export const prodSettingModel = [
  {
    key_name: "culture_id",
    type: "string",
    display_name: "Culture"
  },
  {
    key_name: "experiment_id",
    type: "string",
    display_name: "Experiment"
  },
  {
    key_name: "media_id",
    type: "string",
    display_name: "Media"
  },
  {
    key_name: "strain_id",
    type: "string",
    display_name: "Strain"
  }
  // {
  //   key_name: "volume",
  //   type: "number",
  //   display_name: "Volume"
  // },
  // {
  //   key_name: "inoculum_size",
  //   type: "number",
  //   display_name: "Inoculum Size"
  // },
  // {
  //   key_name: "container_id",
  //   type: "string",
  //   display_name: "Container"
  // },
  // {
  //   key_name: "container_type",
  //   type: "string",
  //   display_name: "Container Type"
  // },
  // {
  //   key_name: "container_tm",
  //   type: "number",
  //   display_name: "Container Temperature"
  // },
  // {
  //   key_name: "container_rpm",
  //   type: "number",
  //   display_name: "Container RPM"
  // },
  // {
  //   key_name: "container_air_vvm",
  //   type: "number",
  //   display_name: "Container Air (vvm)"
  // },
  // {
  //   key_name: "optical_density",
  //   type: "number",
  //   display_name: "Optical Density"
  // },
  // {
  //   key_name: "ph_level",
  //   type: "number",
  //   display_name: "ph Level"
  // },
  // {
  //   key_name: "total_carbon",
  //   type: "number",
  //   display_name: "Total Carbon"
  // }
];
