import { OlabAxios } from "./olabaxios.js";

class OlabSearch {
  constructor() {
    // console.log("OlabSearch.constructor() ...");
  }

  static initSelected(selected) {
    // selected.items = null;
    selected.sortBy = "olab_id";
    selected.pageNumber = 1;
    selected.nextIsDisabled = true;
    selected.prevIsDisabled = true;
  }

  static _createSearchBody(sType, sString, sField, page) {
    const searchBody = {
      searchType: sType ? sType : "project",
      searchString: sString ? sString : "",
      searchField: sField ? sField : "olab_id",
      auxID: null,
      auxID2: null,
      includeDeleted: false,
      page: page * 1 || 1,
      limit: OlabAxios.LIMIT_PER_PAGE
    };
    return searchBody;
  }

  static createSearchBody(sType, sString, sField) {
    return this._createSearchBody(sType, sString, sField, 1);
  }

  static async doPage(sType, sTerm, includeDeleted, selected, statusObj) {
    const sFilter = selected.sortBy ? selected.sortBy : "olab_id";
    const searchBody = OlabSearch._createSearchBody(
      sType,
      sTerm,
      sFilter,
      selected.pageNumber
    );
    searchBody.includeDeleted = includeDeleted;

    const items = await OlabAxios.getItems(sType, searchBody, statusObj);
    if (items.length >= OlabAxios.LIMIT_PER_PAGE) {
      selected.sortBy = sFilter;
      selected.nextIsDisabled = false;
    } else {
      // No more data for next page
      selected.nextIsDisabled = true;
    }
    // console.log("items.len = " + items.length);
    // console.log("items =", items);
    selected.items = items;
  }

  static async doPrev(sType, sTerm, includeDeleted, selected, statusObj) {
    // console.log("** doPrev - sType = " + sType);

    selected.pageNumber--;
    if (selected.pageNumber <= 1) {
      selected.prevIsDisabled = true;
    }
    await OlabSearch.doPage(sType, sTerm, includeDeleted, selected, statusObj);
  }

  static async doNext(sType, sTerm, includeDeleted, selected, statusObj) {
    // console.log("** doNext - sType = " + sType);

    selected.pageNumber++;
    selected.prevIsDisabled = false;
    await OlabSearch.doPage(sType, sTerm, includeDeleted, selected, statusObj);
  }

  static doSearchTerm(
    sTerm,
    sType,
    includeDeleted,
    filterKey,
    selected,
    statusObj
  ) {
    // console.log("includeDelete =", includeDeleted);
    // Initialize selected at the beginning of a new sTerm
    OlabSearch.initSelected(selected);

    OlabSearch.searchItems(
      sTerm,
      sType,
      includeDeleted,
      filterKey,
      selected,
      statusObj
    );
  }

  static async searchItems(
    sTerm,
    sType,
    includeDeleted,
    sFilter,
    selected,
    statusObj
  ) {
    // console.log(
    //   `searchItems : sTerm = ${sTerm}, includeDelete = ${includeDeleted}, sFilter = ${sFilter}, sType = ${sType}`
    // );
    const searchBody = OlabSearch._createSearchBody(
      sType,
      sTerm,
      sFilter,
      selected.pageNumber
    );
    const items = await OlabAxios.getItems(sType, searchBody, statusObj);
    if (items.length >= OlabAxios.LIMIT_PER_PAGE) {
      selected.sortBy = sFilter;
      selected.nextIsDisabled = false;
    }
    // console.log("items.len = " + items.length);
    selected.items = items;
  }
}

export { OlabSearch };
