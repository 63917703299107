<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'chart'" class="container">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="inp-exprt-name" class="form-control-label">
          <span>Name:</span>
        </label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.name)"
          id="inp-exprt-name"
          placeholder="Name"
          v-model="item.name"
        />
      </div>
      <div class="form-row">
        <div v-if="user" class="form-group col-md-10">
          <div class="form-group">
            <label for="sel-projs">Project:</label>
            <div class="mb-2" :class="oakProjsValidate">
              <VueMultiselect
                id="sel-projs"
                v-model="selProj"
                :options="projChoices"
                :loading="isLoadingProjs"
                :internal-search="false"
                :multiple="false"
                :close-on-select="true"
                :options-limit="10"
                :custom-label="nameInfo"
                placeholder="Type to search"
                label="olab_id"
                track-by="olab_id"
                @search-change="getProjs"
                @select="validateProjs"
                @remove="removeProjs"
              />
            </div>
            <!-- <p v-if="selProj">
              {{ selProj.olab_id }}
            </p> -->
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="inp-desc">Description</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.desc"
          id="inp-desc"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="inp-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-note"
        ></textarea>
      </div>
      <div class="form-group oak-flex-creator-footer">
        Created by: {{ item.creator }}
      </div>
    </form>
  </div>
  <div class="oak-flex-footer">
    <button
      type="button"
      v-bind:disabled="itemAddIsDisabled == true"
      class="btn btn-primary"
      v-on:click="handleAddItem(item)"
    >
      <span
        v-if="statusObj.progress"
        class="spinner-border spinner-border-sm text-light"
      ></span>
      Add
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      v-on:click="cancelAdd()"
    >
      Cancel
    </button>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import VueMultiselect from "vue-multiselect";

export default {
  name: "ChartAdd",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      isLoadingProjs: false,
      selProj: "",
      projChoices: [],
      oakProjsValidate: "oak-invalid"
    };
  },
  components: {
    VueMultiselect
  },
  props: ["user", "dbInfo", "selectedType", "item", "items", "statusObj"],
  emits: ["resetAddEnabled"],
  async mounted() {
    console.log("mounted: ChartAdd");
    await this.getProjs();
  },
  methods: {
    async handleAddItem(item) {
      console.log("Add item = ", item, ", selectedType = ", this.selectedType);
      await OlabAxios.addItem(
        item,
        this.selectedType,
        this.items,
        this.statusObj
      );

      // Done add item
      this.$emit("resetAddEnabled");
    },
    cancelAdd() {
      this.$emit("resetAddEnabled");
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ olab_id, name }) {
      return `${name}: [${olab_id}]`;
    },
    async getProjs() {
      this.isLoadingProjs = true;
      this.projChoices = await OlabAxios.getProjects(this.user, this.statusObj);
      this.isLoadingProjs = false;
    },
    validateProjs(/*actionName*/) {
      // console.log("validateProjs =", actionName);
      this.oakProjsValidate = "oak-valid";
    },
    removeProjs(/*actionName*/) {
      // console.log("removeProjs =", actionName);
      this.oakProjsValidate = "oak-invalid";
    }
  },
  computed: {
    itemAddIsDisabled() {
      return !OlabSanity.isItemValid(this.selectedType, this.item);
    }
  },
  watch: {
    selProj() {
      if (this.selProj) {
        this.item.setProject(this.selProj);
      }
    }
  }
};
</script>

<style scoped>
.oak-invalid {
  background: red;
  padding: 0.06rem;
  border-radius: 0.4rem;
}

.oak-valid {
  background: rgb(1, 152, 1);
  padding: 0.06rem;
  border-radius: 0.4rem;
}

.oak-flex-creator-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.oak-flex-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 0.05rem solid;
  margin: 2rm 1rem;
}
</style>
