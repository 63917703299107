export const paletteSettingModel = [
  {
    key_name: "bright",
    display_name: "Bright"
  },
  {
    key_name: "colorblind",
    display_name: "Colorblind"
  },
  {
    key_name: "dark",
    display_name: "Dark"
  },
  {
    key_name: "deep",
    display_name: "Deep"
  },
  {
    key_name: "muted",
    display_name: "Muted"
  },
  {
    key_name: "pastel",
    display_name: "Pastel"
  }
];
