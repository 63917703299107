export const plateTypeSettingModel = [
  {
    key_name: "round",
    display_name: "Round"
  },
  {
    key_name: "rectangle",
    display_name: "Rectangle"
  }
];
