<template>
  <div v-if="user">
    <!-- <button
      type="button"
      class="btn btn-outline-danger ml-1"
      data-toggle="modal"
      data-target="#userProfile"
    >
      <font-awesome-icon icon="id-badge" />
    </button> -->
    <div class="modal fade" id="userProfile">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              User ID:
              <span class="text-dark text-truncate">{{ user.email }}</span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div class="pt-2">
              <span>Name:</span>
              <span v-if="user" class="ml-1">{{ user.name }}</span>
            </div>
            <div class="pt-2">
              <span>Email:</span>
              <span v-if="user" class="ml-1">{{ user.email }}</span>
            </div>
            <div class="pt-2">
              <span>System Role:</span>
              <span v-if="user" class="ml-1">{{ user.role }}</span>
            </div>
            <div class="pt-2">
              <span>Division:</span>
              <span v-if="user" class="ml-1">{{ user.division }}</span>
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
    <!-- .modal fade -- End of userProfile -->
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "OlabUserProfile",
  props: ["user"],
  components: {
    // FontAwesomeIcon
  }
};
</script>
