<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'branch'">
    <form>
      <fieldset class="form-group">
        <!-- <legend>{{selectedType}} Info</legend> -->
        <div class="form-group">
          <label for="inp-edit-name" class="form-control-label"> Name:</label>
          <input
            class="form-control"
            v-bind:class="validState(item.name)"
            type="text"
            placeholder="Name"
            v-model="item.name"
            id="inp-edit-name"
          />
          <!-- <div class="invalid-feedback">{{invalidFB.name}}</div> -->
        </div>
        <div>
          <OlabStatusObjDialogs
            :statusObj="localStatusObj"
            :showStatusObjDialog="showStatusObjDialog"
            @emitStatusMessage="emitStatusMessage"
            @emitErrorMessage="emitErrorMessage"
          />
        </div>
        <div class="form-group">
          <span class="mr-1">Project ID:</span>
          <span class="ml-1">{{ formatProject }}</span>
        </div>
        <div class="form-group">
          <label for="inp-edit-start-date" class="form-control-label">
            Start Date:
          </label>
          <input
            class="form-control"
            v-bind:class="validState(item.start_date)"
            type="date"
            v-model="item.start_date"
            id="inp-edit-start-date"
          />
          <!-- <div class="invalid-feedback">{{invalidFB.startDate}}</div> -->
        </div>
        <div class="form-group">
          <label for="inp-edit-end-date" class="form-control-label"
            >End Date:</label
          >
          <input
            class="form-control"
            type="date"
            v-model="item.end_date"
            id="inp-edit-end-date"
          />
        </div>
        <div>
          <div class="form-row">
            <div class="form-group col-md-10">
              <div class="form-group">
                <label for="sel-users">Select Members:</label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-users"
                    v-model="selUsers"
                    :options="userChoices"
                    :loading="isLoadingProjMembers"
                    :internal-search="internalSearchMembers"
                    :multiple="true"
                    :close-on-select="true"
                    :options-limit="10"
                    :custom-label="nameInfo"
                    placeholder="Type to search"
                    label="olab_id"
                    track-by="olab_id"
                    @search-change="loadProjMbrs"
                  />
                </div>
                <!-- <p v-if="selUsers && selUsers.length > 0">
                  {{ computeSelusers }}
                </p> -->
              </div>
            </div>
          </div>

          <div class="py-1 text-wrap">
            <span class="mr-1">Members:</span>
          </div>
          <div v-if="item.members" class="py-1 text-wrap">
            <ol>
              <!-- Loop through members and display them in the ordered list -->
              <li v-for="(mbr, index) in item.members" :key="index">
                {{ mbr }}
              </li>
            </ol>
          </div>
        </div>
        <div class="py-1 form-group">
          <label for="inp-edit-desc" class="form-control-label"
            >Description:</label
          >
          <textarea
            class="form-control"
            v-model="item.desc"
            id="inp-edit-desc"
          ></textarea>
        </div>
        <!-- .card -->
      </fieldset>
      <!-- .form-group -->
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";
import OlabStatusObjDialogs from "@/components/OlabStatusObjDialogs.vue";

export default {
  name: "BranchEdit",
  data() {
    return {
      selUsers: [],
      userChoices: [],
      invalidFB: OlabItem.getInvalidFeedbacks(),
      project: null,
      isLoadingProjMembers: false,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      },
      showStatusObjDialog: false
    };
  },
  components: {
    VueMultiselect,
    OlabStatusObjDialogs
  },
  props: ["user", "dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("Mounted() item =", this.item);
    await this.loadData();
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.localStatusObj.message = statusMsg;
      this.localStatusObj.progress = statusProgress;
      this.showStatusObjDialog = this.localStatusObj.message != null;
    },
    emitErrorMessage(errMsg) {
      this.localStatusObj.errorMessage = errMsg;
      this.showStatusObjDialog = this.localStatusObj.errorMessage != null;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ name, email }) {
      return `${name}: [${email}]`;
    },
    async loadProjectData() {
      if (this.item && this.item.olab_type === "branch") {
        this.project = await OlabAxios.getProject(
          this.item.project_id,
          this.localStatusObj
        );
      }
    },
    async loadProjMembers(query, proj) {
      // console.log("loadProjMembers: proj =", proj);
      this.isLoadingProjMembers = true;
      if (proj && !proj.ra_locks.public) {
        this.userChoices = await OlabAxios.getProjectMembers(
          proj.olab_id,
          this.localStatusObj
        );
      } else {
        // Share within Division case
        // TODO: Need to restrict search to within proj's division
        const searchBody = OlabSearch.createSearchBody("user", query, "name");
        this.userChoices = await OlabAxios.getUsers(
          searchBody,
          this.localStatusObj
        );
      }
      this.isLoadingProjMembers = false;
      // console.log("userChoices =", this.userChoices);
    },
    async loadProjMbrs(query) {
      await this.loadProjMembers(query, this.project);
    },
    async loadBranchMembers() {
      if (this.item && this.item.members) {
        const usrs = await OlabAxios.getUsersByEmails(
          this.item.members,
          this.localStatusObj
        );
        this.selUsers = usrs;
      } else {
        this.selUsers = [];
      }
      // console.log("selUsers =", this.selUsers);
    },
    async loadData() {
      await this.loadProjectData();
      await this.loadProjMembers("", this.project);
      await this.loadBranchMembers();
    }
  },
  computed: {
    formatProject() {
      if (this.project) {
        return `${this.project.olab_id} - ${this.project.name}`;
      }
      return this.item.project_id;
    },
    computeSelusers() {
      const csfUsrs = [];
      if (this.selUsers) {
        this.selUsers.forEach((sf) => {
          const csf = { name: sf.name, olab_id: sf.olab_id };
          csfUsrs.push(csf);
        });
      }
      return csfUsrs;
    },
    internalSearchMembers() {
      let iSearchState = true;
      if (
        this.project &&
        this.project.ra_locks &&
        this.project.ra_locks.public
      ) {
        iSearchState = false;
      }
      return iSearchState;
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadData();
    },
    selUsers() {
      // console.log("watch: selUsers =", this.selUsers);
      const mbrs = [];
      if (this.selUsers) {
        this.selUsers.forEach((sUsr) => {
          mbrs.push(sUsr.email);
        });
      }
      this.item.setMembers(mbrs);
      // console.log("members =", this.item.members);
    }
  }
};
</script>
