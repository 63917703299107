export const tubeTypeSettingModel = [
  {
    key_name: "glass",
    display_name: "Glass"
  },
  {
    key_name: "plastic",
    display_name: "Plastic"
  }
];
