<template>
  <div v-if="selectedType === 'branch'">
    <div class="form-group">
      <div
        v-if="localStatusObj.progress"
        class="spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
        {{ localStatusObj.errorMessage }}
      </span>
      <span v-if="localStatusObj.message" class="mx-1 text-info">
        {{ localStatusObj.message }}
      </span>
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Project ID:</span>
      <span class="ml-1">{{ formatProject }}</span>
    </div>
    <div class="py-1 text-wrap">
      <span class="mr-1">Start Date:</span>
      <span class="ml-1">{{ item.start_date }}</span>
    </div>
    <div class="py-1 text-wrap">
      <span class="mr-1">End Date:</span>
      <span class="ml-1">{{ item.end_date }}</span>
    </div>
    <!-- List team members -->
    <div>
      <div class="py-1 text-wrap">
        <span class="mr-1">Members:</span>
      </div>
      <div v-if="item.members" class="py-1 text-wrap">
        <ol>
          <!-- Loop through members and display them in the ordered list -->
          <li v-for="(mbr, index) in item.members" :key="index">
            {{ mbr }}
          </li>
        </ol>
      </div>
    </div>
    <div class="py-1">
      <div class="card">
        <div class="card-header text-dark">Description</div>
        <div class="card-body">
          <div class="py-1 small text-dark text-wrap">
            {{ item.desc }}
          </div>
        </div>
        <!-- .card-body -->
      </div>
      <!-- .card -->
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";

export default {
  name: "BranchView",
  data() {
    return {
      project: null,
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("Mounted() item =", this.item);
    await this.loadProjectData();
  },
  methods: {
    async loadProjectData() {
      if (this.item && this.item.olab_type === "branch") {
        this.project = await OlabAxios.getProject(
          this.item.project_id,
          this.localStatusObj
        );
      }
    }
  },
  computed: {
    formatProject() {
      if (this.project) {
        return `${this.project.olab_id} - ${this.project.name}`;
      }
      return this.item.project_id;
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadProjectData();
    }
  }
};
</script>
