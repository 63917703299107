<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'culture'">
    <form>
      <div
        v-if="localStatusObj.progress"
        class="spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
        {{ localStatusObj.errorMessage }}
      </span>
      <span v-if="localStatusObj.message" class="mx-1 text-info">
        {{ localStatusObj.message }}
      </span>
      <div class="form-group">
        <label for="inp-edit-name">Name</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-bind:class="validState(item.name)"
            v-model="item.name"
            id="inp-edit-name"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-4">
          <label class="mr-2">Culture Type:</label>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="inoculumType"
              id="inpCheckInoculum-edit"
            />
            <label class="form-check-label" for="inpCheckInoculum-edit">
              Inoculum
            </label>
          </div>
        </div>
        <div class="form-group col-lg-5">
          <span class="mr-1">Culture Stage:</span>
          <span class="ml-1">{{ cultureStage }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-culture-strain-edit">Strain:</label>
          <span v-if="strain == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div v-if="item && item.culture_stage === '00'">
            <VueMultiselect
              id="sel-culture-strain-edit"
              v-model="strain"
              :options="strains"
              :loading="isLoadingStrains"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getStrains"
            />
          </div>
          <div v-else>
            <VueMultiselect
              id="sel-culture-strain-edit"
              v-model="strain"
              :options="strainCandidates"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="sel-strain-source-type-edit">Strain Source Type:</label>
          <span
            v-if="strainSourceType == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-strain-source-type-edit"
              v-model="strainSourceType"
              :options="strainSourceTypeSettingModel"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              label="display_name"
              track-by="key_name"
            />
          </div>
          <!-- <p v-if="strainSourceType">{{ strainSourceType }}</p> -->
        </div>
        <div class="form-group col-md-4">
          <label for="sel-strain-confirmation-method-edit"
            >Strain Confirmation Method:</label
          >
          <span
            v-if="strainConfirmationMethod == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-strain-confirmation-method-edit"
              v-model="strainConfirmationMethod"
              :options="strainConfirmationMethodSettingModel"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              deselectLabel=""
              :showLabels="false"
              label="display_name"
              track-by="key_name"
            />
          </div>
          <!-- <p v-if="strainConfirmationMethod">{{ strainConfirmationMethod }}</p> -->
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-culture-media-edit">Media:</label>
          <span v-if="media == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-culture-media-edit"
              v-model="media"
              :options="medias"
              :loading="isLoadingMedias"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getMedias"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sel-culture-container-edit"
            >{{ containerTypeName }}:</label
          >
          <span
            v-if="container == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-culture-container-edit"
              v-model="container"
              :options="containers"
              :loading="isLoadingContainers"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getContainers"
            />
          </div>
        </div>
        <div
          v-if="container && container.olab_type === 'bioreactor'"
          class="form-group col-md-6"
        >
          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="inp-culture-bioreactor-temp-edit">Temperature:</label>
              <div v-if="container">
                <div class="input-group">
                  <input
                    id="inp-culture-bioreactor-temp-edit"
                    type="number"
                    class="form-control"
                    :value="container.tm"
                    @input.prevent="updateContainerTM"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"> &#8451; </span>
                  </div>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div class="form-group col-lg-6">
              <label for="inp-culture-bioreactor-speed-edit">Speed:</label>
              <div v-if="container">
                <div class="input-group">
                  <input
                    id="inp-culture-bioreactor-speed-edit"
                    type="number"
                    min="0"
                    class="form-control"
                    :value="container.rpm"
                    @input.prevent="updateContainerRPM"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"> rpm </span>
                  </div>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="inp-culture-bioreactor-air-edit">Air(vvm):</label>
              <div v-if="container">
                <div class="input-group">
                  <input
                    id="inp-culture-bioreactor-air-edit"
                    type="number"
                    min="0"
                    class="form-control"
                    :value="container.air_vvm"
                    @input.prevent="updateContainerAir"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"> L/min </span>
                  </div>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
          </div>
        </div>
        <div v-else class="form-group col-md-6">
          <div v-if="container" class="row">
            <div class="my-2 col-md-4">
              <label>Type:</label>
              <div v-if="container">
                {{ container.type }}
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div v-if="container.olab_type !== 'plate'" class="my-2 col-md-5">
              <label>Size:</label>
              <div v-if="container">
                {{ container.size }} {{ containerUnit }}
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div v-else class="col-md-8">
              <!-- This is a plate case (round or rectangle) -->
              <div v-if="container && container.dimension">
                <div v-if="container.type === 'round'" class="row">
                  <div class="my-2 col">
                    <label>Diameter:</label>
                    <div>
                      {{ container.dimension.diameter }}
                      {{ container.dimension.unit }}
                    </div>
                  </div>
                  <div class="my-2 col">
                    <label>Height:</label>
                    <div>
                      {{ container.dimension.height }}
                      {{ container.dimension.unit }}
                    </div>
                  </div>
                </div>
                <div v-if="container.type === 'rectangle'" class="row">
                  <div class="my-2 col">
                    <label>Width:</label>
                    <div>
                      {{ container.dimension.width }}
                      {{ container.dimension.unit }}
                    </div>
                  </div>
                  <div class="my-2 col">
                    <label>Length:</label>
                    <div>
                      {{ container.dimension.length }}
                      {{ container.dimension.unit }}
                    </div>
                  </div>
                  <div class="my-2 col">
                    <label>Height:</label>
                    <div>
                      {{ container.dimension.height }}
                      {{ container.dimension.unit }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="container && container.olab_type !== 'bioreactor'"
        class="form-row"
      >
        <div class="form-group col-md-6">
          <label for="sel-culture-incubator-edit">Incubator:</label>
          <span
            v-if="incubator == null"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-culture-incubator-edit"
              v-model="incubator"
              :options="incubators"
              :loading="isLoadingIncubators"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="nameInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getIncubators"
            />
          </div>
          <!-- <p>{{ incubator }}</p> -->
        </div>
        <div class="form-group col-md-6">
          <div class="form-row">
            <div class="form-group col-lg-5">
              <label for="inp-culture-incubator-temp-edit">Temperature:</label>
              <div v-if="incubator" class="input-group">
                <input
                  id="inp-culture-incubator-temp-edit"
                  type="number"
                  class="form-control"
                  :value="incubator.tm"
                  @input.prevent="updateIncubatorTM"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> &#8451; </span>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div class="form-group col-lg-2">
              <label>Type:</label>
              <div v-if="incubator">
                {{ incubator.type }}
              </div>
              <div v-else class="ml-2">--</div>
            </div>
            <div class="form-group col-lg-5">
              <label for="inp-culture-incubator-speed-edit">Speed:</label>
              <div
                v-if="incubator && incubator.type === 'Shaker'"
                class="input-group"
              >
                <input
                  id="inp-culture-incubator-speed-edit"
                  type="number"
                  class="form-control"
                  :value="incubator.rpm"
                  @input.prevent="updateIncubatorRPM"
                />
                <div class="input-group-append">
                  <span class="input-group-text"> rpm </span>
                </div>
              </div>
              <div v-else class="ml-2">--</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="inputStartDate">Start Date</label>
          <div class="input-group">
            <input
              id="inputStartDate"
              type="date"
              class="form-control"
              v-bind:class="validState(startDate)"
              :value="startDate"
              @input.prevent="updateStartDate"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputStartTime">Start Time</label>
          <div class="input-group">
            <input
              id="inputStartTime"
              type="time"
              class="form-control"
              v-bind:class="validState(startTime)"
              :value="startTime"
              @input.prevent="updateStartTime"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="inputEndDate">End Date</label>
          <div class="input-group">
            <input
              id="inputEndDate"
              type="date"
              class="form-control"
              :value="endDate"
              @input.prevent="updateEndDate"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputEndTime">End Time</label>
          <div class="input-group">
            <input
              id="inputEndTime"
              type="time"
              class="form-control"
              :value="endTime"
              @input.prevent="updateEndTime"
            />
          </div>
        </div>
        <div class="form-group col-md-12">
          <label>Culture Time:</label>
          <div class="text-info ml-2">
            {{ formatCultureTime }}
          </div>
        </div>
      </div>
      <div v-if="container" class="form-row">
        <div v-if="container.olab_type !== 'plate'" class="form-group col-md-3">
          <label for="inputInitialOD">Initial OD (600nm)</label>
          <div class="input-group">
            <input
              id="inputInitialOD"
              type="Number"
              step="0.1"
              min="0"
              class="form-control"
              :value="initialOD"
              @input.prevent="updateInitialOD"
            />
          </div>
        </div>
        <div
          v-if="
            container.olab_type !== 'bioreactor' &&
            container.olab_type !== 'plate'
          "
          class="form-group col-md-3"
        >
          <label for="inputFinalOD">Final OD (600nm)</label>
          <div class="input-group">
            <input
              id="inputFinalOD"
              type="Number"
              step="0.1"
              min="0"
              class="form-control"
              :value="finalOD"
              @input.prevent="updateFinalOD"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialPHLevel">Initial pH Level</label>
          <div class="input-group">
            <input
              id="inputInitialPHLevel"
              type="Number"
              step="0.1"
              min="0"
              max="14"
              class="form-control"
              :value="initialPHLevel"
              @input.prevent="updateInitialPHLevel"
            />
          </div>
        </div>
        <div
          v-if="
            container.olab_type !== 'bioreactor' &&
            container.olab_type !== 'plate'
          "
          class="form-group col-md-3"
        >
          <label for="inputFinalPHLevel">Final pH Level</label>
          <div class="input-group">
            <input
              id="inputFinalPHLevel"
              type="Number"
              step="0.1"
              min="0"
              max="14"
              class="form-control"
              :value="finalPHLevel"
              @input.prevent="updateFinalPHLevel"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialTotalCarbon">Initial Total Carbon</label>
          <div class="input-group">
            <input
              id="inputInitialTotalCarbon"
              type="Number"
              min="0"
              class="form-control"
              :value="initialTotalCarbon"
              @input.prevent="updateInitialTotalCarbon"
            />
            <!-- Unit is in gram -->
            <div class="input-group-append">
              <span class="input-group-text"> g </span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialVolume">Initial Volume</label>
          <div class="input-group">
            <input
              id="inputInitialVolume"
              type="Number"
              class="form-control"
              :value="initalVolume"
              @input.prevent="updateInitialVolume"
            />
            <div class="input-group-append">
              <span class="input-group-text"> {{ containerUnit }} </span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInoculumSize">Inoculum Size</label>
          <div class="input-group">
            <input
              id="inputInoculumSize"
              type="Number"
              class="form-control"
              :value="inoculumSize"
              @input.prevent="updateInoculumSize"
            />
            <div class="input-group-append">
              <span class="input-group-text"> {{ containerUnit }} </span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="inp-culture-imagefile-edit">Growth</label>
        <span class="text-warning mx-1">TODO: Work in progress</span>
        <div class="input-group">
          <input type="file" id="inp-culture-imagefile-edit" accept="image/*" />
        </div>
      </div> -->
      <div class="form-group">
        <label for="inp-edit-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="note"
          id="inp-edit-note"
        ></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabUtils } from "@/olab/olabutils";
import VueMultiselect from "vue-multiselect";
import { strainSourceTypeSettingModel } from "@/assets/data/strain_source_type_setting";
import { strainConfirmationMethodSettingModel } from "@/assets/data/strain_confirmation_method_setting";

export default {
  name: "CultureEdit",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      strainSourceTypeSettingModel,
      strainConfirmationMethodSettingModel,
      strainCandidates: [],
      culture: null,
      container: null,
      incubator: null,
      media: null,
      strain: null,
      inoculumType: false,
      strainSourceType: null,
      strainConfirmationMethod: null,
      cultureType: "culture",

      note: null,
      imageFile: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      cultureTime: 0,
      startDateISO: null,
      endDateISO: null,
      inoculumSize: 0,
      initialOD: 0,
      finalOD: 0,
      initialPHLevel: 0,
      finalPHLevel: 0,
      initalVolume: 0,
      initialTotalCarbon: 0,
      containers: [],
      incubators: [],
      medias: [],
      strains: [],
      isLoadingContainers: false,
      isLoadingMedias: false,
      isLoadingStrains: false,
      isLoadingIncubators: false,
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  components: { VueMultiselect },
  props: ["user", "dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("** dbInfo =", this.dbInfo);
    // console.log("** selectedType =", this.selectedType);
    // Loading is needed for the first item
    await this.loadCultureData();
    // console.log("culture =", this.culture);
    // console.log("container =", this.container);
    // console.log("incubator =", this.incubator);
    // console.log("media =", this.media);
    // console.log("strain =", this.strain);

    // This is reload in loadCultureData()
    // await this.getContainers("");

    await this.getIncubators("");
    await this.getMedias("");
  },
  computed: {
    cultureStage() {
      return OlabUtils.getCultureStageName(this.item.culture_stage);
    },
    containerTypeName() {
      const cType = this.container ? this.container.olab_type : "No Container";
      return cType[0].toUpperCase() + cType.slice(1);
    },
    formatCultureTime() {
      // cultureTime is in minutes
      return OlabDate.formatDiffTime(this.cultureTime);
    },
    containerUnit() {
      let sym = "--";
      if (this.container) {
        if (this.container && this.container.olab_type === "plate") {
          sym = "ml";
        } else {
          sym = OlabUtils.getUnitSym(this.container.unit);
        }
      }
      return sym;
    }
  },
  methods: {
    nameInfo({ olab_id, name }) {
      return `${name}: [${olab_id}]`;
    },
    async getMedias(query) {
      // console.log("getMedias: query = ", query);
      this.isLoadingMedias = true;
      const searchBody = OlabSearch.createSearchBody("media", query, "merge");
      this.medias = await OlabAxios.getMedias(searchBody, this.localStatusObj);
      this.isLoadingMedias = false;
    },
    async getContainers(query) {
      // console.log("container =", this.container);
      // console.log("getContainers: query = ", query);
      const cType = this.container.olab_type;
      this.isLoadingContainers = true;
      const searchBody = OlabSearch.createSearchBody(cType, query, "merge");
      if (cType === "bioreactor") {
        this.containers = await OlabAxios.getBioreactors(
          searchBody,
          this.localStatusObj
        );
      } else if (cType === "flask") {
        this.containers = await OlabAxios.getFlasks(
          searchBody,
          this.localStatusObj
        );
      } else if (cType === "plate") {
        this.containers = await OlabAxios.getPlates(
          searchBody,
          this.localStatusObj
        );
      } else if (cType === "tube") {
        this.containers = await OlabAxios.getTubes(
          searchBody,
          this.localStatusObj
        );
      }
      this.isLoadingContainers = false;
    },
    async getStrains(query) {
      // console.log("getStrains: query = ", query);
      this.isLoadingStrains = true;
      const searchBody = OlabSearch.createSearchBody("strain", query, "merge");
      this.strains = await OlabAxios.getStrains(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingStrains = false;
    },
    async getIncubators(query) {
      // console.log("getStrains: query = ", query);
      this.isLoadingIncubators = true;
      const searchBody = OlabSearch.createSearchBody(
        "incubator",
        query,
        "merge"
      );
      this.incubators = await OlabAxios.getIncubators(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingIncubators = false;
    },
    // Computed culture time in minutes
    computeCultureTime() {
      let time = 0;
      if (this.startDate && this.startTime && this.endDate && this.endTime) {
        const sDateTime = OlabDate.createDateObj(
          this.startDate,
          this.startTime
        );
        const eDateTime = OlabDate.createDateObj(this.endDate, this.endTime);
        // Convert milliseconds to minutes
        time = OlabDate.computeDiffTime(sDateTime, eDateTime);
      }
      this.cultureTime = time;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    async setupStrainCandidates(culture) {
      const prevCS = parseInt(culture.culture_stage) - 1;
      if (culture.experiment_id && prevCS >= 0) {
        const strainIDs = await OlabAxios.getStrainCandidates(
          culture.experiment_id,
          prevCS,
          this.localStatusObj
        );
        // console.log("strainIDs =", strainIDs);
        this.strainCandidates = await OlabAxios.getStrainsByOlabIDs(
          strainIDs,
          this.localStatusObj
        );
        // console.log("strainCandidates =", this.strainCandidates);
      }
    },
    async loadCultureData() {
      if (this.item && this.item.olab_type === "culture") {
        const itemData = await OlabAxios.getCultureData(
          this.item,
          this.localStatusObj
        );
        // console.log("itemData =", itemData);
        this.culture = itemData.culture;
        // Set strain_source_type
        if (this.strainSourceTypeSettingModel && this.item) {
          this.strainSourceType = this.strainSourceTypeSettingModel.find(
            (tt) => tt.key_name === this.item.strain_source_type
          );
        }
        // Set strain_confirmation_method
        if (this.strainConfirmationMethodSettingModel && this.item) {
          this.strainConfirmationMethod =
            this.strainConfirmationMethodSettingModel.find(
              (tt) => tt.key_name === this.item.strain_confirmation_method
            );
        }
        this.media = itemData.media;
        this.strain = itemData.strain;
        this.container = this.culture ? this.culture.container : null;
        this.incubator = this.culture ? this.culture.incubator : null;

        this.inoculumSize = this.culture.inoculum_size;
        this.initialOD = this.culture.initial_OD;
        this.finalOD = this.culture.final_OD;
        this.initialPHLevel = this.culture.initial_ph_level;
        this.finalPHLevel = this.culture.final_ph_level;
        this.initalVolume = this.culture.initial_volume;
        this.initialTotalCarbon = this.culture
          ? this.culture.initial_total_carbon
          : 0;
        this.note = this.culture.note;
        if (this.culture && this.culture.start_date) {
          const sDate = OlabDate.createDateObjWithISOString(
            this.culture.start_date
          );
          this.startDate = OlabDate.formatYMD(sDate, false);
          this.startTime = OlabDate.formatHM(sDate, false);
        }
        if (this.culture && this.culture.end_date) {
          const eDate = OlabDate.createDateObjWithISOString(
            this.culture.end_date
          );
          this.endDate = OlabDate.formatYMD(eDate, false);
          this.endTime = OlabDate.formatHM(eDate, false);
        }
        this.computeCultureTime();

        // Need to reload containers list
        await this.getContainers("");

        // Need to setup strain candidates
        if (this.item.culture_stage === "00") {
          await this.getStrains("");
        } else {
          await this.setupStrainCandidates(this.item);
        }
      }
    },
    updateContainerRPM(e) {
      const rpm = e.target.value;
      // console.log("rpm = ", rpm);
      this.container.rpm = rpm;
      this.item.setContainer(this.container);
    },
    updateContainerTM(e) {
      const tm = e.target.value;
      // console.log("tm = ", tm);
      this.container.tm = tm;
      this.item.setContainer(this.container);
    },
    updateContainerAir(e) {
      const ar = e.target.value;
      // console.log("ar = ", ar);
      this.container.air_vvm = ar;
      this.item.setContainer(this.container);
    },
    updateIncubatorRPM(e) {
      const rpm = e.target.value;
      // console.log("rpm = ", rpm);
      this.incubator.rpm = rpm;
      this.item.setIncubator(this.incubator);
    },
    updateIncubatorTM(e) {
      const tm = e.target.value;
      // console.log("tm = ", tm);
      this.incubator.tm = tm;
      this.item.setIncubator(this.incubator);
    },
    computeDateISOStr(date, time) {
      let isoString = null;
      if (date && time) {
        isoString = OlabDate.createDateObj(date, time).toISOString();
      }
      return isoString;
    },
    updateStartDate(e) {
      const sDate = e.target.value;
      this.startDate = sDate;
      const sDateISOStr = this.computeDateISOStr(
        this.startDate,
        this.startTime
      );
      if (sDateISOStr) {
        this.startDateISO = sDateISOStr;
        this.computeCultureTime();
      }
    },
    updateStartTime(e) {
      const sTime = e.target.value;
      this.startTime = sTime;
      const sDateISOStr = this.computeDateISOStr(
        this.startDate,
        this.startTime
      );
      if (sDateISOStr) {
        this.startDateISO = sDateISOStr;
        this.computeCultureTime();
      }
    },
    updateEndDate(e) {
      const eDate = e.target.value;
      this.endDate = eDate;
      const eDateISOStr = this.computeDateISOStr(this.endDate, this.endTime);
      if (eDateISOStr) {
        this.endDateISO = eDateISOStr;
        this.computeCultureTime();
      }
    },
    updateEndTime(e) {
      const eTime = e.target.value;
      this.endTime = eTime;
      const eDateISOStr = this.computeDateISOStr(this.endDate, this.endTime);
      if (eDateISOStr) {
        this.endDateISO = eDateISOStr;
        this.computeCultureTime();
      }
    },
    updateInoculumSize(e) {
      const inoSz = e.target.value;
      // console.log("updateInoculumSize: inoSz =", inoSz);
      this.inoculumSize = inoSz;
      this.item.setInoculumSize(this.inoculumSize);
    },
    // TODO: 0 - Max, one decimal point
    updateInitialOD(e) {
      const od = e.target.value;
      // console.log("updateInitialOD: od =", od);
      this.initialOD = od;
      this.item.setInitialOD(this.initialOD);
    },
    // TODO: 0 - Max, one decimal point
    updateFinalOD(e) {
      const od = e.target.value;
      // console.log("updateFinalOD: od =", od);
      this.finalOD = od;
      this.item.setFinalOD(this.finalOD);
    },
    // TODO: 0 - 14, one decimal point
    updateInitialPHLevel(e) {
      const ph = e.target.value;
      // console.log("updateInitialPHLevel: ph =", ph);
      this.initialPHLevel = ph;
      this.item.setInitialPHLevel(this.initialPHLevel);
    },
    // TODO: 0 - 14, one decimal point
    updateFinalPHLevel(e) {
      const ph = e.target.value;
      // console.log("updateFinalPHLevel: ph =", ph);
      this.finalPHLevel = ph;
      this.item.setFinalPHLevel(this.finalPHLevel);
    },
    // TODO: 0 - Max
    updateInitialVolume(e) {
      const vol = e.target.value;
      // console.log("updateInitialVolume: vol =", vol);
      this.initalVolume = vol;
      this.item.setInitialVolume(this.initalVolume);
    },
    // TODO: 0 - Max
    updateInitialTotalCarbon(e) {
      const cbg = e.target.value;
      // console.log("updateInitialTotalCarbon: cbg =", cbg);
      this.initialTotalCarbon = cbg;
      this.item.setInitialTotalCarbon(this.initialTotalCarbon);
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadCultureData();
    },
    name() {
      this.item.setName(name);
    },
    startDateISO() {
      this.item.setStartDate(this.startDateISO);
    },
    endDateISO() {
      this.item.setEndDate(this.endDateISO);
    },
    container() {
      this.item.setContainer(this.container);
    },
    incubator() {
      this.item.setIncubator(this.incubator);
    },
    media() {
      this.item.setMedia(this.media);
    },
    strain() {
      this.item.setStrain(this.strain);
    },
    strainSourceType() {
      this.item.setStrainSourceType(this.strainSourceType);
    },
    strainConfirmationMethod() {
      this.item.setStrainConfirmationMethod(this.strainConfirmationMethod);
    },
    inoculumType() {
      // console.log("inoculumType =", this.inoculumType);
      this.cultureType = this.inoculumType ? "inoculum" : "culture";
      this.item.setCultureType(this.cultureType);
    },
    imageFile() {
      console.log("TODO: watch: imageFile =", this.imageFile);
    },
    note() {
      this.item.setNote(this.note);
    }
  }
};
</script>
