import { OlabUtils } from "@/olab/olabutils.js";

export const recipes = [
  {
    name: "LB liquid medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "Tryptone",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "NaCl",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 5,
        unit: "g",
        substanceName: "Yeast extract",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  },
  {
    name: "LB agar (2%) medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "Tryptone",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "NaCl",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 5,
        unit: "g",
        substanceName: "Yeast extract",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 20,
        unit: "g",
        substanceName: "Agar",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  },
  {
    name: "YPD medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 20,
        unit: "g",
        substanceName: "Bacto peptone",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "Yeast extract",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  },
  {
    name: "YPD agar medium",
    volume: 1000,
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 24,
        unit: "g",
        substanceName: "Bacto agar",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 20,
        unit: "g",
        substanceName: "Bacto peptone",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "Yeast extract",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  },
  {
    name: "CM agar medium",
    volume: "1000",
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 6.7,
        unit: "g",
        substanceName: "YNB",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 20,
        unit: "g",
        substanceName: "Glucose",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 20,
        unit: "g",
        substanceName: "Bacto agar",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 2,
        unit: "g",
        substanceName: "Drop-out mix",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  },
  {
    name: "SD liquid medium",
    volume: "1000",
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 1.7,
        unit: "g",
        substanceName:
          "Yeast nitrogen base (without amino acids and nitrogen substrate)",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 5,
        unit: "g",
        substanceName: "Ammonium Sulfate",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 20,
        unit: "g",
        substanceName: "Dextrose",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 1,
        unit: "unit",
        substanceName: "CSM (* varies)",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 20,
        unit: "g",
        substanceName: "Agar",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  },
  {
    name: "C.glutamicum liquid medium",
    volume: "1000",
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "Tryptone",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 10,
        unit: "g",
        substanceName: "NaCl",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 5,
        unit: "g",
        substanceName: "Yeast extract",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 5,
        unit: "g",
        substanceName: "Glucose",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 25,
        unit: "g",
        substanceName: "Glycine",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 4,
        unit: "g",
        substanceName: "isonicotinic acid hydrazid",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  },
  {
    name: "4X SDS sample buffer for Western Blotting",
    volume: "7.5",
    units: "mL",
    ingredients: [
      {
        ingredientsValue: 1.5,
        unit: "mL",
        substanceName: "1 M Tris-HCl pH 6.5",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 3,
        unit: "mL",
        substanceName: "1 M DTT (dithiothreitol)",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 0.6,
        unit: "g",
        substanceName: "SDS (sodium dodecyl sulfate)",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 30,
        unit: "mg",
        substanceName: "bromophenol blue",
        value: OlabUtils.fixFloatTo(0, 4)
      },
      {
        ingredientsValue: 2.4,
        unit: "mL",
        substanceName: "glycerol",
        value: OlabUtils.fixFloatTo(0, 4)
      }
    ]
  }
];
