<template>
  <div>
    <div v-if="user" id="olabanalysis">
      <h1>This is a TODO: Analysis page</h1>
      <div class="container">
        <div class="row justify-content-center family-sans text-uppercase">
          <section>
            <img alt="Sample Chart" src="../assets/data/graph.svg" />
            <h5 class="text-center pt-2">Sample Chart</h5>
          </section>
        </div>
      </div>
    </div>
    <div v-else class="mt-5">
      <div class="text-secondary text-center">
        <div class="h2">Inactivity Time Out</div>
        <router-link
          class="btn btn-outline-primary mt-2"
          to="/login"
          v-if="!user"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OlabAnalysis",
  props: ["user", "dbInfo", "statusObj"],
  methods: {}
};
</script>
