<template>
  <div v-if="hypo1 && hypo2 && hypo3">
    <div class="card my-4">
      <div class="card-body">
        <h5 class="card-title">
          Hypothesis<span
            v-if="hypo1.statement == ''"
            class="text-danger font-weight-bold mx-1"
            >*</span
          >
        </h5>
        <ul class="nav nav-tabs mb-3" id="hypothesis-edit-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="hypothesis1-edit-tab"
              data-toggle="tab"
              data-target="#hypothesis1-edit"
              type="button"
            >
              1
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="hypothesis2-edit-tab"
              data-toggle="tab"
              data-target="#hypothesis2-edit"
              type="button"
            >
              2
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="hypothesis3-edit-tab"
              data-toggle="tab"
              data-target="#hypothesis3-edit"
              type="button"
            >
              3
            </button>
          </li>
        </ul>
        <div class="tab-content" id="hypothesis-edit-tabContent">
          <div
            class="tab-pane fade show active"
            id="hypothesis1-edit"
            role="tabpanel"
            aria-labelledby="hypothesis1-edit-tab"
          >
            <div class="form-group">
              <textarea
                class="form-control"
                type="textarea"
                v-model="hypo1.statement"
              ></textarea>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inp-cause">Cause:</label>
                <input
                  id="inp-cause"
                  class="form-control"
                  type="text"
                  v-model="hypo1.cause"
                  placeholder="Cause"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="inp-effect" class="form-control-label">
                  <span>Effect:</span>
                </label>
                <input
                  id="inp-effect"
                  class="form-control"
                  type="text"
                  v-model="hypo1.effect"
                  placeholder="Effect"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inp-answered">Answered:</label>
                <select
                  id="inp-answered"
                  class="form-control"
                  v-model="hypo1.answered"
                >
                  <option selected>N/A</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="hypothesis2-edit"
            role="tabpanel"
            aria-labelledby="hypothesis2-edit-tab"
          >
            <div class="form-group">
              <textarea
                class="form-control"
                type="textarea"
                v-model="hypo2.statement"
              ></textarea>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inp-cause">Cause:</label>
                <input
                  id="inp-cause"
                  class="form-control"
                  type="text"
                  v-model="hypo2.cause"
                  placeholder="Cause"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="inp-effect" class="form-control-label">
                  <span>Effect:</span>
                </label>
                <input
                  id="inp-effect"
                  class="form-control"
                  type="text"
                  v-model="hypo2.effect"
                  placeholder="Effect"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inp-answered">Answered:</label>
                <select
                  id="inp-answered"
                  class="form-control"
                  v-model="hypo2.answered"
                >
                  <option selected>N/A</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="hypothesis3-edit"
            role="tabpanel"
            aria-labelledby="hypothesis3-edit-tab"
          >
            <div class="form-group">
              <textarea
                class="form-control"
                type="textarea"
                v-model="hypo3.statement"
              ></textarea>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inp-cause">Cause:</label>
                <input
                  id="inp-cause"
                  class="form-control"
                  type="text"
                  v-model="hypo3.cause"
                  placeholder="Cause"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="inp-effect" class="form-control-label">
                  <span>Effect:</span>
                </label>
                <input
                  id="inp-effect"
                  class="form-control"
                  type="text"
                  v-model="hypo3.effect"
                  placeholder="Effect"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inp-answered">Answered:</label>
                <select
                  id="inp-answered"
                  class="form-control"
                  v-model="hypo3.answered"
                >
                  <option selected>N/A</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HypothesisEdit",
  data() {
    return {
      hypo1: null,
      hypo2: null,
      hypo3: null
    };
  },
  props: ["user", "item", "preLoad", "resetItem"],
  components: {},
  emits: ["copyHypothesis"],
  mounted() {
    // Loading is needed for the first item
    this.doPreLoad();
  },
  methods: {
    doPreLoad() {
      if (this.preLoad === "true") {
        this.hypo1 = { ...this.item.hypotheses[0] };
        this.hypo2 = { ...this.item.hypotheses[1] };
        this.hypo3 = { ...this.item.hypotheses[2] };
      } else {
        const cleanHyp = {
          statement: "",
          cause: "",
          effect: "",
          answered: "N/A"
        };
        this.hypo1 = { ...cleanHyp };
        this.hypo2 = { ...cleanHyp };
        this.hypo3 = { ...cleanHyp };
      }
      // console.log("** (EDIT) hypo1 =", this.hypo1.statement);
      // console.log("** (EDIT) hypo2 =", this.hypo2.statement);
      // console.log("** (EDIT) hypo3 =", this.hypo3.statement);
    },
    doCopyHypothesis(idx, hypo) {
      // console.log("Copy hypotheses idx =", idx, ", ", hypo);
      this.$emit("copyHypothesis", idx, hypo);
    },
    clearHypotheis(hypo) {
      hypo.statement = "";
      hypo.cause = "";
      hypo.effect = "";
      hypo.answered = "N/A";
    }
  },
  watch: {
    "item.olab_id"() {
      // Update if item is changed
      this.doPreLoad();
    },
    "hypo1.statement"() {
      this.doCopyHypothesis(0, this.hypo1);
    },
    "hypo1.cause"() {
      this.doCopyHypothesis(0, this.hypo1);
    },
    "hypo1.effect"() {
      this.doCopyHypothesis(0, this.hypo1);
    },
    "hypo1.answered"() {
      this.doCopyHypothesis(0, this.hypo1);
    },
    "hypo2.statement"() {
      this.doCopyHypothesis(1, this.hypo2);
    },
    "hypo2.cause"() {
      this.doCopyHypothesis(1, this.hypo2);
    },
    "hypo2.effect"() {
      this.doCopyHypothesis(1, this.hypo2);
    },
    "hypo2.answered"() {
      this.doCopyHypothesis(1, this.hypo2);
    },
    "hypo3.statement"() {
      this.doCopyHypothesis(2, this.hypo3);
    },
    "hypo3.cause"() {
      this.doCopyHypothesis(2, this.hypo3);
    },
    "hypo3.effect"() {
      this.doCopyHypothesis(2, this.hypo3);
    },
    "hypo3.answered"() {
      this.doCopyHypothesis(2, this.hypo3);
    },
    resetItem() {
      // console.log("** Hypo - olab_id =", this.item.olab_id);
      // Clean previously set hypotheses once add is excuted
      this.clearHypotheis(this.hypo1);
      this.clearHypotheis(this.hypo2);
      this.clearHypotheis(this.hypo3);
      // This is done by ExperimentAdd
      // this.$emit("clearResetItem");
    }
  }
};
</script>
