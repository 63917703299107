<template>
  <div v-if="user">
    <footer class="footer">
      <div class="container">
        <div class="logo-col">
          <span>
            <a href="/"
              ><img
                src="../assets/OLAB.svg"
                alt="OLAB Logo"
                height="15"
                class="mr-2"
            /></a>
          </span>
          <span class="text-secondary">
            Copyright &copy; 2024 by OLAB Bioscience, All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "OlabFooter",
  props: ["user"]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  display: flex;
  padding: 1.5rem 0;
  border-top: 1px solid #eee;
  text-align: center;
}
.logo-col {
  flex-direction: column;
}
</style>
