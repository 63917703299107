<template>
  <div>
    <div class="form-group card">
      <div class="card-header text-dark">
        Production Data
        <div
          v-if="localStatusObj.progress"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
          {{ localStatusObj.errorMessage }}
        </span>
        <span v-if="localStatusObj.message" class="mx-1 text-info">
          {{ localStatusObj.message }}
        </span>
      </div>
      <div class="card-body">
        <div v-if="batchUploads" class="mb-2">
          <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th class="small" scope="col">#</th>
                  <th class="small" scope="col">Uploaded By</th>
                  <th class="small" scope="col">Date</th>
                  <th class="small" scope="col">Count</th>
                  <th class="small" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bUpld, index) in batchUploads" :key="index">
                  <th class="small" scope="row">{{ index + 1 }}</th>
                  <td class="small">{{ bUpld.creator }}</td>
                  <td class="small">
                    {{ formatTimeStamp(bUpld.createdAt) }}
                  </td>
                  <td class="small">{{ bUpld.total_count }}</td>
                  <td class="small">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      :disabled="index === selectedIndex"
                      v-on:click="viewData(index, bUpld)"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- {{ batchUploads }} -->
        </div>
        <div>
          <div v-if="prodDatas" class="py-1">
            <div class="card">
              <div class="card-header text-dark">Loaded Product Data</div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Age (h)</th>
                        <th class="small" scope="col">OD(600nm)</th>
                        <th class="small" scope="col">PCW(%)</th>
                        <th class="small" scope="col">Product conc. (g/L)</th>
                        <th class="small" scope="col">Increased Volume(ml)</th>
                        <th class="small" scope="col">Fed carbon (g)</th>
                        <th class="small" scope="col">
                          Carbon conc. measured (g/L)
                        </th>
                        <th class="small" scope="col">By-products (g/L)</th>
                        <th class="small" scope="col">pH Control</th>
                        <th class="small" scope="col">20%NaOH used (L)</th>
                        <th class="small" scope="col">50% Antifoam used(ml)</th>
                        <th class="small" scope="col">B.O (L)</th>
                        <th class="small" scope="col">I/O (%)</th>
                        <th class="small" scope="col">
                          Total Accumulated Carbon (g)
                        </th>
                        <th class="small" scope="col">
                          Carbon In The Media (g)
                        </th>
                        <th class="small" scope="col">
                          Total Consumed Carbon (g)
                        </th>
                        <th class="small" scope="col">
                          Consumed Carbon between Sampling
                        </th>
                        <th class="small" scope="col">
                          Carbon Consumed Rate (g/hr)
                        </th>
                        <th class="small" scope="col">Product Adjusted (g)</th>
                        <th class="small" scope="col">
                          Production Rate (mg/L/hr)
                        </th>
                        <th class="small" scope="col">Yield (mg/g)</th>
                        <th class="small" scope="col">
                          Yield 12hrs Interval (mg/g)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in prodDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.age }}</td>
                        <td class="small">{{ pData.od_600nm }}</td>
                        <td class="small">{{ pData.pcw }}</td>
                        <td class="small">{{ pData.product_conc }}</td>
                        <td class="small">{{ pData.increased_vol }}</td>
                        <td class="small">{{ pData.fed_carbon }}</td>
                        <td class="small">
                          {{ pData.carbon_conc_measured }}
                        </td>
                        <td class="small">{{ pData.by_products }}</td>
                        <td class="small">{{ pData.ph_control }}</td>
                        <td class="small">{{ pData.naoh20pct_used }}</td>
                        <td class="small">
                          {{ pData.antifoam50pct_used }}
                        </td>
                        <td class="small">{{ pData.broth_out }}</td>
                        <td class="small">{{ pData.input_out }}</td>
                        <td class="small">
                          {{ pData.total_accumulated_carbon }}
                        </td>
                        <td class="small">{{ pData.carbon_in_media }}</td>
                        <td class="small">{{ pData.total_consumed_carbon }}</td>
                        <td class="small">
                          {{ pData.consumed_carbon_bw_sampling }}
                        </td>
                        <td class="small">{{ pData.carbon_consumed_rate }}</td>
                        <td class="small">{{ pData.product_adjusted }}</td>
                        <td class="small">{{ pData.production_rate }}</td>
                        <td class="small">{{ pData.yield }}</td>
                        <td class="small">{{ pData.yield_12h }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-2 float-right">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    v-on:click="clearDatas"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .card-body -->
      <div class="card-footer text-center">
        <!-- <span>Footer Production data</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabProdData } from "@/olab/olabproddata";

export default {
  name: "ProductionData",
  data() {
    return {
      prodDataUpload: null,
      selectedIndex: -1,
      prodDatas: null,
      batchUploads: null,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["user", "exprt", "culture", "reloadUpload"],
  async mounted() {
    // console.log("Mounted ProductionData: exprt =", this.exprt);
    // console.log("Mounted ProductionData: culture =", this.culture);
    await this.loadProductionUpload();
  },
  computed: {},
  methods: {
    formatTimeStamp(tm) {
      return OlabDate.formatTimeStamp(tm);
    },
    async viewData(index, bUpld) {
      await this.loadProdDatas(index, bUpld);
    },
    clearDatas() {
      this.prodDatas = null;
      this.selectedIndex = -1;
    },
    async loadProdDatas(index, bUpld) {
      this.selectedIndex = index;
      if (bUpld) {
        const searchBody = OlabSearch.createSearchBody(
          "bioreactor_pd",
          bUpld.origin_csv,
          "origin_csv"
        );
        this.prodDatas = await OlabProdData.getProductionDatas(
          searchBody,
          this.localStatusObj
        );

        // console.log("prodDatas =", this.prodDatas);
      }
    },
    async loadProductionUpload() {
      // reset
      this.batchUploads = null;

      this.prodDataUpload = await OlabAxios.loadProductionUpload(
        this.user,
        this.culture,
        this.localStatusObj
      );
      if (this.prodDataUpload) {
        this.batchUploads = this.prodDataUpload.batch_uploads;
      } else if (
        this.localStatusObj &&
        this.localStatusObj.errorMessage &&
        this.localStatusObj.errorMessage.includes("404")
      ) {
        // Reset errorMessage since it is possible to have no upload data
        // console.log("errorMessage =", this.localStatusObj.errorMessage);
        this.localStatusObj.errorMessage = null;
      }
      // console.log(
      //   "loadProductionUpload: prodDataUpload =",
      //   this.prodDataUpload
      // );
      // console.log("batchUploads =", this.batchUploads);
    }
  },
  watch: {
    "culture.olab_id"() {
      // console.log("watch: culture =", this.culture);
      this.batchUploads = null;
      this.prodDataUpload = null;
      this.clearDatas();
      // Reload production uploads
      this.loadProductionUpload();
    },
    reloadUpload() {
      // console.log("watch: reloadUpload =", this.culture);
      // Reload production uploads
      this.loadProductionUpload();
      this.$emit("setReloadUpload", false);
    }
  }
};
</script>
