<template>
  <div>
    <div
      v-if="statusObj.errorMessage"
      class="col-12 alert alert-danger text-center px-3"
    >
      {{ statusObj.errorMessage }}
      <div class="text-center mt-2">
        <button type="button" class="btn btn-danger" v-on:click="closeError()">
          OK
        </button>
      </div>
    </div>
    <div
      v-if="statusObj.message"
      class="col-12 alert alert-info text-center fade show px-3"
    >
      <span>{{ statusObj.message }}</span>
      <span v-if="statusObj.progress" class="mx-2 spinner-grow" role="status">
        <span class="sr-only">Status information</span>
      </span>
      <div class="text-center mt-2">
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="closeStatus()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OlabStatusObjAlerts",
  data() {
    return {};
  },
  props: ["statusObj"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.doEmitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.doEmitErrorMessage(null);
    }
  }
};
</script>
