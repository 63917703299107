<template>
  <div>
    <h3>Status</h3>
    <ul class="nav nav-tabs" id="statusTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="olab-complete-tab"
          data-toggle="tab"
          href="#olab-complete"
          role="tab"
          aria-controls="olab-complete"
          aria-selected="true"
          >Complete
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="olab-preview-tab"
          data-toggle="tab"
          href="#olab-preview"
          role="tab"
          aria-controls="olab-preview"
          aria-selected="false"
          >Preview
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="olab-in-progress-tab"
          data-toggle="tab"
          href="#olab-in-progress"
          role="tab"
          aria-controls="olab-in-progress"
          aria-selected="false"
          >In-progress
        </a>
      </li>
    </ul>
    <div class="tab-content" id="statusTabContent">
      <div
        class="tab-pane fade show active"
        id="olab-complete"
        role="tabpanel"
        aria-labelledby="olab-complete-tab"
      >
        <div class="form-group mt-3">
          <OlabComplete />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="olab-preview"
        role="tabpanel"
        aria-labelledby="olab-preview-tab"
      >
        <div class="form-group mt-3">
          <OlabPreview />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="olab-in-progress"
        role="tabpanel"
        aria-labelledby="olab-in-progress-tab"
      >
        <div class="form-group mt-3">
          <OlabInProgress />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabComplete from "@/components/status/OlabComplete";
import OlabPreview from "@/components/status/OlabPreview";
import OlabInProgress from "@/components/status/OlabInProgress";

export default {
  name: "OlabStatus",
  components: {
    // FontAwesomeIcon
    OlabComplete,
    OlabPreview,
    OlabInProgress
  }
};
</script>
