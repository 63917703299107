<template>
  <div v-if="selectedType === 'project'">
    <div class="py-1 text-wrap">
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div class="py-1 text-wrap">
      <span class="mr-1">Start Date:</span>
      <span class="ml-1">{{ item.start_date }}</span>
    </div>
    <div class="py-1 text-wrap">
      <span class="mr-1">End Date:</span>
      <span class="ml-1">{{ item.end_date }}</span>
    </div>

    <div v-if="item.ra_locks" class="form-row">
      <div class="form-group col-md-3">
        <div class="py-1 text-wrap">
          <span class="mr-1">Division:</span>
          <span class="ml-1">{{ item.division }}</span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="py-1 text-wrap">
          <span class="mr-1">Public Access:</span>
          <span class="ml-1">{{ item.ra_locks.public }}</span>
        </div>
        <div>
          <small v-if="item.ra_locks.public" class="text-info">
            Viewable by all members in the {{ item.division }} Division
          </small>
          <small v-else class="text-info">
            Viewable by members of this project and the leads in
            {{ item.division }}
          </small>
        </div>
      </div>
    </div>
    <!-- Add members if public is false -->
    <div vif="item.ra_locks">
      <div v-if="!item.ra_locks.public">
        <div class="py-1 text-wrap">
          <span class="mr-1">Members:</span>
        </div>
        <div v-if="item.ra_locks.members" class="py-1 text-wrap">
          <ol>
            <!-- Loop through members and display them in the ordered list -->
            <li v-for="(mbr, index) in item.ra_locks.members" :key="index">
              {{ mbr }}
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="py-1">
      <div class="card">
        <div class="card-header text-dark">Description</div>
        <div class="card-body">
          <div class="py-1 small text-dark text-wrap">
            {{ item.desc }}
          </div>
        </div>
        <!-- .card-body -->
      </div>
      <!-- .card -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectView",
  props: ["dbInfo", "selectedType", "item"]
};
</script>
