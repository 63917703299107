<template>
  <div v-if="item" class="modal fade" id="viewItem">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            OLAB ID:
            <span class="h5 text-dark">{{ item.olab_id }}</span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- .modal-header -->
        <div class="modal-body">
          <div class="container text-left mb-2">
            <div class="py-1 text-wrap">
              <span class="mr-1">Module:</span>
              <span class="ml-1">{{ capitalizeModule }}</span>
            </div>
            <div class="py-1 text-wrap">
              <span class="mr-1">Question:</span>
              <span class="ml-1">{{ item.question }}</span>
            </div>
            <div class="py-1 text-wrap">
              <span class="mr-1">Answer:</span>
              <span class="ml-1">{{ item.answer }}</span>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div
              v-for="sect in item.sections"
              :key="sect.id"
              class="list-group-item d-flex mb-1 align-items-center"
            >
              <div v-if="sect" class="container">
                <div class="row">
                  <div class="container">
                    <div class="text-left mb-2">
                      <span class="mr-1">{{ sect.id }}.</span>
                      <span class="ml-1">{{ sect.description }}</span>
                    </div>
                    <div class="image-container">
                      <img
                        :src="getImageSrc(sect.image)"
                        style="overflow-x: scroll; overflow-y: scroll"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="form-row justify-content-between font-italic">
            <div class="col-md-6">Updated at: {{ formatTimeStamp }}</div>
            <div class="col-md-6">Created by: {{ item.creator }}</div>
          </div>
        </div>
        <!-- .modal-body -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
        <!-- .modal-footer -->
      </div>
      <!-- .modal-content -->
    </div>
    <!-- .modal-dialog .modal-lg-->
  </div>
  <!-- </div> -->
  <!-- .modal fade #viewItem -->
</template>

<script>
import { OlabDate } from "@/olab/olabdate";

export default {
  name: "FAQViewForm",
  data() {
    return {
      // testImage: "faq-demo.svg"
    };
  },
  props: ["dbInfo", "item"],
  components: {},
  // mounted() {
  //   console.log("*** dbInfo =", this.dbInfo);
  //   console.log("*** selectedType =", this.selectedType);
  //   console.log("*** item =", this.item);
  // },
  computed: {
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.item) {
        const createdAt = this.item.createdAt;
        const updatedAt = this.item.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    },
    capitalizeModule() {
      return (
        this.item.module.charAt(0).toUpperCase() + this.item.module.slice(1)
      );
    }
  },
  methods: {
    getImageSrc(imageName) {
      // Dynamically require the image from the assets folder
      return require(`@/assets/data/faq/svg-file/${imageName}`);
    }
  }
};
</script>

<style scoped>
.image-container {
  overflow: scroll;
}
</style>
