<template>
  <div v-if="localRange && limits && limits.length === 2">
    <p class="mt-2 mb-5">{{ name }}</p>
    <vue-slider
      v-model="localRange"
      :min="limits[0]"
      :max="limits[1]"
      :interval="1"
      :tooltip="'always'"
      :dotOptions="{ style: { width: '18px', height: '18px' } }"
      :disabled="disabled"
    ></vue-slider>
    <!-- <p class="mt-5">Selected localRange: {{ localRange }}</p> -->
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  components: {
    VueSlider
  },
  data() {
    return {
      localRange: null
    };
  },
  props: ["name", "range", "limits", "disabled"],
  mounted() {
    this.localRange = [this.range[0], this.range[1]];
    // console.log("range =", this.range);
    // console.log("localRange =", this.localRange);
    // window.addEventListener("keydown", this.onKeydown);
    window.addEventListener("keyup", this.onKeyup);
    window.addEventListener("mouseup", this.emitFinalEvent);
    window.addEventListener("touchend", this.emitFinalEvent);
  },
  beforeUnmount() {
    // window.removeEventListener("keydown", this.onKeydown);
    window.removeEventListener("keyup", this.onKeyup);
    window.removeEventListener("mouseup", this.emitFinalEvent);
    window.removeEventListener("touchend", this.emitFinalEvent);
  },
  methods: {
    // onKeydown(event) {
    //   // console.log("onkeydown");
    //   if (event.key === "ArrowLeft") {
    //     this.handleLeftArrow(event);
    //   } else if (event.key === "ArrowRight") {
    //     this.handleRightArrow(event);
    //   }
    // },
    onKeyup(event) {
      // console.log("** onkeyup =", event);
      if (event.key === "ArrowLeft") {
        this.handleLeftArrow(event);
      } else if (event.key === "ArrowRight") {
        this.handleRightArrow(event);
      }
    },
    handleLeftArrow(evt) {
      // console.log("Left arrow key pressed");
      // Implement your logic for left arrow key press
      this.$emit("emitFinalEvent", evt);
    },
    handleRightArrow(evt) {
      // console.log("Right arrow key pressed");
      // Implement your logic for right arrow key press
      this.$emit("emitFinalEvent", evt);
    },
    emitFinalEvent(evt) {
      // console.log("emitFinalEvent =", evt);
      this.$emit("emitFinalEvent", evt);
    }
  },
  watch: {
    localRange(newValue) {
      // console.log("localRange =", newValue);
      this.$emit("updateRange", newValue); // Emit event to update range in parent
    }
  }
};
</script>

<style scoped>
/* Custom styling for the slider if necessary */
</style>
