<template>
  <div v-if="selectedType === 'manufacturer'">
    <div class="form-group">
      <div class="form-row">
        <span class="mr-1">Company Name:</span>
        <span class="ml-1">{{ item.name }}</span>
      </div>
    </div>
    <div>
      <div class="form-group">
        <div class="form-row">
          <div class="mr-1">Address:<br /></div>
          <div>
            {{ item.addr1 }} <br />
            {{ item.addr2 }} <br />
            City: {{ item.city }}<br />
            State: {{ item.state }}<br />
            Zipcode: {{ item.zipcode }}<br />
            Country: {{ item.country }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-row">
          <div class="mr-1">Contact Name:<br /></div>
          <div>
            {{ item.contact_name }}<br />
            Email: {{ item.email }} <br />
            Phone: {{ item.phone }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <span class="mr-1">Note:</span>
        <span class="ml-1">{{ item.note }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManufacturerView",
  props: ["dbInfo", "selectedType", "item"]
};
</script>
