<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="200">
      <g transform="translate(150,150)">
        <path
          :fill="computedFill"
          transform="scale(0.5)"
          d="M384 320H256c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM192 32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v128c0 17.67 14.33 32 32 32h95.72l73.16 128.04C211.98 300.98 232.4 288 256 288h.28L192 175.51V128h224V64H192V32zM608 0H480c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32z"
        />
      </g>
      <text
        :x="namePosX"
        :y="namePosY"
        fill="#f80"
        style="
          font-size: 80px;
          font-weight: normal;
          text-align: start;
          text-anchor: start;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
          font-family: Arial;
        "
        id="olab-text"
        xml:space="preserve"
      >
        {{ computedName }}
      </text>
      <text
        :x="computedRolePosX"
        :y="rolePosY"
        fill="#f80"
        style="
          font-size: 80px;
          font-weight: normal;
          text-align: start;
          text-anchor: start;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
          font-family: Arial;
        "
        id="olab-text"
        xml:space="preserve"
      >
        {{ computedRole }}
      </text>
    </svg>
  </div>
</template>

<script>
import { OlabUtils } from "../olab/olabutils.js";

export default {
  name: "OlabProjectSVGGen",
  data() {
    return {
      title: "OlabProjectSVGGen",
      namePosX: 190,
      namePosY: 250,
      rolePosX: 160,
      rolePosY: 350
    };
  },
  props: ["strain", "proj", "fillColor"],
  computed: {
    computedFill() {
      switch (this.fillColor) {
        case "compdb":
          return "#ffeedd";
        default:
          return "#eeeeee";
      }
    },
    computedRolePosX() {
      let xPos = this.rolePosX;
      if (this.strain) {
        switch (this.computedRole) {
          case "member":
            xPos += 5;
            break;
        }
      }
      return xPos;
    },
    computedRole() {
      if (this.strain) {
        return OlabUtils.getStrainRole(this.proj, this.strain);
      }
      return "";
    },
    computedName() {
      return this.strain ? "Strain" : "Project";
    }
  }
};
</script>
