<template>
  <div class="container">
    <div class="mt-4">
      <h3>Stats</h3>
      <ul class="nav nav-tabs" id="statsTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="olab-stats-system-tab"
            data-toggle="tab"
            href="#olab-stats-system"
            role="tab"
            aria-controls="olab-stats-system"
            aria-selected="true"
            v-on:click="loadSystemStats()"
            >System
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="olab-stats-project-tab"
            data-toggle="tab"
            href="#olab-stats-project"
            role="tab"
            aria-controls="olab-stats-project"
            aria-selected="false"
            >Project
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="olab-stats-user-tab"
            data-toggle="tab"
            href="#olab-stats-user"
            role="tab"
            aria-controls="olab-stats-user"
            aria-selected="false"
            >User
          </a>
        </li>
      </ul>
      <OlabStatusObjAlerts
        :statusObj="localStatusObj"
        @emitStatusMessage="emitStatusMessage"
        @emitErrorMessage="emitErrorMessage"
      />
      <div class="tab-content" id="statsTabContent">
        <div
          class="tab-pane fade show active"
          id="olab-stats-system"
          role="tabpanel"
          aria-labelledby="olab-stats-system-tab"
        >
          <div v-if="system" class="form-group mt-3">
            <OlabSystemTab :system="system" />
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="olab-stats-project"
          role="tabpanel"
          aria-labelledby="olab-stats-project-tab"
        >
          <div class="form-group mt-3">
            <OlabProjectTab
              :user="user"
              :dbInfo="dbInfo"
              :statusObj="localStatusObj"
            />
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="olab-stats-user"
          role="tabpanel"
          aria-labelledby="olab-stats-user-tab"
        >
          <div class="form-group mt-3">
            <OlabUserTab
              :user="user"
              :dbInfo="dbInfo"
              :statusObj="localStatusObj"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import OlabStatusObjAlerts from "@/components/OlabStatusObjAlerts.vue";
import OlabSystemTab from "@/components/stats/OlabSystemTab";
import OlabProjectTab from "@/components/stats/OlabProjectTab";
import OlabUserTab from "@/components/stats/OlabUserTab";

export default {
  name: "OlabStats",
  data() {
    return {
      system: null,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  components: {
    OlabStatusObjAlerts,
    OlabSystemTab,
    OlabProjectTab,
    OlabUserTab
  },
  props: ["user", "dbInfo"],
  mounted() {
    this.emitStatusMessage(null, false);
    this.emitErrorMessage(null);
    this.loadSystemStats();
  },
  methods: {
    async loadSystemStats() {
      // console.log("loadSystemStats");
      this.system = await OlabAxios.getSystemStats(this.localStatusObj);
    },
    emitStatusMessage(statusMsg, statusProgress) {
      this.localStatusObj.message = statusMsg;
      this.localStatusObj.progress = statusProgress;
    },
    emitErrorMessage(errMsg) {
      this.localStatusObj.errorMessage = errMsg;
    }
  }
};
</script>
