<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'flask'">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-row">
        <div class="form-group col-md-8">
          <label for="inp-add-name">Name</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-bind:class="validState(item.name)"
              v-model="item.name"
              id="inp-add-name"
            />
          </div>
        </div>
      </div>
      <div>
        <OlabStatusObjDialogs
          :statusObj="localStatusObj"
          :showStatusObjDialog="showStatusObjDialog"
          @emitStatusMessage="emitStatusMessage"
          @emitErrorMessage="emitErrorMessage"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-8">
          <label for="inp-add-mfr-id">Manufacturer ID</label>
          <div>
            <VueMultiselect
              id="inp-add-mfr-id"
              v-model="manufacturer"
              :options="manufacturers"
              :loading="isLoadingMfrs"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              placeholder="Select a Manufacturer ..."
              :options-limit="10"
              :custom-label="mfrInfo"
              label="olab_id"
              track-by="olab_id"
              @search-change="getMfrs"
            />
          </div>
        </div>
      </div>
      <!-- <p v-if="manufacturer">{{ manufacturer }}</p> -->
      <!-- <p v-if="item">{{ item.mfr_id }}</p> -->
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inp-add-type">Type</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-bind:class="validState(item.type)"
              v-model="item.type"
              id="inp-add-type"
            />
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="inp-add-size">Size</label>
          <div class="input-group">
            <select
              class="form-control"
              v-bind:class="validState(item.size)"
              v-model="item.size"
              id="inp-add-size"
            >
              <option disabled>Select...</option>
              <option>100</option>
              <option>250</option>
              <option>500</option>
              <option>1000</option>
              <option>5000</option>
            </select>
            <div class="input-group-append">
              <span class="input-group-text">ml</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="inp-add-desc">Description</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.desc"
          id="inp-add-desc"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="inp-add-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-add-note"
        ></textarea>
      </div>
      <div class="form-group float-right">Created by: {{ item.creator }}</div>
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch.js";
import VueMultiselect from "vue-multiselect";
import OlabStatusObjDialogs from "@/components/OlabStatusObjDialogs.vue";

export default {
  name: "FlaskAdd",
  data() {
    return {
      manufacturer: null,
      invalidFB: OlabItem.getInvalidFeedbacks(),
      manufacturers: [],
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      },
      showStatusObjDialog: false,
      isLoadingMfrs: false
    };
  },
  props: ["user", "dbInfo", "selectedType", "item", "statusObj"],
  components: {
    OlabStatusObjDialogs,
    VueMultiselect
  },
  async mounted() {
    // console.log("Flask Add Mount...");
    await this.getMfrs("");
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.localStatusObj.message = statusMsg;
      this.localStatusObj.progress = statusProgress;
      this.showStatusObjDialog = this.localStatusObj.message != null;
    },
    emitErrorMessage(errMsg) {
      this.localStatusObj.errorMessage = errMsg;
      this.showStatusObjDialog = this.localStatusObj.errorMessage != null;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    mfrInfo({ olab_id, name }) {
      return `${olab_id} - ${name}`;
    },
    async getMfrs(query) {
      // console.log("getMfrs: query = ", query);
      this.isLoadingMfrs = true;
      const searchBody = OlabSearch.createSearchBody(
        "manufacturer",
        query,
        "merge"
      );
      this.manufacturers = await OlabAxios.getManufacturers(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingMfrs = false;
    }
  },
  watch: {
    manufacturer() {
      // console.log("manufacturer =", this.manufacturer);
      const mfrID =
        this.manufacturer && this.manufacturer.olab_id
          ? this.manufacturer.olab_id
          : null;
      this.item.setMfrID(mfrID);
    }
  }
};
</script>
