<template>
  <div v-if="selectedType === 'user'">
    <div class="py-1 text-wrap">
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div class="py-1 text-wrap">
      <span class="mr-1">Email:</span>
      <span class="ml-1">{{ item.email }}</span>
    </div>
    <div class="py-1 text-wrap">
      <span class="mr-1">Division:</span>
      <span class="ml-1">{{ item.division }}</span>
    </div>
    <div class="py-1 text-wrap">
      <span class="mr-1">System Role:</span>
      <span class="ml-1">{{ item.role }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserView",
  props: ["dbInfo", "selectedType", "item"]
};
</script>
