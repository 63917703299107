<template>
  <div>
    <div class="card-body">
      <div class="form-row justify-content-center">
        <div class="col col-md-8 mb-3">
          <VueMultiselect
            v-model="project"
            :options="projects"
            :loading="isLoadingProjects"
            :internal-search="false"
            :multiple="false"
            :close-on-select="true"
            :options-limit="10"
            :custom-label="projectInfo"
            label="olab_id"
            track-by="olab_id"
            placeholder="Type to search ..."
            @search-change="getProjects"
          />
        </div>
        <!-- <span>selected = {{ project }}</span> -->
      </div>
      <div v-if="projStats && projStats.project" class="mt-3">
        <div class="form-group mb-5">
          <div class="form-row">
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label"> From Date </label>
              <input class="form-control" type="date" v-model="projFromDate" />
            </div>
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label">To Date</label>
              <input class="form-control" type="date" v-model="projToDate" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">OLAB ID:</span>
            <span class="ml-1">{{ projStats.project.olab_id }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">Total Memory:</span>
            <span class="ml-1">{{
              formatMemorySize(projStats.total_memory)
            }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <span class="mr-1">Name:</span>
            <span class="ml-1">{{ projStats.project.name }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">Start Date:</span>
            <span class="ml-1">{{ projStats.project.start_date }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">End Date:</span>
            <span class="ml-1">{{ projStats.project.end_date }}</span>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Collection</th>
              <th scope="col">Memory</th>
              <th scope="col">Count</th>
              <!-- <th scope="col">Deleted</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(collection, index) in projStats.collections"
              :key="index"
            >
              <th scope="row">
                {{ collection.name }}
              </th>
              <td>{{ formatMemorySize(collection.memory) }}</td>
              <td>{{ collection.count }}</td>
              <!-- <td>{{ collection.deleted }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabUtils } from "@/olab/olabutils.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";

export default {
  name: "OlabProjectTab",
  data() {
    return {
      projStats: null,
      project: null,
      projects: [],
      isLoadingProjects: false,
      projFromDate: null,
      projToDate: null
    };
  },
  components: {
    VueMultiselect
  },
  props: ["user", "dbInfo", "statusObj"],
  async mounted() {
    await this.getProjects("");
  },
  methods: {
    formatMemorySize(size) {
      return OlabUtils.formatMemorySize(size);
    },
    projectInfo({ olab_id, name }) {
      return `${name} [${olab_id}]`;
    },
    async getProjects(query) {
      // console.log("getProjects: query = ", query);
      this.isLoadingProjects = true;
      const searchBody = OlabSearch.createSearchBody("project", query, "merge");
      this.projects = await OlabAxios.getProjects(searchBody, this.statusObj);
      this.isLoadingProjects = false;
    },
    async loadProjectStats() {
      // console.log("loadProjectStats: proj =", this.projStats);
      this.projStats = await OlabAxios.getProjectStats(
        this.project.olab_id,
        this.projFromDate,
        this.projToDate,
        this.statusObj
      );
    }
  },
  watch: {
    project() {
      // console.log("project =", this.project);
      if (this.project) {
        this.loadProjectStats();
      } else {
        this.projStats = null;
      }
    },
    projFromDate() {
      // console.log("projFromDate =", this.projFromDate);
      if (this.projStats) {
        this.loadProjectStats();
      }
    },
    projToDate() {
      // console.log("projToDate =", this.projToDate);
      if (this.projStats) {
        this.loadProjectStats();
      }
    }
  }
};
</script>
