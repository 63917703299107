<template>
  <div v-if="selectedType === 'recipe'">
    <div class="py-1 text-wrap">
      <span class="mr-1 h5">Recipe Name:</span>
      <span class="ml-1 h5">{{ item.name }}</span>
    </div>
    <table class="table">
      <thead>
        <th scope="col">Substance</th>
        <th scope="col">
          Recipe <br />
          {{ item.volume }}
          {{ item.unit }}
        </th>
      </thead>
      <tbody>
        <tr v-for="ingredient in item.ingredients" v-bind:key="ingredient">
          <th scope="row">{{ ingredient.name }}, {{ ingredient.chem_id }}</th>
          <td>{{ ingredient.qty }} {{ ingredient.unit }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pb-3">
      <span>Adjust final volume to</span>
      <span class="h5 text-primary bg-light mx-1"
        >{{ item.volume }} {{ item.unit }}</span
      >
      <span>with Milli-Q Water</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecipeView",
  props: ["dbInfo", "selectedType", "item"]
};
</script>
