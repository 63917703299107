<template>
  <div class="calulatorface">
    <div class="container text-left mt-3">
      <h1>Solution Dilution Calculator</h1>
      <p>
        This bidirectional solution dilution calculator provides an easy-to-use
        approach to calculate dilution solution.
      </p>
      <p>
        You fill in any 3 of the 4 components and we will compute the remaining
        component.
      </p>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="statusObj.errorMessage"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ statusObj.errorMessage }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div>
        <legend for="selectConcentration">Select concentration in</legend>
        <!-- <select
          class="form-control ml-2 text-light bg-secondary"
          v-model="selectedConc"
        >
          <option selected>Molar</option>
          <option>Mass/Volume (mg/L etc)</option>
        </select> -->
        <div>
          <input
            type="radio"
            v-model="selectedConc"
            value="Molar"
            id="selectedConcentration1"
          />
          <label class="ml-2" for="selectedConcentration1">Molar</label><br />
          <input
            type="radio"
            v-model="selectedConc"
            value="Mass/Volume"
            id="selectedConcentration2"
          />
          <label class="ml-2" for="selectedConcentration2"
            >Volume (mg/L etc)</label
          >
          <br />
        </div>
      </div>
    </div>

    <div class="container text-left">
      <div class="row justify-content-center">
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Stock&nbsp;Concentration</legend>
            <input
              name="Stock_concentration"
              type="number"
              class="form-control"
              placeholder="Stock Concentration"
              v-bind:disabled="calculateField == 'calculateStockConcentration'"
              v-model="stockconcentration"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="stockConc"
                >
                  <option
                    v-for="selectConcFactor in selectConcFactors"
                    :value="selectConcFactor"
                    v-bind:key="selectConcFactor.unit"
                  >
                    {{ selectConcFactor.unit }}
                  </option>
                </select>
                <!-- <select
                  v-if="selectedConc === 'Molar'"
                  class="form-control text-light bg-secondary"
                  v-model="stockconcentrationfactor"
                >
                  <option value="1">molar (M)</option>
                  <option value="0.001">milimolar (mM)</option>
                  <option value="0.000001">micromolar (μM)</option>
                  <option value="0.000000001">nanomolar (nM)</option>
                  <option value="0.000000000001">picomolar (pM)</option>
                </select>
                <select
                  v-if="selectedConc === 'Mass/Volume'"
                  class="form-control text-light bg-secondary"
                  v-model="stockconcentrationfactor"
                >
                  <option value="1">mg/L or ppm</option>
                  <option value="1000">g/L</option>
                </select> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Stock&nbsp;Volume</legend>
            <input
              name="Stock_volume"
              type="number"
              class="form-control"
              placeholder="Stock Volume"
              v-bind:disabled="calculateField == 'calculateStockVolume'"
              v-model="stockvolume"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="stockvolumefactor"
                >
                  <option value="1">liters (L)</option>
                  <option value="0.001">mililiters (mL)</option>
                  <option value="0.000001">microliters (μl)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- Desired Concentration & Concentration factor -->
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Desired&nbsp;Concentration</legend>
            <input
              name="Desired_concentration"
              type="number"
              class="form-control"
              placeholder="Desired Concentration"
              v-bind:disabled="
                calculateField == 'calculateDesiredConcentration'
              "
              v-model="desiredconcentration"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="desiredConc"
                >
                  <option
                    v-for="selectConcFactor in selectConcFactors"
                    :value="selectConcFactor"
                    v-bind:key="selectConcFactor.unit"
                  >
                    {{ selectConcFactor.unit }}
                  </option>
                </select>
                <!-- <select
                  v-if="selectedConc === 'Molar'"
                  class="form-control text-light bg-secondary"
                  v-model="desiredconcentrationfactor"
                >
                  <option value="1">molar (M)</option>
                  <option value="0.001">milimolar (mM)</option>
                  <option value="0.000001">micromolar (μM)</option>
                  <option value="0.000000001">nanomolar (nM)</option>
                  <option value="0.000000000001">picomolar (pM)</option>
                </select>
                <select
                  v-if="selectedConc === 'Mass/Volume'"
                  class="form-control text-light bg-secondary"
                  v-model="desiredconcentrationfactor"
                >
                  <option value="1">mg/L or ppm</option>
                  <option value="1000">g/L</option>
                </select> -->
              </div>
            </div>
          </div>
        </div>
        <!-- Desired Volume & Volume factor -->
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Desired&nbsp;Volume</legend>
            <input
              name="Desired_volume"
              type="number"
              class="form-control"
              placeholder="Desired Volume"
              v-bind:disabled="calculateField == 'calculateDesiredVolume'"
              v-model="desiredvolume"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="desiredvolumefactor"
                >
                  <option value="1">liters (L)</option>
                  <option value="0.001">mililiters (mL)</option>
                  <option value="0.000001">microliters (μl)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center my-4">
          <button
            class="btn btn-brand mr-2"
            v-on:click="calculate"
            v-bind:disabled="show != 3"
          >
            Calculate
          </button>
          <button class="btn btn-brand mr-2" v-on:click="reset">Reset</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DilutionCalculator",
  data() {
    return {
      intVal: "",
      calculateField: "",
      selectedConc: "",
      stockconcentration: "",
      desiredvolume: "",
      desiredvolumefactor: 1,
      desiredconcentration: "",
      stockvolume: "",
      stockvolumefactor: 1,
      concFactorLists: [
        { unit: "molar (M)", factor: 1, type: "Molar" },
        { unit: "milimolar (mM)", factor: 1e-3, type: "Molar" },
        { unit: "micromolar (μM)", factor: 1e-6, type: "Molar" },
        { unit: "nanomolar (nM)", factor: 1e-9, type: "Molar" },
        { unit: "picomolar (pM)", factor: 1e-12, type: "Molar" },
        { unit: "part per million (ppm)", factor: 1, type: "Mass/Volume" },
        { unit: "milligram/litre (mg/L)", factor: 1, type: "Mass/Volume" },
        { unit: "microgram/litre (μg/L)", factor: 1e-3, type: "Mass/Volume" },
        { unit: "gram/litre (g/L)", factor: 1e3, type: "Mass/Volume" },
        {
          unit: "milligram/millilitre (mg/mL)",
          factor: 1e3,
          type: "Mass/Volume"
        },
        {
          unit: "microgram/millilitre (μg/mL)",
          factor: 1,
          type: "Mass/Volume"
        },
        { unit: "gram/millilitre (g/mL)", factor: 1e6, type: "Mass/Volume" }
      ],
      selectConcFactors: [],
      stockConc: [],
      desiredConc: []
    };
  },
  props: ["statusObj"],
  emits: ["setErrorMessage"],
  methods: {
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.$emit("setErrorMessage", null);
    },
    calculate() {
      let stockconcentration1 =
        this.stockconcentration == "" ? 0 : this.stockconcentration;
      let desiredvolume1 = this.desiredvolume == "" ? 0 : this.desiredvolume;
      let desiredconcentration1 =
        this.desiredconcentration == "" ? 0 : this.desiredconcentration;
      let stockvolume1 = this.stockvolume == "" ? 0 : this.stockvolume;
      console.log("run calculate. calculateField = ", this.calculateField);

      if (stockvolume1 == 0) {
        this.calculateField = "calculateStockVolume";
        this.calculateStockVolume();
      } else if (desiredvolume1 == 0) {
        this.calculateField = "calculateDesiredVolume";
        this.calculateDesiredVolume();
      } else if (desiredconcentration1 == 0) {
        this.calculateField = "calculateDesiredConcentration";
        this.calculateDesiredConcentration();
      } else if (stockconcentration1 == 0) {
        this.calculateField = "calculateStockConcentration";
        this.calculateStockConcentration();
      }
    },
    reset() {
      this.calculateField = "";
      this.selectedConc = "";
      this.stockconcentration = "";
      this.stockvolume = "";
      this.desiredconcentration = "";
      this.desiredvolume = "";
      this.stockConc = [];
      this.stockvolumefactor = 1;
      this.desiredConc = [];
      this.desiredvolumefactor = 1;
      console.log("reset");
    },
    calculateStockVolume() {
      if (
        this.stockconcentration > 0 &&
        this.stockconcentration * this.stockConc.factor >=
          this.desiredconcentration * this.desiredConc.factor
      ) {
        this.emitErrorMessage("");
        this.stockvolume =
          (this.desiredconcentration *
            this.desiredConc.factor *
            this.desiredvolume *
            this.desiredvolumefactor) /
          (this.stockconcentration *
            this.stockConc.factor *
            this.stockvolumefactor);
        console.log("calculateStockVolume function run");
      } else {
        this.emitErrorMessage(
          "stock concentration cannot be zero or less than desired concentration"
        );
      }
    },
    calculateDesiredVolume() {
      if (
        this.desiredconcentration > 0 &&
        this.stockconcentration * this.stockConc.factor >=
          this.desiredconcentration * this.desiredConc.factor
      ) {
        this.emitErrorMessage("");
        this.desiredvolume =
          (this.stockconcentration *
            this.stockConc.factor *
            this.stockvolume *
            this.stockvolumefactor) /
          (this.desiredconcentration *
            this.desiredConc.factor *
            this.desiredvolumefactor);
        console.log("calculateDesiredVolume function run");
      } else {
        this.emitErrorMessage(
          "desired concentration cannot be zero or more than stock concentration"
        );
      }
    },
    calculateStockConcentration() {
      if (
        this.stockvolume > 0 &&
        this.stockvolume * this.stockvolumefactor <=
          this.desiredvolume * this.desiredvolumefactor
      ) {
        this.emitErrorMessage("");
        this.stockconcentration =
          (this.desiredconcentration *
            this.desiredConc.factor *
            this.desiredvolume *
            this.desiredvolumefactor) /
          (this.stockvolume * this.stockvolumefactor * this.stockConc.factor);
        console.log("calculateStockConcentration function run");
      } else {
        this.emitErrorMessage(
          "stock volume cannot be zero or more than desired volume"
        );
      }
    },
    calculateDesiredConcentration() {
      if (
        this.desiredvolume > 0 &&
        this.desiredvolume * this.desiredvolumefactor >=
          this.stockvolume * this.stockvolumefactor
      ) {
        this.emitErrorMessage("");
        this.desiredconcentration =
          (this.stockconcentration *
            this.stockConc.factor *
            this.stockvolume *
            this.stockvolumefactor) /
          (this.desiredvolume *
            this.desiredvolumefactor *
            this.desiredConc.factor);
        console.log("calculateDesiredConcentration function run");
      } else {
        this.emitErrorMessage(
          "desired volume cannot be zero or less than stock volume"
        );
      }
    },
    recalculateField() {
      if (this.calculateField == "") {
        return;
      } else if (this.calculateField == "calculateStockVolume") {
        this.calculateStockVolume();
      } else if (this.calculateField == "calculateDesiredVolume") {
        this.calculateDesiredVolume();
      } else if (this.calculateField == "calculateStockConcentration") {
        this.calculateStockConcentration();
      } else if (this.calculateField == "calculateDesiredConcentration") {
        this.calculateDesiredConcentration();
      }
      console.log("recalculateField run");
      return {};
    }
  },
  computed: {
    show() {
      let show =
        (this.stockconcentration == "" || this.stockconcentration <= 0
          ? 0
          : 1) +
        (this.desiredvolume == "" || this.desiredvolume <= 0 ? 0 : 1) +
        (this.desiredconcentration == "" || this.desiredconcentration <= 0
          ? 0
          : 1) +
        (this.stockvolume == "" || this.stockvolume <= 0 ? 0 : 1);
      console.log("computed show run");
      return show;
    }
  },
  watch: {
    stockconcentration() {
      console.log("watch calling stockconcentration", this.stockconcentration);
      if (this.stockconcentration < 0) {
        this.stockconcentration = 0;
      }
      this.recalculateField();
    },
    stockConc() {
      if (this.stockConc.unit === "") {
        return;
      } else {
        console.log("watch calling stockConc");
        this.recalculateField();
      }
    },
    stockvolume() {
      console.log("watch calling stockvolume", this.stockvolume);
      if (this.stockvolume < 0) {
        this.stockvolume = 0;
      }
      this.recalculateField();
    },
    stockvolumefactor() {
      console.log("watch calling stockvolumefactor", this.stockvolumefactor);
      this.recalculateField();
    },
    desiredconcentration() {
      console.log("watch calling desiredConc");
      if (this.desiredconcentration < 0) {
        this.desiredconcentration = 0;
      }
      this.recalculateField();
    },
    desiredConc() {
      if (this.desiredConc.unit === "") {
        return;
      } else {
        console.log(
          "watch calling desiredconcentractionfactor",
          this.desiredConc.unit
        );
        this.recalculateField();
      }
    },
    desiredvolume() {
      console.log("watch calling desiredvolume", this.desiredvolume);
      if (this.desiredvolume < 0) {
        this.desiredvolume = 0;
      }
      this.recalculateField();
    },
    desiredvolumefactor() {
      console.log(
        "watch calling desiredvolumefactor",
        this.desiredvolumefactor
      );
      this.recalculateField();
    },
    selectedConc() {
      if (this.selectedConc === "") {
        this.selectConcFactors = this.concFactorLists;
      } else {
        console.log("Selected concentration type is ", this.selectedConc);
        this.selectConcFactors = this.concFactorLists.filter(
          (concFactor) => concFactor.type === this.selectedConc
        );
      }
      this.desiredConc = this.selectConcFactors[0];
      this.stockConc = this.selectConcFactors[0];
    }
  }
};
</script>

<style scoped>
.calulatorface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
</style>
