<template>
  <div v-if="statusObj.errorMessage || statusObj.message">
    <div class="modal fade" id="olabStatusObjDialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="statusObj.errorMessage" class="modal-title">Error</h5>
            <h5 v-else class="modal-title">Message</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              v-if="statusObj.errorMessage"
              class="col-12 alert alert-danger text-center px-3"
            >
              {{ statusObj.errorMessage }}
            </div>

            <div
              v-else-if="statusObj.message"
              class="col-12 alert alert-info text-center fade show px-3"
            >
              <span>{{ statusObj.message }}</span>
              <span
                v-if="statusObj.progress"
                class="mx-2 spinner-grow"
                role="status"
              >
                <span class="sr-only">Status information</span>
              </span>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="closeError"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "OlabStatusObjAlerts",
  data() {
    return {};
  },
  props: ["statusObj", "showStatusObjDialog"],
  emits: ["emitStatusMessage", "emitErrorMessage"],
  methods: {
    doEmitStatusMessage(statusMsg, statusProgress) {
      this.$emit("emitStatusMessage", statusMsg, statusProgress);
    },
    doEmitErrorMessage(errMsg) {
      this.$emit("emitErrorMessage", errMsg);
    },
    closeStatus() {
      // console.log("Close Status Alert ...");
      this.doEmitStatusMessage(null, false);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.doEmitErrorMessage(null);
    }
  },
  watch: {
    showStatusObjDialog() {
      // console.log("showStatusObjDialog =", this.showStatusObjDialog);
      if (this.showStatusObjDialog) {
        $("#olabStatusObjDialog").modal("show");
      } else {
        $("#olabStatusObjDialog").modal("hide");
      }
    }
  }
};
</script>
