<template>
  <div>
    <div v-if="user" id="biocalculator">
      <article id="page-multicolumn" class="page-section text-center py-5">
        <header class="page-section-header container">
          <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
              <h2 class="page-section-title">Bio-Calculators</h2>
              <p class="page-section-text">
                A collection of calculators made easy for Life Science
                researchers.
              </p>
            </div>
          </div>
        </header>

        <section class="layout-multicolumn container">
          <div class="row justify-content-center family-sans text-uppercase">
            <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
              <div class="svg-body">
                <a href="/molarity">
                  <OlabMolaritySVGGen
                    name="Molarity"
                    textPosX="45"
                    textPosY="215"
                  />
                </a>
              </div>
            </section>
            <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
              <div class="svg-body">
                <a href="/dilution">
                  <OlabDilutionSVGGen
                    name="Dilution"
                    textPosX="50"
                    textPosY="215"
                  />
                </a>
              </div>
            </section>
            <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
              <div class="svg-body">
                <a href="/mediumbuffer">
                  <OlabMediumSVGGen
                    name="Medium"
                    textPosX="58"
                    textPosY="215"
                  />
                </a>
              </div>
            </section>
            <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
              <div class="svg-body">
                <a href="/quick">
                  <OlabMediumSVGGen name="Quick" textPosX="75" textPosY="215" />
                </a>
              </div>
            </section>
            <!-- TODO: Temporarily remove microbial growth calc. till icon is ready -->
            <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
              <div class="svg-body">
                <a href="/microbialgrowth">
                  <OlabMicrobialGrowthSVGGen
                    name="Growth"
                    textPosX="50"
                    textPosY="215"
                  />
                </a>
              </div>
            </section>
          </div>
        </section>
      </article>
    </div>
    <div v-else class="mt-5">
      <div class="text-secondary text-center">
        <div class="h2">Inactivity Time Out</div>
        <router-link
          class="btn btn-outline-primary mt-2"
          to="/login"
          v-if="!user"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import OlabMolaritySVGGen from "@/components/OlabMolaritySVGGen";
import OlabDilutionSVGGen from "@/components/OlabDilutionSVGGen";
import OlabMediumSVGGen from "@/components/OlabMediumSVGGen";
import OlabMicrobialGrowthSVGGen from "@/components/OlabMicrobialGrowthSVGGen";
export default {
  name: "BioCalculators",
  components: {
    OlabMolaritySVGGen,
    OlabDilutionSVGGen,
    OlabMediumSVGGen,
    OlabMicrobialGrowthSVGGen
  },
  data() {
    return {};
  },
  props: ["user", "dbInfo", "statusObj"]
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.svg-body {
  place-items: center;
  padding: 20%;
}
</style>
