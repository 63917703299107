<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        v-for="sid in items"
        :key="sid.olab_id"
        class="list-group-item d-flex mb-1 align-items-center"
      >
        <div class="container">
          <div class="row">
            <div
              class="col-12 mt-2 align-self-center text-center text-truncate"
            >
              <a
                class="text-info"
                data-toggle="modal"
                href="#viewItem"
                v-on:click="registerItem(sid)"
                data-backdrop="static"
                data-keyboard="false"
                >{{ sid.question ? sid.question : "NO QUESTION" }}</a
              >
              <div class="h6">
                <small>{{ sid.answer }}</small>
              </div>
              <div class="small mt-2">
                <span>{{ sid.olab_id }}</span>
                <span class="ml-2">{{ sid.module }}</span>
              </div>
            </div>

            <div v-if="selectedItem != null">
              <!-- Modal: ViewItem -->
              <FAQViewForm :dbInfo="dbInfo" :item="selectedItem" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FAQViewForm from "@/components/FAQViewForm";

export default {
  name: "FAQList",
  data() {
    return {
      selectedItem: null
    };
  },
  props: ["user", "dbInfo", "item", "items", "statusObj"],
  components: {
    FAQViewForm
  },
  methods: {
    async registerItem(item) {
      this.selectedItem = item;
      console.log("registerItem: item = ", item);
    }
  }
};
</script>
