<template>
  <div>
    <div class="card-body">
      <div class="form-row justify-content-center">
        <div class="col col-md-8 mb-3">
          <VueMultiselect
            v-model="selUser"
            :options="users"
            :loading="isLoadingUsers"
            :internal-search="false"
            :multiple="false"
            :close-on-select="true"
            :options-limit="10"
            :custom-label="userInfo"
            label="olab_id"
            track-by="olab_id"
            placeholder="Type to search ..."
            @search-change="getUsers"
          />
        </div>
        <!-- <span>selected = {{ selUser }}</span> -->
      </div>
      <div v-if="userStats && userStats.user" class="mt-3">
        <div class="form-group mb-5">
          <div class="form-row">
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label"> From Date </label>
              <input class="form-control" type="date" v-model="userFromDate" />
            </div>
            <div class="col-md-6 mt-3">
              <label class="h5 form-control-label">To Date</label>
              <input class="form-control" type="date" v-model="userToDate" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">OLAB ID:</span>
            <span class="ml-1">{{ userStats.user.olab_id }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">Total Memory:</span>
            <span class="ml-1">{{
              formatMemorySize(userStats.total_memory)
            }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <span class="mr-1">Name:</span>
            <span class="ml-1">{{ userStats.user.name }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <span class="mr-1">Email:</span>
            <span class="ml-1">{{ userStats.user.email }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="mr-1">Role:</span>
            <span class="ml-1">{{ userStats.user.role }}</span>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Collection</th>
              <th scope="col">Memory</th>
              <th scope="col">Count</th>
              <!-- <th scope="col">Deleted</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(collection, index) in userStats.collections"
              :key="index"
            >
              <th scope="row">
                {{ collection.name }}
              </th>
              <td>{{ formatMemorySize(collection.memory) }}</td>
              <td>{{ collection.count }}</td>
              <!-- <td>{{ collection.deleted }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabUtils } from "@/olab/olabutils.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";

export default {
  name: "OlabUserTab",
  data() {
    return {
      selUser: null,
      users: [],
      isLoadingUsers: false,
      userStats: null,
      userFromDate: null,
      userToDate: null
    };
  },
  components: {
    VueMultiselect
  },
  props: ["user", "dbInfo", "statusObj"],
  async mounted() {
    await this.getUsers("");
  },
  methods: {
    formatMemorySize(size) {
      return OlabUtils.formatMemorySize(size);
    },
    userInfo({ olab_id, name }) {
      return `${name} [${olab_id}]`;
    },
    async getUsers(query) {
      // console.log("getUsers: query = ", query);
      this.isLoadingUsers = true;
      const searchBody = OlabSearch.createSearchBody("user", query, "merge");
      this.users = await OlabAxios.getUsers(searchBody, this.statusObj);
      this.isLoadingUsers = false;
    },
    async loadUserStats() {
      // console.log("loadUserStats: userStats =", this.userStats);
      this.userStats = await OlabAxios.getUserStats(
        this.selUser.olab_id,
        this.userFromDate,
        this.userToDate,
        this.statusObj
      );
    }
  },
  watch: {
    selUser() {
      // console.log("selectedUserID =", this.selectedUserID);
      if (this.selUser) {
        this.loadUserStats();
      } else {
        this.userStats = null;
      }
    },
    userFromDate() {
      // console.log("userFromDate =", this.userFromDate);
      if (this.userStats) {
        this.loadUserStats();
      }
    },
    userToDate() {
      // console.log("userToDate =", this.userToDate);
      if (this.userStats) {
        this.loadUserStats();
      }
    }
  }
};
</script>
