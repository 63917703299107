<template>
  <div>
    <div v-if="user" id="olabchart">
      <nav class="navbar sticky-top navbar-light bg-light">
        <div class="input-group my-2">
          <!-- Search UI Group -->
          <div class="row mr-0">
            <div class="add-btn ml-1">
              <button
                type="button"
                class="btn btn-info"
                v-on:click="prepAddItem(item)"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
          </div>
          <input
            id="searchTerm"
            placeholder="Search"
            type="text"
            class="form-control ml-1"
            aria-label="Search"
            v-model="searchTerm"
          />

          <div class="search-group-btn">
            <button
              type="button"
              class="ml-1 btn btn-info dropdown-toggle"
              data-flip="false"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="search" />
              <span class="small mt-2 ml-1">{{ searchFilterKey }}</span>
              <span class="caret"></span>
            </button>

            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="#"
                id="merge"
                v-on:click.prevent="requestKeyChange('merge')"
              >
                Merge
                <span v-if="filterKey === 'merge'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="olab_id"
                v-on:click.prevent="requestKeyChange('olab_id')"
              >
                OLAB ID
                <span v-if="filterKey === 'olab_id'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="name"
                v-on:click.prevent="requestKeyChange('name')"
              >
                Name
                <span v-if="filterKey === 'name'">&#x2713;</span>
              </a>

              <a
                class="dropdown-item"
                href="#"
                id="creator"
                v-on:click.prevent="requestKeyChange('creator')"
              >
                Creator
                <span v-if="filterKey === 'creator'">&#x2713;</span>
              </a>
            </div>
            <!-- .dropdown-menu -->
          </div>
          <!-- search-group-btn -->
        </div>
        <!-- input-group my-2 -->

        <div class="navbar-brand text-truncate">
          <!-- Placeholder for user profile-->
          <button
            type="button"
            class="btn btn-outline-danger ml-1"
            data-toggle="modal"
            data-target="#userProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="id-badge" />
          </button>
          <button
            type="button"
            class="btn btn-outline-success ml-1"
            data-toggle="modal"
            data-target="#infoProfile"
            data-backdrop="static"
            data-keyboard="false"
          >
            <font-awesome-icon icon="info" />
          </button>
          <span
            v-if="statusObj.searching"
            class="mx-2 spinner-border spinner-border-sm text-info"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </span>
          <span class="h4 text-success text-capitalize ml-2"
            >{{ selectedTypeName }} Control System</span
          >
        </div>
        <!-- navbar-brand text-truncate -->
      </nav>
      <div>
        <OlabStatusObjAlerts
          :statusObj="statusObj"
          @emitStatusMessage="emitStatusMessage"
          @emitErrorMessage="emitErrorMessage"
        />
      </div>
      <!-- sticky-top -->

      <!-- userProfile -->
      <OlabUserProfile :user="user" />

      <!-- infoProfile -->
      <OlabInfoProfile :user="user" :selectedTypeName="selectedTypeName" />

      <!-- addNewItem -->
      <div v-if="addEnabled">
        <ChartAdd
          :user="user"
          :dbInfo="dbInfo"
          :selectedType="selectedType"
          :item="item"
          :items="selected.items"
          :statusObj="statusObj"
          @resetAddEnabled="resetAddEnabled"
        />
      </div>
      <div v-else>
        <!-- <p v-if="selected">{{ selected.items }}</p> -->
        <!-- TODO: ChartList -->
        <div>
          <ChartList
            v-if="user"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :chart="item"
            :charts="selected.items"
            :statusObj="statusObj"
          />
        </div>

        <div class="my-5">
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item">
                <button
                  type="button"
                  v-bind:disabled="selected.prevIsDisabled == true"
                  class="btn btn-primary"
                  v-on:click="handlePrev()"
                >
                  &lt;
                </button>
              </li>
              <li class="mx-3">{{ selected.pageNumber }}</li>
              <li class="page-item">
                <button
                  type="button"
                  v-bind:disabled="selected.nextIsDisabled == true"
                  class="btn btn-primary"
                  v-on:click="handleNext()"
                >
                  &gt;
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div v-else class="mt-5">
      <div class="text-secondary text-center">
        <div class="h2">Inactivity Time Out</div>
        <router-link
          class="btn btn-outline-primary mt-2"
          to="/login"
          v-if="!user"
          >Log in</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabUserProfile from "@/components/OlabUserProfile";
import OlabInfoProfile from "@/components/OlabInfoProfile";
import OlabStatusObjAlerts from "@/components/OlabStatusObjAlerts.vue";
import ChartList from "@/components/ChartList.vue";
import ChartAdd from "@/components/charts/ChartAdd.vue";
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabUtils } from "@/olab/olabutils.js";

export default {
  name: "OlabChart",
  data() {
    return {
      title: "Chart",
      searchTerm: "",
      filterKey: "merge",
      filterDir: "asc",
      selectedType: "chart",
      lastSelectedType: null,
      selectedTypeName: "Chart",
      selectedItem: null,
      item: new OlabItem(),
      control: {
        title: "",
        x_axes: [],
        y_axes: [],
        source: "prod_data",
        target: {
          key: null,
          operator: "==",
          values: []
        },
        type: "line",
        filters: [],
        groups: [],
        colors: [],
        options: []
      },
      addEnabled: false,
      invalidFB: OlabItem.getInvalidFeedbacks(),
      includeDeleted: true,
      selected: {
        items: null,
        sortBy: "olab_id",
        pageNumber: 1,
        nextIsDisabled: true,
        prevIsDisabled: true
      }
    };
  },
  components: {
    FontAwesomeIcon,
    OlabUserProfile,
    OlabInfoProfile,
    ChartAdd,
    ChartList,
    OlabStatusObjAlerts
  },
  props: ["user", "dbInfo", "statusObj"],
  emits: ["setStatusMessage", "setErrorMessage"],
  async mounted() {
    // console.log("Experiment mounted ...");
    // Reset status and error
    this.emitStatusMessage(null, false);
    this.emitErrorMessage(null);
    // Fetch Experiments
    try {
      const searchBody = OlabSearch.createSearchBody(
        this.selectedType,
        "",
        "olab_id"
      );
      const items = await OlabAxios.getCharts(searchBody, this.statusObj);
      if (items.length >= OlabAxios.LIMIT_PER_PAGE) {
        this.selected.sortBy = "olab_id";
        this.selected.nextIsDisabled = false;
      }
      this.selected.items = items;
    } catch (err) {
      console.log(err);
      this.emitErrorMessage(OlabUtils.getErrorMessage(err));
    }
  },
  computed: {
    searchFilterKey() {
      let key = "Merge";
      switch (this.filterKey) {
        case "merge":
          key = "Merge";
          break;
        case "olab_id":
          key = "OLAB ID";
          break;
        case "name":
          key = "Name";
          break;
        case "creator":
          key = "Creator";
          break;
      }
      return key;
    }
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.$emit("setStatusMessage", statusMsg, statusProgress);
    },
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    handlePrev() {
      let sType = this.selectedType;
      // console.log("handlePrev ... sType = " + sType);
      OlabSearch.doPrev(
        sType,
        this.searchTerm,
        this.includeDeleted,
        this.selected,
        this.statusObj
      );
    },
    handleNext() {
      let sType = this.selectedType;
      // console.log("handleNext ... sType = " + sType);
      OlabSearch.doNext(
        sType,
        this.searchTerm,
        this.includeDeleted,
        this.selected,
        this.statusObj
      );
    },
    requestKeyChange(value) {
      this.filterKey = value;
      this.doSearchTerm(this.searchTerm, this.selectedType);
    },
    prepAddItem(item) {
      // Scoll to top for better positioning
      window.scrollTo(0, 0);
      // console.log("prepAddItem");
      item.initItem();
      // Do item preconfiguration here if needed
      item.control = this.control;
      // Register item is created by user
      item.creator = this.user.email;
      // Trigger ChartAdd module
      this.addEnabled = true;
    },
    resetAddEnabled() {
      this.addEnabled = false;
    },
    doSearchTerm(sTerm, sType) {
      // console.log(
      //   "doSearchTerm - sTerm = " +
      //     sTerm +
      //     ", sType = " +
      //     sType +
      //     ", filterKey = " +
      //     this.filterKey
      // );

      OlabSearch.doSearchTerm(
        sTerm,
        sType,
        this.includeDeleted,
        this.filterKey,
        this.selected,
        this.statusObj
      );
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    isGreaterThanZero(state) {
      return OlabSanity.isGreaterThanZero(state);
    }
  },
  watch: {
    searchTerm() {
      // console.log("Watch - searchTerm = " + this.searchTerm);
      this.doSearchTerm(this.searchTerm, this.selectedType);
    }
  }
};
</script>

<style scoped></style>
