<template>
  <div v-if="user">
    <!-- <button
      type="button"
      class="btn btn-outline-success ml-1"
      data-toggle="modal"
      data-target="#infoProfile"
      data-backdrop="static"
      data-keyboard="false"
    >
      <font-awesome-icon icon="info" />
    </button> -->
    <div class="modal fade" id="infoProfile">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Information</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .modal-header -->
          <div class="modal-body">
            <div v-if="selectedTypeName" class="pt-2">
              <span>Name:</span>
              <span class="ml-1">{{ selectedTypeName }}</span>
            </div>

            <div class="py-1">
              <div class="card">
                <div class="card-header text-dark">Description</div>
                <div class="card-body">
                  <div class="py-1 text-dark">
                    This Contextual Bioinformatics System is powered by OLAB
                    Bioscience Informatics
                  </div>
                </div>
                <!-- .card-body -->
              </div>
              <!-- .card -->
            </div>
          </div>
          <!-- .modal-body -->

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          <!-- .modal-footer -->
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog .modal-lg-->
    </div>
    <!-- .modal fade -- End of infoProfile -->
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "OlabInfoProfile",
  props: ["user", "selectedTypeName"],
  components: {
    // FontAwesomeIcon
  }
};
</script>
