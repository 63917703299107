<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="200">
      <g transform="translate(250,120)">
        <path
          fill="#eeeeee"
          transform="scale(0.6)"
          d="M224 256c70.7 0
        128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6
        32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0
        348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5
        48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
        />
      </g>
      <text
        :x="namePosX"
        :y="namePosY"
        fill="#f80"
        style="
          font-size: 80px;
          font-weight: normal;
          text-align: start;
          text-anchor: start;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
          font-family: Arial;
        "
        id="olab-text"
        xml:space="preserve"
      >
        {{ computedName }}
      </text>
      <text
        :x="computedRolePosX"
        :y="rolePosY"
        fill="#f80"
        style="
          font-size: 80px;
          font-weight: normal;
          text-align: start;
          text-anchor: start;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
          font-family: Arial;
        "
        id="olab-text"
        xml:space="preserve"
      >
        {{ computedRole }}
      </text>
    </svg>
  </div>
</template>

<script>
import { OlabUtils } from "../olab/olabutils.js";

export default {
  name: "OlabUserSVGGen",
  data() {
    return {
      title: "OlabUserSVGGen",
      namePosX: 260,
      namePosY: 300,
      rolePosX: 200,
      rolePosY: 400
    };
  },
  props: ["project", "usr"],
  computed: {
    computedRolePosX() {
      let xPos = this.rolePosX;
      if (this.project) {
        switch (this.computedRole) {
          case "owner":
            xPos += 70;
            break;
          case "member":
            xPos += 35;
            break;
          default:
            xPos += 40;
        }
      } else {
        switch (this.usr.role) {
          case "admin":
            xPos += 75;
            break;
          case "lead":
            xPos += 85;
            break;
          case "user":
            xPos += 95;
            break;
          default:
        }
      }
      return xPos;
    },
    computedRole() {
      if (this.project) {
        return OlabUtils.getProjectRole(this.usr, this.project);
      }
      return this.usr.role;
    },
    computedName() {
      return this.project ? "Project" : "System";
    }
  }
};
</script>
