<template>
  <div class="calulatorface">
    <div class="container text-left mt-3">
      <h1>Molarity Calculator</h1>
      <p>
        This bidirectional molarity calculator provides an easy-to-use approach
        to do molarity computation.
      </p>
      <p>
        You fill in any 3 of the 4 components and we will compute the remaining
        component.
      </p>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="statusObj.errorMessage"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ statusObj.errorMessage }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container text-left">
      <div class="row justify-content-center">
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Formula&nbsp;weight</legend>
            <input
              name="formula_weight"
              type="number"
              class="form-control"
              placeholder="Formula Weight"
              v-bind:disabled="calculateField == 'calculateFormulaWeight'"
              v-model="formulaWeight"
            />
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6">
                <div class="form-control text-light bg-secondary">g/mol</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Desired&nbsp;Volume</legend>
            <input
              name="Desired_final_volume"
              type="number"
              class="form-control"
              placeholder="Desired Volume"
              v-bind:disabled="calculateField == 'calculateDesiredVolume'"
              v-model="desiredVolume"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="desiredVolumeFactor"
                >
                  <option value="1">liters (L)</option>
                  <option value="0.001">mililiters (mL)</option>
                  <option value="0.000001">microliters (μl)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Desired&nbsp;Concentration</legend>
            <input
              name="Desired_concentration"
              type="number"
              class="form-control"
              placeholder="Desired Concentration"
              v-bind:disabled="
                calculateField == 'calculateDesiredConcentration'
              "
              v-model="desiredConcentration"
            />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="desiredConcentrationFactor"
                >
                  <option value="1">molar (M)</option>
                  <option value="0.001">milimolar (mM)</option>
                  <option value="0.000001">micromolar (μM)</option>
                  <option value="0.000000001">nanomolar (nM)</option>
                  <option value="0.000000000001">picomolar (pM)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 col-lg-3 my-2">
          <div class="form-group">
            <legend class="text-center">Mass</legend>
            <input
              name="mass"
              type="number"
              class="form-control"
              placeholder="Mass"
              v-bind:disabled="calculateField == 'calculateMass'"
              v-model="mass"
            />
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6">
                <select
                  class="form-control text-light bg-secondary"
                  v-model="massFactor"
                >
                  <option value="1">g</option>
                  <option value="0.001">mg</option>
                  <option value="0.000001">μg</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center my-4">
          <button
            class="btn btn-brand mr-2"
            v-on:click="calculate"
            v-bind:disabled="show != 3"
          >
            Calculate
          </button>
          <button class="btn btn-brand mr-2" v-on:click="reset">Reset</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MolarityCalculator",
  data() {
    return {
      intVal: "",
      calculateField: "",
      formulaWeight: "",
      desiredVolume: "",
      desiredConcentration: "",
      desiredVolumeFactor: 1,
      desiredConcentrationFactor: 1,
      massFactor: 1,
      mass: ""
    };
  },
  props: ["statusObj"],
  emits: ["setErrorMessage"],
  methods: {
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.$emit("setErrorMessage", null);
    },
    calculate() {
      let formulaWeight1 = this.formulaWeight == "" ? 0 : this.formulaWeight;
      let desiredVolume1 = this.desiredVolume == "" ? 0 : this.desiredVolume;
      let desiredConcentration1 =
        this.desiredConcentration == "" ? 0 : this.desiredConcentration;
      let mass1 = this.mass == "" ? 0 : this.mass;

      if (mass1 == 0) {
        this.calculateField = "calculateMass";
        this.calculateMass();
      } else if (desiredVolume1 == 0) {
        this.calculateField = "calculateDesiredVolume";
        this.calculateDesiredVolume();
      } else if (desiredConcentration1 == 0) {
        this.calculateField = "calculateDesiredConcentration";
        this.calculateDesiredConcentration();
      } else if (formulaWeight1 == 0) {
        this.calculateField = "calculateFormulaWeight";
        this.calculateFormulaWeight();
      }
    },
    calculateMass() {
      this.emitErrorMessage("");
      this.mass =
        (this.formulaWeight *
          this.desiredVolume *
          this.desiredVolumeFactor *
          this.desiredConcentration *
          this.desiredConcentrationFactor) /
        this.massFactor;
    },
    calculateDesiredVolume() {
      if (this.desiredConcentration * this.formulaWeight > 0) {
        this.emitErrorMessage("");
        this.desiredVolume =
          (this.mass * this.massFactor) /
          (this.formulaWeight *
            this.desiredConcentration *
            this.desiredConcentrationFactor *
            this.desiredVolumeFactor);
      } else {
        this.emitErrorMessage(
          "Formula Weight and Desired Concentration cannot be zero"
        );
      }
    },
    calculateDesiredConcentration() {
      if (this.formulaWeight * this.desiredVolume > 0) {
        this.desiredConcentration =
          (this.mass * this.massFactor) /
          (this.formulaWeight *
            this.desiredVolume *
            this.desiredVolumeFactor *
            this.desiredConcentrationFactor);
      } else {
        this.emitErrorMessage(
          "Formula Weight and Desired Volume cannot be zero"
        );
      }
    },
    calculateFormulaWeight() {
      if (this.desiredVolume * this.desiredConcentration > 0) {
        this.formulaWeight =
          (this.mass * this.massFactor) /
          (this.desiredVolume *
            this.desiredVolumeFactor *
            this.desiredConcentration *
            this.desiredConcentrationFactor);
      } else {
        this.emitErrorMessage(
          "Desired Volume and Desired Concentration cannot be zero"
        );
      }
    },
    recalculateField() {
      if (this.calculateField == "") {
        return;
      } else if (this.calculateField == "calculateMass") {
        this.calculateMass();
      } else if (this.calculateField == "calculateDesiredVolume") {
        this.calculateDesiredVolume();
      } else if (this.calculateField == "calculateDesiredConcentration") {
        this.calculateDesiredConcentration();
      } else if (this.calculateField == "calculateFormulaWeight") {
        this.calculateFormulaWeight();
      }
      console.log("recalculateField run");
      return {};
    },
    reset() {
      this.formulaWeight = "";
      this.mass = "";
      this.massFactor = 1;
      this.desiredVolume = "";
      this.desiredVolumeFactor = 1;
      this.desiredConcentration = "";
      this.desiredConcentrationFactor = 1;
      this.calculateField = "";
    }
  },
  computed: {
    show() {
      let show =
        (this.formulaWeight == "" || this.formulaWeight <= 0 ? 0 : 1) +
        (this.desiredVolume == "" || this.desiredVolume <= 0 ? 0 : 1) +
        (this.desiredConcentration == "" || this.desiredConcentration <= 0
          ? 0
          : 1) +
        (this.mass == "" || this.mass <= 0 ? 0 : 1);
      return show;
    }
  },
  watch: {
    formulaWeight() {
      console.log("watch calling formulaWeight", this.formulaWeight);
      if (this.formulaWeight < 0) {
        this.formulaWeight = 0;
      }
      this.recalculateField();
    },
    desiredVolume() {
      console.log("watch calling desiredVolume", this.desiredVolume);
      if (this.desiredVolume < 0) {
        this.desiredVolume = 0;
      }
      this.recalculateField();
    },
    desiredVolumeFactor() {
      console.log(
        "watch calling desiredVolumeFactor",
        this.desiredVolumeFactor
      );
      this.recalculateField();
    },
    desiredConcentration() {
      console.log(
        "watch calling desiredConcentration",
        this.desiredConcentration
      );
      if (this.desiredConcentration < 0) {
        this.desiredConcentration = 0;
      }
      this.recalculateField();
    },
    desiredConcentrationFactor() {
      console.log(
        "watch calling desiredConcentrationFactor",
        this.desiredConcentrationFactor
      );
      this.recalculateField();
    },
    mass() {
      console.log("watch calling mass", this.mass);
      if (this.mass < 0) {
        this.mass = 0;
      }
      this.recalculateField();
    },
    massFactor() {
      console.log("watch calling massFactor", this.massFactor);
      this.recalculateField();
    }
  }
};
</script>

<style scoped>
.calulatorface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
</style>
