<template>
  <div v-if="selectedType === 'experiment' && item">
    <div class="form-group">
      <div
        v-if="localStatusObj.progress"
        class="spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
        {{ localStatusObj.errorMessage }}
      </span>
      <span v-if="localStatusObj.message" class="mx-1 text-info">
        {{ localStatusObj.message }}
      </span>
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div v-if="branch" class="form-group">
      <span class="mr-1">Branch:</span>
      <span class="ml-1">{{ branch.olab_id }} - {{ branch.name }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Number Of Stages:</span>
      <span class="ml-1">{{ item.num_culture_stages }}</span>
    </div>
    <div class="form-group form-row">
      <div class="col-md-5">
        <span class="mr-1">Start Date:</span>
        <span class="ml-1">{{ item.start_date }}</span>
      </div>
      <div class="col-md-5">
        <span class="mr-1">End Date:</span>
        <span class="ml-1">{{ item.end_date }}</span>
      </div>
    </div>
    <div v-if="controlStrain" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">Control Strain:</span>
      </label>
      <table class="table">
        <thead>
          <th scope="col">OLAB ID</th>
          <th scope="col">Name</th>
          <th scope="col">GenoType</th>
        </thead>
        <tbody>
          <tr>
            <td>{{ controlStrain.olab_id }}</td>
            <td>{{ controlStrain.name }}</td>
            <td>{{ controlStrain.strain_genotype }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="testStrains && testStrains.length > 0" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">Test Strains:</span>
      </label>
      <table class="table">
        <thead>
          <th scope="col">OLAB ID</th>
          <th scope="col">Name</th>
          <th scope="col">GenoType</th>
        </thead>
        <tbody>
          <tr v-for="tstStrn in testStrains" v-bind:key="tstStrn.olab_id">
            <td>{{ tstStrn.olab_id }}</td>
            <td>{{ tstStrn.name }}</td>
            <td>{{ tstStrn.strain_genotype }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="relatedExprts && relatedExprts.length > 0" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">Related Experiments:</span>
      </label>
      <table class="table">
        <thead>
          <th scope="col">OLAB ID</th>
          <th scope="col">Name</th>
          <th scope="col">Creator</th>
        </thead>
        <tbody>
          <tr v-for="relExprt in relatedExprts" v-bind:key="relExprt.olab_id">
            <td>{{ relExprt.olab_id }}</td>
            <td>{{ relExprt.name }}</td>
            <td>{{ relExprt.creator }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="item" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">Hypotheses:</span>
      </label>
      <table class="table">
        <thead>
          <th scope="col">Statement</th>
          <th scope="col">Cause</th>
          <th scope="col">Effect</th>
          <th scope="col">Answered</th>
        </thead>
        <tbody>
          <tr v-for="hyp in item.hypotheses" v-bind:key="hyp">
            <td v-if="hyp.statement">{{ hyp.statement }}</td>
            <td v-if="hyp.statement">{{ hyp.cause }}</td>
            <td v-if="hyp.statement">{{ hyp.effect }}</td>
            <td v-if="hyp.statement">{{ hyp.answered }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="verifier" class="form-group">
      <span class="mr-1">Verifier:</span>
      <span class="ml-1"
        >{{ verifier.olab_id }} - {{ verifier.name }},
        {{ verifier.email }}</span
      >
    </div>
    <div class="form-group form-row">
      <div class="col-md-5">
        <span class="mr-1">Verifier signature:</span>
        <span class="ml-1">{{ item.verifier_signature }}</span>
      </div>
      <div class="col-md-5">
        <span class="mr-1">Verified Date:</span>
        <span class="ml-1">{{ item.verified_date }}</span>
      </div>
    </div>
    <div class="form-group">
      <span class="mr-1">Description:</span>
      <span class="ml-1">{{ item.desc }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Note:</span>
      <span class="ml-1">{{ item.note }}</span>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";

export default {
  name: "ExperimentView",
  data() {
    return {
      branch: null,
      controlStrain: null,
      testStrains: [],
      relatedExprts: [],
      verifier: null,
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("** dbInfo =", this.dbInfo);
    // console.log("** selectedType =", this.selectedType);
    // Loading is needed for the first item
    await this.loadExperimentData();
  },
  methods: {
    async loadExperimentData() {
      if (this.item && this.item.olab_type === "experiment") {
        const itemData = await OlabAxios.getExperimentData(
          this.item,
          this.localStatusObj
        );
        // console.log("itemData =", itemData);
        this.branch = itemData.branch;
        this.controlStrain = itemData.control_strain;
        this.testStrains = itemData.test_strains;
        this.relatedExprts = itemData.related_exprts;
        this.verifier = itemData.verifier;
      }
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadExperimentData();
    }
  }
};
</script>
