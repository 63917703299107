<template>
  <div v-if="item && selectedType === 'strain'">
    <div class="form-group">
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Project ID:</span>
      <span class="ml-1">{{ formatProject }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Genotype:</span>
      <span class="ml-1">{{ item.strain_genotype }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Description:</span>
      <span class="ml-1">{{ item.desc }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Note:</span>
      <span class="ml-1">{{ item.note }}</span>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";

export default {
  name: "StrainView",
  data() {
    return {
      project: null,
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("Mounted() item =", this.item);
    await this.loadProjectData();
  },
  methods: {
    async loadProjectData() {
      if (this.item && this.item.olab_type === "strain") {
        this.project = await OlabAxios.getProject(
          this.item.project_id,
          this.localStatusObj
        );
      }
    }
  },
  computed: {
    formatProject() {
      if (this.project) {
        return `${this.project.olab_id} - ${this.project.name}`;
      }
      return this.item.project_id;
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadProjectData();
    }
  }
};
</script>
