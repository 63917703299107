<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'manufacturer'">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="inp-edit-name">Company</label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.name)"
          v-model="item.name"
          id="inp-edit-name"
          placeholder="Company Name"
        />
      </div>
      <div class="form-group">
        <label for="inp-edit-address">Address</label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.addr1)"
          v-model="item.addr1"
          id="inp-edit-address"
          placeholder="1234 Main St"
        />
      </div>
      <div class="form-group">
        <label for="inp-edit-address2">Address 2</label>
        <input
          type="text"
          class="form-control"
          v-model="item.addr2"
          id="inp-edit-address2"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inp-edit-city">City</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="validState(item.city)"
            v-model="item.city"
            id="inp-edit-city"
          />
        </div>
        <div class="form-group col-md-4">
          <label for="inp-edit-state">State</label>
          <select
            id="inp-edit-state"
            class="form-control"
            v-bind:class="validState(item.state)"
            v-model="item.state"
          >
            <option disabled>Choose...</option>
            <option value="NA">Not in US</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="inp-edit-zip">Zip</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="validState(item.zipcode)"
            v-model="item.zipcode"
            id="inp-edit-zip"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="inp-edit-country">Country</label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.country)"
          v-model="item.country"
          id="inp-edit-country"
        />
      </div>
      <hr />
      <div class="form-group">
        <label for="inp-edit-contact-name">Contact Person</label>
        <input
          type="text"
          class="form-control"
          v-model="item.contact_name"
          id="inp-edit-contact-name"
          placeholder="Name"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inp-edit-email">Email</label>
          <input
            type="text"
            class="form-control"
            v-model="item.email"
            id="inp-edit-email"
            placeholder="name@example.com"
          />
        </div>
        <div class="form-group col-md-6">
          <label for="inp-edit-phone">Phone</label>
          <input
            type="tel-country-code"
            class="form-control"
            v-model="item.phone"
            id="inp-edit-phone"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="inp-edit-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-edit-note"
        ></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";

export default {
  name: "ManufacturerEdit",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: ["user", "dbInfo", "selectedType", "item"],
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    validEmailState(em) {
      // console.log("validEmailState: email = " + em);
      return OlabSanity.validateEmail(em) ? "is-valid" : "is-invalid";
    }
  }
};
</script>
