<template>
  <div class="modal fade" id="selectEditItem">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            OLAB ID:
            <span class="text-dark">{{ item.olab_id }}</span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- .modal-header -->
        <div class="modal-body">
          <BioreactorEdit
            v-if="selectedType === 'bioreactor'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
          <BranchEdit
            v-if="selectedType === 'branch'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <CultureEdit
            v-if="selectedType === 'culture'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ExperimentEdit
            v-if="selectedType === 'experiment'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <FlaskEdit
            v-if="selectedType === 'flask'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
          <IncubatorEdit
            v-if="selectedType === 'incubator'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
          <ManufacturerEdit
            v-if="selectedType === 'manufacturer'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <MediaEdit
            v-if="selectedType === 'media'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ChemicalEdit
            v-if="selectedType === 'chemical'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
          <RecipeEdit
            v-if="selectedType === 'recipe'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
          <UserEdit
            v-if="selectedType === 'user'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <PlateEdit
            v-if="selectedType === 'plate'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
          <ProjectEdit
            v-if="selectedType === 'project'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <StrainEdit
            v-if="selectedType === 'strain'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <TubeEdit
            v-if="selectedType === 'tube'"
            :user="user"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
            :statusObj="statusObj"
          />
        </div>
        <div class="container">
          <div class="form-row justify-content-between font-italic">
            <div class="col-md-6">Updated at: {{ formatTimeStamp }}</div>
            <div class="col-md-6">Created by: {{ item.creator }}</div>
          </div>
        </div>
        <!-- .modal-body -->
        <div class="modal-footer">
          <button
            type="button"
            v-bind:disabled="itemSaveIsDisabled == true"
            class="btn btn-primary"
            v-on:click="handleSaveItem()"
          >
            <span
              v-if="statusObj.progress"
              class="spinner-border spinner-border-sm text-light"
            ></span>
            Save
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancel
          </button>
        </div>
        <!-- .modal-footer -->
      </div>
      <!-- .modal-content -->
    </div>
    <!-- .modal-dialog .modal-lg-->
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios.js";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import BioreactorEdit from "@/components/forms/BioreactorEdit.vue";
import BranchEdit from "@/components/forms/BranchEdit.vue";
import ExperimentEdit from "@/components/forms/ExperimentEdit.vue";
import FlaskEdit from "@/components/forms/FlaskEdit.vue";
import IncubatorEdit from "@/components/forms/IncubatorEdit.vue";
import ManufacturerEdit from "@/components/forms/ManufacturerEdit.vue";
import MediaEdit from "@/components/forms/MediaEdit.vue";
import ChemicalEdit from "@/components/forms/ChemicalEdit.vue";
import RecipeEdit from "@/components/forms/RecipeEdit.vue";
import CultureEdit from "@/components/forms/CultureEdit.vue";
import PlateEdit from "@/components/forms/PlateEdit.vue";
import ProjectEdit from "@/components/forms/ProjectEdit.vue";
import UserEdit from "@/components/forms/UserEdit.vue";
import StrainEdit from "@/components/forms/StrainEdit.vue";
import TubeEdit from "@/components/forms/TubeEdit.vue";
import { OlabDate } from "@/olab/olabdate";
import $ from "jquery";

export default {
  name: "EditForm",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: [
    "user",
    "dbInfo",
    "selectedType",
    "item",
    "selectedItems",
    "statusObj"
  ],
  components: {
    BioreactorEdit,
    BranchEdit,
    ExperimentEdit,
    FlaskEdit,
    IncubatorEdit,
    ManufacturerEdit,
    MediaEdit,
    ChemicalEdit,
    RecipeEdit,
    CultureEdit,
    PlateEdit,
    ProjectEdit,
    UserEdit,
    StrainEdit,
    TubeEdit
  },
  computed: {
    itemSaveIsDisabled() {
      return !OlabSanity.isItemValid(this.selectedType, this.item);
    },
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.item) {
        const createdAt = this.item.createdAt;
        const updatedAt = this.item.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    }
  },
  methods: {
    async handleSaveItem() {
      // Add additional field before emit
      // eslint-disable-next-line vue/no-mutating-props
      this.item.olab_type = this.selectedType;
      // console.log(
      //   "Add item = " + this.item + ", selectedType = " + this.selectedType
      // );
      await OlabAxios.saveItem(
        this.user,
        this.item,
        this.selectedItems,
        this.statusObj
      );
      $("#selectEditItem").modal("hide");
    }
  }
};
</script>
