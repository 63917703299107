<template>
  <div v-if="selectedType === 'chemical'">
    <div class="py-3 text-wrap">
      <span class="mr-1 h5">OLAB ID:</span>
      <span class="ml-1 h5">{{ item.olab_id }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div class="form-row">
      <div class="form-group col-md-3">
        <span class="mr-1">Quantity:</span>
        <span class="ml-1">{{ item.qty }} {{ item.unit }}</span>
      </div>
      <div class="form-group col-md-6">
        <span class="mr-1">Manufacturer ID:</span>
        <span class="ml-1">{{ item.mfr_id }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <span class="mr-1">Lot Number:</span>
        <span class="ml-1">{{ item.lot_no }}</span>
      </div>
      <div class="form-group col-md-6">
        <span class="mr-1">Expiry Date:</span>
        <span class="ml-1">{{ item.expire_date }}</span>
      </div>
    </div>
    <div class="form-group">
      <span class="mr-1">Note:</span>
      <span class="ml-1">{{ item.note }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChemicalView",
  props: ["dbInfo", "selectedType", "item"]
};
</script>
