<template>
  <div>
    <div v-if="chart && item">
      <div class="card">
        <div class="card-body mb-3">
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <label for="inp-cht-name" class="form-control-label">
                <span>Name:</span>
              </label>
              <input
                type="text"
                class="form-control"
                v-bind:class="validState(item.name)"
                id="inp-cht-name"
                placeholder="Name"
                :value="item.name"
                @input.prevent="updateName"
              />
              <small v-if="!item.name" class="mx-2 text-danger"
                >Name cannot be empty</small
              >
            </div>
            <div>
              <OlabStatusObjDialogs
                :statusObj="localStatusObj"
                :showStatusObjDialog="showStatusObjDialog"
                @emitStatusMessage="emitStatusMessage"
                @emitErrorMessage="emitErrorMessage"
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label for="sel-plot-type">Plot Type:</label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-plot-type"
                    v-model="plotType"
                    :options="computedPlotTypes"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    deselectLabel=""
                    :showLabels="false"
                    label="display_name"
                    track-by="key_name"
                  />
                </div>
                <!-- <p v-if="plotType">{{ plotType }}</p> -->
              </div>
              <div class="form-group col-md-7">
                <label for="sel-fltrs">Filters:</label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-fltrs"
                    v-model="selFilters"
                    :options="prodSettingModel"
                    :max="3"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Type to search"
                    label="display_name"
                    track-by="key_name"
                  />
                  <small class="form-text text-muted"> Up to 3 filters </small>
                </div>
                <!-- <p v-if="selFilters && selFilters.length > 0">
                  {{ selFilters }}
                </p> -->
              </div>
            </div>
            <div class="form-row">
              <div v-if="filCultrEnabled" class="form-group col-md-10">
                <div class="form-group">
                  <label for="sel-cultrs">Culture:</label>
                  <div class="mb-2 olabtest">
                    <VueMultiselect
                      id="sel-cultrs"
                      v-model="selFilCultures"
                      :options="cultureChoices"
                      :loading="isLoadingCultures"
                      :internal-search="false"
                      :multiple="true"
                      :close-on-select="true"
                      :options-limit="10"
                      :custom-label="nameInfo"
                      placeholder="Type to search"
                      label="olab_id"
                      track-by="olab_id"
                      @search-change="getCultures"
                    />
                  </div>
                  <!-- <p v-if="selFilCultures && selFilCultures.length > 0">
                    {{ computeSelFilCultures }}
                  </p> -->
                </div>
              </div>
            </div>
            <div class="form-row">
              <div v-if="filExprtEnabled" class="form-group col-md-10">
                <div class="form-group">
                  <label for="sel-exprts">Experiment:</label>
                  <div class="mb-2 olabtest">
                    <VueMultiselect
                      id="sel-exprts"
                      v-model="selFilExperiments"
                      :options="experimentChoices"
                      :loading="isLoadingExperiments"
                      :internal-search="false"
                      :multiple="true"
                      :close-on-select="true"
                      :options-limit="10"
                      :custom-label="nameInfo"
                      placeholder="Type to search"
                      label="olab_id"
                      track-by="olab_id"
                      @search-change="getExperiments"
                    />
                  </div>
                  <!-- <p v-if="selFilExperiments && selFilExperiments.length > 0">
                    {{ computeSelFilExperiments }}
                  </p> -->
                </div>
              </div>
            </div>
            <div class="form-row">
              <div v-if="filMediaEnabled" class="form-group col-md-10">
                <div class="form-group">
                  <label for="sel-medias">Media:</label>
                  <div class="mb-2 olabtest">
                    <VueMultiselect
                      id="sel-medias"
                      v-model="selFilMedias"
                      :options="mediaChoices"
                      :loading="isLoadingMedias"
                      :internal-search="false"
                      :multiple="true"
                      :close-on-select="true"
                      :options-limit="10"
                      :custom-label="nameInfo"
                      placeholder="Type to search"
                      label="olab_id"
                      track-by="olab_id"
                      @search-change="getMedias"
                    />
                  </div>
                  <!-- <p v-if="selFilMedias && selFilMedias.length > 0">
                    {{ computeSelFilMedias }}
                  </p> -->
                </div>
              </div>
            </div>
            <div class="form-row">
              <div v-if="filStrnEnabled" class="form-group col-md-10">
                <div class="form-group">
                  <label for="sel-strns">Strain:</label>
                  <div class="mb-2 olabtest">
                    <VueMultiselect
                      id="sel-strns"
                      v-model="selFilStrains"
                      :options="strainChoices"
                      :loading="isLoadingStrains"
                      :internal-search="false"
                      :multiple="true"
                      :close-on-select="true"
                      :options-limit="10"
                      :custom-label="nameInfo"
                      placeholder="Type to search"
                      label="olab_id"
                      track-by="olab_id"
                      @search-change="getStrains"
                    />
                  </div>
                  <!-- <p v-if="selFilStrains && selFilStrains.length > 0">
                    {{ computeSelFilStrains }}
                  </p> -->
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-7">
                <label for="sel-fltrs">Group By:</label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-fltrs"
                    v-model="selGroupBys"
                    :options="prodSettingModel"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Type to search"
                    label="display_name"
                    track-by="key_name"
                  />
                </div>
                <!-- <p v-if="selGroupBys && selGroupBys.length > 0">
                  {{ selGroupBys }}
                </p> -->
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="sel-palette">Palette:</label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-palette"
                    v-model="palette"
                    :options="paletteSettingModel"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    deselectLabel=""
                    :showLabels="false"
                    label="display_name"
                    track-by="key_name"
                  />
                </div>
                <!-- <p v-if="palette">{{ palette }}</p> -->
              </div>
              <div class="form-group col-md-5">
                <label for="sel-one-ppc">One Plot Per Column:</label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-one-ppc"
                    v-model="onePerPlotColumn"
                    :options="prodSettingModel"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Type to search"
                    label="display_name"
                    track-by="key_name"
                    :disabled="
                      (selLeftYAxes && selLeftYAxes.length > 1) ||
                      (selLeftYAxes &&
                        selLeftYAxes.length === 1 &&
                        selRightYAxes &&
                        selRightYAxes.length > 0) ||
                      (plotType && plotType.key_name === 'box-line')
                    "
                  />
                  <small class="form-text text-muted">
                    Limited to 1 y-axis plotting and does not support Box-Line
                    plot type
                  </small>
                </div>
                <!-- <p v-if="onePerPlotColumn">
                  {{ onePerPlotColumn }}
                </p> -->
              </div>
              <div class="form-group col-md-3">
                <label for="sel-num-ppr">Plots Per Row:</label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-num-ppr"
                    v-model="plotsPerRow"
                    :options="plotsPerRows"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    deselectLabel=""
                    :showLabels="false"
                    :disabled="onePerPlotColumn === null"
                  />
                </div>
                <!-- <p v-if="plotsPerRow">
                  {{ plotsPerRow }}
                </p> -->
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-10">
                <label for="sel-x-axes"
                  >X Axes:
                  <span
                    v-show="!isValidXAxes"
                    class="text-danger font-weight-bold"
                    >*</span
                  >
                </label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-x-axes"
                    v-model="selXAxes"
                    :options="prodDataModel"
                    :max="1"
                    :multiple="true"
                    :allow-empty="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Type to search"
                    label="display_name"
                    track-by="key_name"
                  />
                </div>
                <div v-show="!isValidXAxes" class="text-danger small ml-2">
                  x axes cannot be empty
                </div>
                <!-- <p v-if="selXAxes && selXAxes.length > 0">
                  {{ computedXAxes }}
                </p> -->
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-10">
                <label for="sel-left-y-axes"
                  >Left Y Axes:
                  <span
                    v-show="!isValidLeftYAxes"
                    class="text-danger font-weight-bold"
                    >*</span
                  >
                </label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-left-y-axes"
                    v-model="selLeftYAxes"
                    :options="leftYAxesOptions"
                    :max="onePerPlotColumn === null ? 10 : 1"
                    :multiple="true"
                    :allow-empty="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Type to search"
                    label="display_name"
                    track-by="key_name"
                  />
                </div>
                <div v-show="!isValidLeftYAxes" class="text-danger small ml-2">
                  left y axes cannot be empty
                </div>
                <!-- <p v-if="selLeftYAxes && selLeftYAxes.length > 0">
                  {{ computedLeftYAxes }}
                </p> -->
              </div>
            </div>
            <div v-if="onePerPlotColumn === null" class="form-row">
              <div class="form-group col-md-10">
                <label for="sel-right-y-axes">Right Y Axes: </label>
                <div class="mb-2 olabtest">
                  <VueMultiselect
                    id="sel-right-y-axes"
                    v-model="selRightYAxes"
                    :options="rightYAxesOptions"
                    :max="10"
                    :multiple="true"
                    :allow-empty="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Type to search"
                    label="display_name"
                    track-by="key_name"
                  />
                </div>
                <!-- <p v-if="selRightYAxes && selRightYAxes.length > 0">
                  {{ computedRightYAxes }}
                </p> -->
              </div>
            </div>
            <div v-if="svgImage" class="my-5">
              <div class="form-group row">
                <div class="col-md-4">
                  <label for="scale-down-input" class="mx-2 col-form-label"
                    >Scale Down</label
                  >
                  <!-- Slider to adjust the scale down -->
                  <input
                    id="scale-down-input"
                    type="range"
                    min="1"
                    max="5"
                    step="0.1"
                    v-model="scale"
                  />
                </div>
                <div class="col-md-8">
                  <label for="scale-down-input" class="mx-2 col-form-label"
                    >Y Axes Range</label
                  >
                  <input
                    type="radio"
                    id="auto-range"
                    :value="true"
                    v-model="autoRange"
                    class="ml-2"
                  />
                  <label for="auto-range" class="mx-1">auto</label>

                  <input
                    type="radio"
                    id="manual-range"
                    :value="false"
                    v-model="autoRange"
                    class="ml-2"
                  />
                  <label for="manual-range" class="mx-1">manual</label>

                  <input
                    type="checkbox"
                    id="showRngSdr"
                    v-model="showRangeSlider"
                    class="ml-2"
                    :disabled="autoRange == true"
                  />
                  <label for="showRngSdr" class="mx-1">Slider</label>
                </div>
              </div>
              <!-- TODO: Enable range setting when it is stable -->
              <div v-if="!autoRange && showRangeSlider" class="form-group row">
                <div class="col">
                  <RangeSlider
                    name="Left Y Axes Range"
                    :range="selLeftYAxesRange"
                    :limits="leftYAxesLimits"
                    @updateRange="updateLeftYAxesRange"
                    @emitFinalEvent="emitFinalYAxesRange"
                  />
                  <p class="my-3">
                    Left Y Range : {{ selLeftYAxesRange }}, Left Y Limits :
                    {{ leftYAxesLimits }}
                  </p>
                </div>
                <div class="col">
                  <RangeSlider
                    name="Right Y Axes Range"
                    :range="selRightYAxesRange"
                    :limits="rightYAxesLimits"
                    @updateRange="updateRightYAxesRange"
                    @emitFinalEvent="emitFinalYAxesRange"
                  />
                  <p class="my-3">
                    Right Y Range : {{ selRightYAxesRange }}, Right Y Limits :
                    {{ rightYAxesLimits }}
                  </p>
                </div>
              </div>
              <!-- Container for the v-html content -->
              <div
                class="svg-container"
                style="overflow-x: scroll; overflow-y: scroll"
                v-html="svgImageWithViewBox"
              ></div>
              <!-- <h6 class="text-center pt-2">{{ item.control }}</h6>
              <h6 class="text-center pt-2">{{ item.svg_filenames }}</h6> -->
            </div>
            <div class="form-row my-3 justify-content-center">
              <span>
                <button
                  type="button"
                  v-bind:disabled="!svgImage"
                  class="btn btn-primary"
                  v-on:click="downloadCSVFile(chart)"
                >
                  <span
                    v-if="localStatusObj.progress"
                    class="spinner-border spinner-border-sm text-light"
                  ></span>
                  Download plot data as CSV file
                </button>
              </span>
            </div>
            <div class="form-group">
              <label for="inp-desc">Description</label>
              <textarea
                type="textarea"
                class="form-control"
                id="inp-desc"
                :value="item.desc"
                @input.prevent="updateDesc"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="inp-note">Note</label>
              <textarea
                type="textarea"
                class="form-control"
                id="inp-note"
                :value="item.note"
                @input.prevent="updateNote"
              ></textarea>
            </div>
          </form>
          <div class="mt-2 form-row justify-content-between font-italic">
            <div class="col-md-6">Updated at: {{ formatTimeStamp }}</div>
            <div class="col-md-6">Created by: {{ item.creator }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OlabStatusObjDialogs from "@/components/OlabStatusObjDialogs.vue";
import RangeSlider from "@/components/ui/RangeSliderUI.vue";
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
// import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";
import { paletteSettingModel } from "@/assets/data/palette_setting";
import { prodSettingModel } from "@/assets/data/prod_setting";
import { prodDataCfg1Model } from "@/assets/data/prod_data_cfg1";
import { prodDataCfg2Model } from "@/assets/data/prod_data_cfg2";
import { plotTypeSettingModel } from "@/assets/data/plot_setting";

export default {
  name: "ChartControl",
  data() {
    return {
      scale: 1, // Initial scale value
      svgImage: "", // Holds the raw SVG content
      viewBoxMinX: 0, // Initial min-x value for the viewBox
      viewBoxMinY: 0, // Initial min-y value for the viewBox
      viewBoxWidth: 500, // Initial width value for the viewBox
      viewBoxHeight: 500, // Initial height value for the viewBox
      maxViewBoxX: 1000, // Max value for viewBox X
      maxViewBoxY: 1000, // Max value for viewBox Y
      item: this.chart,
      plotType: {
        key_name: "line",
        display_name: "Line"
      },
      palette: {
        key_name: "bright",
        display_name: "Bright"
      },
      paletteSettingModel,
      plotTypeSettingModel,
      prodSettingModel,
      prodDataModel: [],
      prodDataCfg1Model,
      prodDataCfg2Model,
      leftYAxesOptions: [],
      rightYAxesOptions: [],
      onePerPlotColumn: null,
      plotsPerRow: 1,
      plotsPerRows: ["1", "2", "3", "4"],
      selFilters: [],
      selFilCultures: [],
      selFilExperiments: [],
      selFilMedias: [],
      selFilStrains: [],
      selGroupBys: [],
      selXAxes: [],
      selLeftYAxes: [],
      selRightYAxes: [],
      autoRange: true,
      showRangeSlider: false,
      spNegCntrlNum: -1234.56,
      spPosCntrlNum: 1234.56,
      selLeftYAxesRange: [this.spNegCntrlNum, this.spPosCntrlNum],
      selRightYAxesRange: [this.spNegCntrlNum, this.spPosCntrlNum],
      leftYAxesLimits: [this.spNegCntrlNum, this.spPosCntrlNum],
      rightYAxesLimits: [this.spNegCntrlNum, this.spPosCntrlNum],
      throttleTimeout: null, // To store the setTimeout ID

      cultureChoices: [],
      experimentChoices: [],
      mediaChoices: [],
      strainChoices: [],

      isLoadingCultures: false,
      isLoadingExperiments: false,
      isLoadingMedias: false,
      isLoadingStrains: false,
      filCultrEnabled: false,
      filExprtEnabled: false,
      filMediaEnabled: false,
      filStrnEnabled: false,
      lastFilCultrEnabled: false,
      lastFilExprtEnabled: false,
      lastFilMediaEnabled: false,
      lastFilStrnEnabled: false,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      },
      showStatusObjDialog: false
    };
  },
  props: ["user", "dbInfo", "selectedType", "chart", "charts", "statusObj"],
  emits: ["setStatusMessage", "setErrorMessage"],
  components: {
    OlabStatusObjDialogs,
    VueMultiselect,
    RangeSlider
  },
  async mounted() {
    // console.log("ChartControl mounted(): chart.olab_id =", this.chart.olab_id);
    // console.log(
    //   "ChartControl mounted(): chart's control =",
    //   this.chart.control
    // );

    const prodDataCategory =
      this.dbInfo && this.dbInfo.prod_data_categories
        ? this.dbInfo.prod_data_categories[0]
        : "prod_data_cfg1";
    // console.log("prodDataCategory =", prodDataCategory);
    // console.log("prodDataCfg1Model =", this.prodDataCfg1Model);
    // console.log("prodDataCfg2Model =", this.prodDataCfg2Model);
    if (prodDataCategory === "prod_data_cfg1") {
      this.prodDataModel.push(...this.prodDataCfg1Model);
    } else {
      this.prodDataModel.push(...this.prodDataCfg2Model);
    }
    const ctrl = this.chart.control;

    // Initialize plotType with item's control.type
    if (ctrl && ctrl.type) {
      this.plotType.key_name = ctrl.type;
      const pt = this.plotTypeSettingModel.find(
        (p) => p.key_name === ctrl.type
      );
      if (pt) {
        this.plotType.display_name = pt.display_name;
      }
    }

    // Initialize selFilters with item's control.filters
    if (this.prodSettingModel && this.selFilters && ctrl) {
      if (ctrl.filters) {
        ctrl.filters.forEach((fil) => {
          if (fil.key) {
            const fObj = this.prodSettingModel.find(
              (f) => f.key_name === fil.key
            );
            if (fObj) {
              this.selFilters.push(fObj);
            }
          }
        });
      }

      // console.log("** selFilters =", this.selFilters);
      // Initialize filters
      if (this.selFilters && this.selFilters.length > 0) {
        this.selFilters.forEach((sf) => {
          const fType = sf.key_name;
          this.InitFilter(fType, ctrl);
        });
      }
    }

    // Initialize group by:
    // console.log("ctrl's groups =", ctrl.groups);
    if (ctrl && ctrl.groups) {
      ctrl.groups.forEach((grp) => {
        if (grp) {
          const gObj = this.prodSettingModel.find((g) => g.key_name === grp);
          if (gObj) {
            this.selGroupBys.push(gObj);
          }
        }
      });
    }
    // console.log("this.selGroupBys =", this.selGroupBys);

    // Initialize palette
    // console.log("ctrl's palette =", ctrl.palette);
    if (ctrl && ctrl.palette) {
      this.palette.key_name = ctrl.palette;
      const pt = this.paletteSettingModel.find(
        (p) => p.key_name === ctrl.palette
      );
      if (pt) {
        this.palette.display_name = pt.display_name;
      }
    }
    // console.log("palette =", this.palette);

    if (ctrl && ctrl.col_wrap) {
      this.plotsPerRow = ctrl.col_wrap;
    }

    if (ctrl && ctrl.col) {
      const oppc = this.prodSettingModel.find((p) => p.key_name === ctrl.col);
      if (oppc) {
        this.onePerPlotColumn = {
          key_name: oppc.key_name,
          display_name: oppc.display_name
        };
      } else {
        this.onePerPlotColumn = null;
        // Reset plotsPerRow to 1
        this.plotsPerRow = 1;
      }
    }
    // console.log("onePerPlotColumn =", this.onePerPlotColumn);
    // console.log("plotsPerRow =", this.plotsPerRow);

    if (this.prodDataModel && ctrl) {
      // console.log("ctrl.x_axes =", ctrl.x_axes);
      if (ctrl.x_axes && ctrl.x_axes.length > 0) {
        ctrl.x_axes.map((xA) => {
          const axeObj = this.prodDataModel.find((pd) => {
            // console.log("pd =", pd, ", xA =", xA);
            return pd.key_name === xA;
          });
          if (axeObj) {
            this.selXAxes.push(axeObj);
          }
        });
      } else {
        ctrl.x_axes = [];
        this.selXAxes.length = 0;
      }

      // console.log("ctrl.left_y_axes =", ctrl.left_y_axes);
      if (ctrl.left_y_axes && ctrl.left_y_axes.length > 0) {
        ctrl.left_y_axes.map((yA) => {
          const axeObj = this.prodDataModel.find((pd) => {
            return pd.key_name === yA;
          });
          if (axeObj) {
            this.selLeftYAxes.push(axeObj);
          }
        });
      } else {
        ctrl.left_y_axes = [];
        this.selLeftYAxes.length = 0;
      }
      if (ctrl.right_y_axes && ctrl.right_y_axes.length > 0) {
        ctrl.right_y_axes.map((yA) => {
          const axeObj = this.prodDataModel.find((pd) => {
            return pd.key_name === yA;
          });
          if (axeObj) {
            this.selRightYAxes.push(axeObj);
          }
        });
      } else {
        ctrl.right_y_axes = [];
        this.selRightYAxes.length = 0;
      }

      this.autoRange = this.chart.auto_range;
      // console.log("autoRange =", this.autoRange);

      // console.log("left_y_axes_limits =", this.chart.left_y_axes_limits);
      // console.log("right_y_axes_limits =", this.chart.right_y_axes_limits);
      this.setExtLimits(this.chart.left_y_axes_limits, this.leftYAxesLimits);
      this.setExtLimits(this.chart.right_y_axes_limits, this.rightYAxesLimits);
      // console.log("leftYAxesLimits =", this.leftYAxesLimits);
      // console.log("rightYAxesLimits =", this.rightYAxesLimits);
      // console.log("left_y_axes_range =", ctrl.left_y_axes_range);
      // console.log("right_y_axes_range =", ctrl.right_y_axes_range);
      this.setAxesRange(
        ctrl.left_y_axes_range,
        this.chart.left_y_axes_limits,
        this.selLeftYAxesRange
      );
      this.setAxesRange(
        ctrl.right_y_axes_range,
        this.chart.right_y_axes_limits,
        this.selRightYAxesRange
      );
      // console.log("selLeftYAxesRange =", this.selLeftYAxesRange);
      // console.log("selRightYAxesRange =", this.selRightYAxesRange);
    }
    this.leftYAxesOptions = this.updateOptions(
      this.selLeftYAxes,
      this.prodDataModel
    );
    this.rightYAxesOptions = this.updateOptions(
      this.selRightYAxes,
      this.prodDataModel
    );

    // Preload filter's value choices
    await this.getCultures("");
    await this.getExperiments("");
    await this.getMedias("");
    await this.getStrains("");

    // console.log("item'ssvg_filenames =", this.item.svg_filenames);
    // Load SVG Image if chart has generated svg file
    if (
      this.item &&
      this.item.svg_filenames &&
      this.item.svg_filenames.length > 0
    ) {
      this.svgImage = await OlabAxios.getSVGFile(
        { olab_id: this.item.olab_id },
        400,
        300,
        this.localStatusObj
      );

      if (this.svgImage) {
        // Parse the SVG content and extract the viewBox values
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(this.svgImage, "image/svg+xml");
        const svgElement = svgDoc.querySelector("svg");
        if (svgElement) {
          const viewBox = svgElement.getAttribute("viewBox");
          if (viewBox) {
            const [minX, minY, width, height] = viewBox.split(" ").map(Number);
            this.viewBoxMinX = minX;
            this.viewBoxMinY = minY;
            this.viewBoxWidth = width;
            this.viewBoxHeight = height;

            // Set max values for sliders based on the initial SVG viewBox values
            this.maxViewBoxX = width;
            this.maxViewBoxY = height;
          }
          this.viewBoxWidth = this.scale * this.maxViewBoxX;
          this.viewBoxHeight = this.scale * this.maxViewBoxY;
        }
      }
    }
  },
  computed: {
    svgImageWithViewBox() {
      if (!this.svgImage) return "";

      // Create a temporary DOM element to manipulate the SVG content
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.svgImage;
      const svgElement = tempDiv.querySelector("svg");

      if (svgElement) {
        svgElement.setAttribute(
          "viewBox",
          `${this.viewBoxMinX} ${this.viewBoxMinY} ${this.viewBoxWidth} ${this.viewBoxHeight}`
        );
      }

      return tempDiv.innerHTML;
    },
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.item) {
        const createdAt = this.item.createdAt;
        const updatedAt = this.item.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    },
    isValidXAxes() {
      return this.selXAxes !== null && this.selXAxes.length > 0;
    },
    isValidLeftYAxes() {
      return this.selLeftYAxes !== null && this.selLeftYAxes.length > 0;
    },
    computeSelFilCultures() {
      const csfCultrs = [];
      if (this.selFilCultures) {
        this.selFilCultures.forEach((sf) => {
          const csf = { name: sf.name, olab_id: sf.olab_id };
          csfCultrs.push(csf);
        });
      }
      return csfCultrs;
    },
    computeSelFilExperiments() {
      const csfExprts = [];
      if (this.selFilExperiments) {
        this.selFilExperiments.forEach((sf) => {
          const csf = { name: sf.name, olab_id: sf.olab_id };
          csfExprts.push(csf);
        });
      }
      return csfExprts;
    },
    computeSelFilMedias() {
      const csfMedias = [];
      if (this.selFilMedias) {
        this.selFilMedias.forEach((sf) => {
          const csf = { name: sf.name, olab_id: sf.olab_id };
          csfMedias.push(csf);
        });
      }
      return csfMedias;
    },
    computeSelFilStrains() {
      const csfStrns = [];
      if (this.selFilStrains) {
        this.selFilStrains.forEach((sf) => {
          const csf = { name: sf.name, olab_id: sf.olab_id };
          csfStrns.push(csf);
        });
      }
      return csfStrns;
    },
    computedXAxes() {
      const cxAxes = [];
      if (this.selXAxes) {
        this.selXAxes.forEach((xA) => {
          const cxA = { key_name: xA.key_name, display_name: xA.display_name };
          cxAxes.push(cxA);
        });
      }
      return cxAxes;
    },
    computedLeftYAxes() {
      const cyAxes = [];
      if (this.selLeftYAxes) {
        this.selLeftYAxes.forEach((yA) => {
          const cyA = { key_name: yA.key_name, display_name: yA.display_name };
          cyAxes.push(cyA);
        });
      }
      return cyAxes;
    },
    computedRightYAxes() {
      const cyAxes = [];
      if (this.selRightYAxes) {
        this.selRightYAxes.forEach((yA) => {
          const cyA = { key_name: yA.key_name, display_name: yA.display_name };
          cyAxes.push(cyA);
        });
      }
      return cyAxes;
    },
    computedPlotTypes() {
      // Remove Box-Line from options if onePerPlotColumn is set
      return this.plotTypeSettingModel.filter(
        (opt) =>
          !this.onePerPlotColumn ||
          (this.onePerPlotColumn && opt.key_name !== "box-line")
      );
    }
  },
  methods: {
    updateLeftYAxesRange(rng) {
      // console.log("(1) XXXX updateLeftYAxesRange =", rng);
      this.selLeftYAxesRange[0] = rng[0];
      this.selLeftYAxesRange[1] = rng[1];
    },
    updateRightYAxesRange(rng) {
      // console.log("XXXX updateRightYAxesRange =", rng);
      this.selRightYAxesRange[0] = rng[0];
      this.selRightYAxesRange[1] = rng[1];
    },
    // Handle last range values
    emitFinalYAxesRange(evt) {
      // eslint-disable-next-line no-constant-condition
      if (false) console.log("(1) emitFinalYAxesRange =", evt);
      const ctrlLRMin = this.item.control.left_y_axes_range[0];
      const ctrlLRMax = this.item.control.left_y_axes_range[1];
      const ctrlRRMin = this.item.control.right_y_axes_range[0];
      const ctrlRRMax = this.item.control.right_y_axes_range[1];
      if (
        this.selLeftYAxesRange[0] != ctrlLRMin ||
        this.selLeftYAxesRange[1] != ctrlLRMax ||
        this.selRightYAxesRange[0] != ctrlRRMin ||
        this.selRightYAxesRange[1] != ctrlRRMax
      ) {
        // throttle request
        if (this.localStatusObj.progress === true) {
          // console.log("In progress ... Try again later");
          // Clear any existing timeout
          clearTimeout(this.throttleTimeout);
          this.throttleTimeout = setTimeout(() => {
            this.emitFinalYAxesRange(evt);
          }, 2000); // 2 second throttle
        } else {
          this.item.control.left_y_axes_range[0] = this.selLeftYAxesRange[0];
          this.item.control.left_y_axes_range[1] = this.selLeftYAxesRange[1];
          this.item.control.right_y_axes_range[0] = this.selRightYAxesRange[0];
          this.item.control.right_y_axes_range[1] = this.selRightYAxesRange[1];
          // console.log("(2) emitFinalYAxesRange =", evt);
          this.updateAndGenChart();

          // Check if limits need expansion
          this.expandLimits(this.selLeftYAxesRange, this.leftYAxesLimits);
          this.expandLimits(this.selRightYAxesRange, this.rightYAxesLimits);
        }
      }
    },
    expandLimits(range, limits) {
      // console.log("XX range[0] =", range[0]);
      // Incr. limit if range is at its limit
      if (range[0] <= limits[0]) {
        limits[0] =
          limits[0] < 0
            ? limits[0] + limits[0] * 0.2
            : limits[0] - limits[0] * 0.2;
        limits[0] = Math.floor(limits[0]);
        // console.log("XX Min limits[0] =", limits[0]);
      }
      if (range[1] >= limits[1]) {
        limits[1] =
          limits[1] < 0
            ? limits[1] - limits[1] * 0.2
            : limits[1] + limits[1] * 0.2;
        limits[1] = Math.ceil(limits[1]);
        // console.log("XX Max limits[1] =", limits[1]);
      }
    },
    delayInSecond(sec) {
      return new Promise((resolve) => setTimeout(resolve, sec * 1000));
    },
    emitStatusMessage(statusMsg, statusProgress) {
      this.localStatusObj.message = statusMsg;
      this.localStatusObj.progress = statusProgress;
      this.showStatusObjDialog = this.localStatusObj.message != null;
    },
    emitErrorMessage(errMsg) {
      this.localStatusObj.errorMessage = errMsg;
      this.showStatusObjDialog = this.localStatusObj.errorMessage != null;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ olab_id, name }) {
      return `${name}: [${olab_id}]`;
    },
    setExtLimits(limits, selLimits) {
      let newLmts = [];
      if (limits && limits.length === 2) {
        const lmt0 = limits[0];
        const lmt1 = limits[1];
        const min = lmt0 <= lmt1 ? lmt0 : lmt1;
        const max = lmt0 > lmt1 ? lmt0 : lmt1;
        const rng = max - min;
        const ext = rng > 20 ? rng / 2 : 10;
        newLmts = [Math.floor(min - ext), Math.ceil(max + ext)];
        selLimits[0] = newLmts[0];
        selLimits[1] = newLmts[1];
      }
      // console.log("newLmts =", newLmts);
      // console.log("selLimits =", selLimits);
    },
    setAxesRange(axesRange, axesLimits, selRange) {
      if (
        axesRange &&
        axesRange.length === 2 &&
        axesRange[0] != null &&
        axesRange[1] != null
      ) {
        selRange[0] = axesRange[0];
        selRange[1] = axesRange[1];
      } else if (axesLimits && axesLimits.length === 2) {
        selRange[0] = axesLimits[0];
        selRange[1] = axesLimits[1];
      }
    },
    async setupSelFilter(fType, idArr) {
      // console.log(fType, ": idArr =", idArr);
      if (idArr) {
        // console.log("idArr.length =", idArr.length);
        if (fType === "culture_id") {
          this.filCultrEnabled = true;
          const cltrs = await OlabAxios.getCulturesByOlabIDs(
            idArr,
            this.localStatusObj
          );
          this.selFilCultures = this.selFilCultures.concat(cltrs);
          // console.log("selFilCultures =", this.selFilCultures);
        } else if (fType === "experiment_id") {
          this.filExprtEnabled = true;
          const exprts = await OlabAxios.getExperimentsByOlabIDs(
            idArr,
            this.localStatusObj
          );
          this.selFilExperiments = this.selFilExperiments.concat(exprts);
          // console.log("selFilExperiments =", this.selFilExperiments);
        } else if (fType === "media_id") {
          this.filMediaEnabled = true;
          const medias = await OlabAxios.getMediasByOlabIDs(
            idArr,
            this.localStatusObj
          );
          this.selFilMedias = this.selFilMedias.concat(medias);
          // console.log("selFilMedias =", this.selFilMedias);
        } else if (fType === "strain_id") {
          this.filStrnEnabled = true;
          const strains = await OlabAxios.getStrainsByOlabIDs(
            idArr,
            this.localStatusObj
          );
          this.selFilStrains = this.selFilStrains.concat(strains);
          // console.log("selFilStrains =", this.selFilStrains);
        }
      }
    },
    async InitFilter(fType, ctrl) {
      let vals = [];
      if (fType === "culture_id") {
        const fils = ctrl.filters;
        if (fils && fils.length > 0) {
          fils.forEach((fil) => {
            if (fil.key === fType) {
              vals = vals.concat(fil.values);
            }
          });
        }
      } else if (fType === "experiment_id") {
        const fils = ctrl.filters;
        if (fils && fils.length > 0) {
          fils.forEach((fil) => {
            if (fil.key === fType) {
              vals = vals.concat(fil.values);
            }
          });
        }
      } else if (fType === "media_id") {
        const fils = ctrl.filters;
        if (fils && fils.length > 0) {
          fils.forEach((fil) => {
            if (fil.key === fType) {
              vals = vals.concat(fil.values);
            }
          });
        }
      } else if (fType === "strain_id") {
        const fils = ctrl.filters;
        if (fils && fils.length > 0) {
          fils.forEach((fil) => {
            if (fil.key === fType) {
              vals = vals.concat(fil.values);
            }
          });
        }
      }
      await this.setupSelFilter(fType, vals);
    },
    updateOptions(selList, optList) {
      let resList = optList;
      if (selList && selList.length > 0) {
        const firstEle = selList[0];
        // console.log("firstEle =", firstEle);
        resList = optList.filter(
          (item) => item.base_unit === firstEle.base_unit
        );
      }
      return resList;
    },
    async getCultures(query) {
      // console.log("getCultures: query = ", query);
      this.isLoadingCultures = true;
      const searchBody = OlabSearch.createSearchBody("culture", query, "merge");
      this.cultureChoices = await OlabAxios.getCultures(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingCultures = false;
    },
    async getExperiments(query) {
      this.isLoadingExperiments = true;
      const searchBody = OlabSearch.createSearchBody(
        "experiment",
        query,
        "merge"
      );
      this.experimentChoices = await OlabAxios.getExperiments(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingExperiments = false;
    },
    async getMedias(query) {
      // console.log("getMedias: query = ", query);
      this.isLoadingMedias = true;
      const searchBody = OlabSearch.createSearchBody("media", query, "merge");
      this.mediaChoices = await OlabAxios.getMedias(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingMedias = false;
    },
    async getStrains(query) {
      this.isLoadingStrains = true;
      const searchBody = OlabSearch.createSearchBody("strain", query, "merge");
      this.strainChoices = await OlabAxios.getStrains(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingStrains = false;
    },
    async updateChart() {
      if (
        this.item.control.x_axes === null ||
        this.item.control.x_axes.length < 1 ||
        this.item.control.left_y_axes === null ||
        this.item.control.left_y_axes.length < 1
      ) {
        // Clear svg_filenames if either axes isn't set
        this.item.svg_filenames = [];
      }
      await OlabAxios.updateChart(this.user, this.item, this.localStatusObj);
    },
    async generateSVGFile() {
      await OlabAxios.generateSVGFile(
        this.user,
        this.item,
        this.localStatusObj
      );
      if (this.localStatusObj.errorMessage) {
        this.showStatusObjDialog = this.localStatusObj.errorMessage != null;
        return;
      }
      // console.log("** svg_filenames =", this.item.svg_filenames);
      if (this.item.svg_filenames && this.item.svg_filenames.length > 0) {
        this.svgImage = await OlabAxios.getSVGFile(
          { olab_id: this.item.olab_id },
          400,
          300,
          this.localStatusObj
        );
      }
      this.showStatusObjDialog = this.localStatusObj.errorMessage != null;
      // console.log(
      //   "generateSVGFile: showStatusObjDialog =",
      //   this.showStatusObjDialog
      // );
    },
    async updateAndGenChart() {
      await this.updateChart();
      // Note: Do not generate SVG File if x_axes or left_y_axes
      // is not set.
      if (
        this.item.control &&
        this.item.control.x_axes &&
        this.item.control.left_y_axes &&
        this.item.control.x_axes.length > 0 &&
        this.item.control.left_y_axes.length > 0
      ) {
        await this.generateSVGFile();
      }
    },
    async downloadCSVFile(chart) {
      const csvFile = await OlabAxios.getChartCSVFile(
        chart,
        this.localStatusObj
      );

      const fileName = `${chart.name}.csv`;
      if (csvFile) {
        // download file to client machine
        // console.log("Download Chart Name: ", fileName, ", csvFile =", csvFile);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.style.visibility = "hidden";

        const blob = new Blob([csvFile], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    },
    updateName(e) {
      if (this.item) {
        const name = e.target.value;
        this.item.name = name;
        if (this.item.name) {
          this.updateChart();
        }
      }
    },
    updateDesc(e) {
      if (this.item) {
        const desc = e.target.value;
        this.item.desc = desc == "" ? null : desc;
        this.updateChart();
      }
    },
    updateNote(e) {
      if (this.item) {
        const note = e.target.value;
        this.item.note = note == "" ? null : note;
        this.updateChart();
      }
    }
  },
  watch: {
    "chart.left_y_axes_limits"() {
      // console.log(
      //   "watch: chart.left_y_axes_limits =",
      //   this.chart.left_y_axes_limits
      // );
      if (
        this.leftYAxesLimits[0] == this.spNegCntrlNum &&
        this.leftYAxesLimits[1] == this.spPosCntrlNum
      ) {
        this.setExtLimits(this.chart.left_y_axes_limits, this.leftYAxesLimits);
      }
    },
    "chart.right_y_axes_limits"() {
      // console.log(
      //   "watch: chart.right_y_axes_limits =",
      //   this.chart.right_y_axes_limits
      // );
      if (
        this.rightYAxesLimits[0] == this.spNegCntrlNum &&
        this.rightYAxesLimits[1] == this.spPosCntrlNum
      ) {
        this.setExtLimits(
          this.chart.right_y_axes_limits,
          this.rightYAxesLimits
        );
      }
    },
    scale() {
      this.viewBoxWidth = this.scale * this.maxViewBoxX;
      this.viewBoxHeight = this.scale * this.maxViewBoxY;
    },
    autoRange() {
      if (this.item && this.item.control) {
        this.item.auto_range = this.autoRange;
        // console.log("autoRange =", this.item.auto_range);
        this.updateAndGenChart();
      }
    },
    plotType() {
      if (this.plotType && this.item && this.item.control) {
        this.item.control.type = this.plotType.key_name;
        // console.log("plotType =", this.item.control.type);
        this.updateAndGenChart();
      }
    },
    palette() {
      if (this.palette && this.item && this.item.control) {
        this.item.control.palette = this.palette.key_name;
        // console.log("palette =", this.item.control.palette);
        this.updateAndGenChart();
      }
    },
    onePerPlotColumn() {
      // control.col is the control backing for onePerPlotColumn
      // console.log("Watch: onePerPlotColumn =", this.onePerPlotColumn);
      if (this.onePerPlotColumn && this.item && this.item.control) {
        this.item.control.col = this.onePerPlotColumn.key_name;
        this.item.control.col_wrap = this.plotsPerRow
          ? parseInt(this.plotsPerRow, 10)
          : 1;
      } else {
        this.item.control.col = null;
        // Reset plotsPerRow to 1 and remove col_wrap in options
        this.item.control.col_wrap = 1;
        this.plotsPerRow = 1;
      }
      // console.log("% (2) control.col =", this.item.control.col);
      this.updateAndGenChart();
    },
    plotsPerRow() {
      // control.col_wrap is the control backing for plotsPerRow
      // console.log("Watch: plotsPerRow =", this.plotsPerRow);
      if (this.plotsPerRow && this.item && this.item.control) {
        this.item.control.col_wrap = parseInt(this.plotsPerRow, 10);
      }
      // console.log("control.col_wrap =", this.item.control.col_wrap);
      this.updateAndGenChart();
    },
    selXAxes() {
      if (this.selXAxes && this.item && this.item.control) {
        const axes = [];
        this.selXAxes.forEach((x) => {
          axes.push(x.key_name);
        });

        if (axes.length < 1) {
          // console.log("x axes is empty");
          // Clear svgImage
          this.svgImage = null;
        }
        this.item.control.x_axes = axes;
        // console.log("control =", this.item.control);
        this.updateAndGenChart();
      }
    },
    selLeftYAxes() {
      if (this.selLeftYAxes && this.item && this.item.control) {
        const axes = [];
        this.selLeftYAxes.forEach((y) => {
          axes.push(y.key_name);
        });
        if (axes.length < 1) {
          // console.log("y axes is empty");
          // Clear svgImage
          this.svgImage = null;
        }
        this.item.control.left_y_axes = axes;
        // console.log("control =", this.item.control);
        this.updateAndGenChart();
        // compute filtered leftYAxesOptions
        this.leftYAxesOptions = this.updateOptions(
          this.selLeftYAxes,
          this.prodDataModel
        );
      }
    },
    selRightYAxes() {
      if (this.selRightYAxes && this.item && this.item.control) {
        const axes = [];
        this.selRightYAxes.forEach((y) => {
          axes.push(y.key_name);
        });
        if (axes.length < 1) {
          // console.log("y axes is empty");
          // Clear svgImage
          this.svgImage = null;
        }
        this.item.control.right_y_axes = axes;
        // console.log("control =", this.item.control);
        this.updateAndGenChart();
        // compute filtered rightYAxisOptions
        this.rightYAxesOptions = this.updateOptions(
          this.selRightYAxes,
          this.prodDataModel
        );
      }
    },
    selGroupBys() {
      if (this.selGroupBys && this.item && this.item.control) {
        const gbs = [];
        this.selGroupBys.forEach((gb) => {
          gbs.push(gb.key_name);
        });
        this.item.control.groups = gbs;
      }
      // console.log("control =", this.item.control);
      this.updateAndGenChart();
    },
    selFilters() {
      // console.log("Watch: selFilters =", this.selFilters);
      // console.log("selFilters.length =", this.selFilters.length);

      this.lastFilCultrEnabled = this.filCultrEnabled;
      this.filCultrEnabled = false;
      this.lastFilExprtEnabled = this.filExprtEnabled;
      this.filExprtEnabled = false;
      this.lastFilMediaEnabled = this.filMediaEnabled;
      this.filMediaEnabled = false;
      this.lastFilStrnEnabled = this.filStrnEnabled;
      this.filStrnEnabled = false;

      const ctrl = this.item.control;

      // Quick reset and shortcircuit call
      if (this.selFilters.length === 0) {
        ctrl.filters = [];
        // Update and generate Chart
        this.updateAndGenChart();
        return;
      }

      const prevFilObjs = ctrl.filters.length > 0 ? [...ctrl.filters] : [];
      const filArr = [...this.selFilters];
      filArr.forEach((fil) => {
        if (fil.key_name === "culture_id") {
          this.filCultrEnabled = true;
          if (this.lastFilCultrEnabled != this.filCultrEnabled) {
            this.selFilCultures.length = 0;
          }
        } else if (fil.key_name === "experiment_id") {
          this.filExprtEnabled = true;
          if (this.lastFilExprtEnabled != this.filExprtEnabled) {
            this.selFilExperiments.length = 0;
          }
        } else if (fil.key_name === "media_id") {
          this.filMediaEnabled = true;
          if (this.lastFilMediaEnabled != this.filMediaEnabled) {
            this.selFilMedias.length = 0;
          }
        } else if (fil.key_name === "strain_id") {
          this.filStrnEnabled = true;
          if (this.lastFilStrnEnabled != this.filStrnEnabled) {
            this.selFilStrains.length = 0;
          }
        }
      });
      // console.log("culture =", this.filCultrEnabled);
      // console.log("experiment =", this.filExprtEnabled);
      // console.log("media =", this.filMediaEnabled);
      // console.log("strain =", this.filStrnEnabled);

      // Construct control.filters
      // console.log("(1) filArr =", filArr);
      const filOpArr = [];
      filArr.map((fil) => {
        let filOpObj = prevFilObjs.find((fObj) => {
          return fil.key_name === fObj.key;
        });
        // console.log("----> filOpObj =", filOpObj);
        if (!filOpObj) {
          filOpObj = {
            key: fil.key_name,
            operator: "==",
            values: []
          };
        }
        filOpArr.push(filOpObj);
      });
      ctrl.filters = filOpArr;
      // console.log("(2) control.filters =", ctrl.filters);
      // Update and generate Chart
      this.updateAndGenChart();
    },
    selFilCultures() {
      // console.log("Watch: selFilCultures");
      const vals = [];
      this.selFilCultures.map((sf) => {
        // console.log("sf =", sf);
        vals.push(sf.olab_id);
      });
      const ctrl = this.item.control;
      // Assign vals to culture_id's values[] in filters
      if (ctrl.filters) {
        const fltrs = ctrl.filters;
        fltrs.forEach((fltr) => {
          if (fltr.key === "culture_id") {
            fltr.values = vals;
          }
        });
      }
      // Update and generate Chart
      this.updateAndGenChart();
    },
    selFilExperiments() {
      // console.log("Watch: selFilExperiments");
      const vals = [];
      this.selFilExperiments.map((sf) => {
        // console.log("sf =", sf.olab_id);
        vals.push(sf.olab_id);
      });
      const ctrl = this.item.control;
      // Assign vals to experiment_id's values[] in filters
      if (ctrl.filters) {
        const fltrs = ctrl.filters;
        fltrs.forEach((fltr) => {
          if (fltr.key === "experiment_id") {
            fltr.values = vals;
          }
        });
      }
      // Update and generate Chart
      this.updateAndGenChart();
    },
    selFilMedias() {
      // console.log("Watch: selFilMedias");
      const vals = [];
      this.selFilMedias.map((sf) => {
        // console.log("sf =", sf.olab_id);
        vals.push(sf.olab_id);
      });
      const ctrl = this.item.control;
      // Assign vals to media_id's values[] in filters
      if (ctrl.filters) {
        const fltrs = ctrl.filters;
        fltrs.forEach((fltr) => {
          if (fltr.key === "media_id") {
            fltr.values = vals;
          }
        });
      }
      // Update and generate Chart
      this.updateAndGenChart();
    },
    selFilStrains() {
      // console.log("Watch: selFilStrains");
      const vals = [];
      this.selFilStrains.map((sf) => {
        // console.log("sf =", sf.olab_id);
        vals.push(sf.olab_id);
      });
      const ctrl = this.item.control;
      // Assign vals to strain_id's values[] in filters
      if (ctrl.filters) {
        const fltrs = ctrl.filters;
        fltrs.forEach((fltr) => {
          if (fltr.key === "strain_id") {
            fltr.values = vals;
          }
        });
      }
      // Update and generate Chart
      this.updateAndGenChart();
    }
  }
};
</script>

<style scoped>
.oak-invalid {
  background: red;
  padding: 0.06rem;
  border-radius: 0.4rem;
}

.oak-valid {
  background: rgb(1, 152, 1);
  padding: 0.06rem;
  border-radius: 0.4rem;
}

.oak-flex-creator-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.oak-flex-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 0.05rem solid;
  margin: 2rm 1rem;
}
.svg-container {
  margin-top: 20px;
  width: 100%;
  height: auto;
}
</style>
