<template>
  <div v-if="selectedType === 'tube'">
    <div class="form-row">
      <div class="form-group col-md-8">
        <span class="mr-1">Name:</span>
        <span class="ml-1">{{ item.name }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-8">
        <span class="mr-1">Manufacturer ID:</span>
        <span class="ml-1">{{ item.mfr_id }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <span class="mr-1">Type:</span>
        <span class="ml-1">{{ capitalizeType }}</span>
      </div>
      <div class="form-group col-md-4">
        <span class="mr-1">Size:</span>
        <span class="ml-1">{{ item.size }}</span>
        <span class="ml-1">{{ item.unit }}</span>
      </div>
    </div>
    <div class="form-group">
      <span class="mr-1">Description:</span>
      <span class="ml-1">{{ item.desc }}</span>
    </div>
    <div class="form-group">
      <span class="mr-1">Note:</span>
      <span class="ml-1">{{ item.note }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TubeView",
  props: ["dbInfo", "selectedType", "item"],
  computed: {
    capitalizeType() {
      const word = this.item && this.item.type ? this.item.type : "";

      return word.length > 1
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : "";
    }
  }
};
</script>
