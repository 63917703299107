<template>
  <div>
    <div v-if="user" id="olabfaq">
      <article id="page-multicolumn" class="page-section text-center py-5">
        <header class="page-section-header container">
          <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
              <h2 class="page-section-title">{{ title }}</h2>
              <p class="page-section-text">
                This page contains the frequently asked questions ....
              </p>
              <div class="input-group my-3">
                <input
                  id="search-cls"
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  v-model="searchTerm"
                />
                <!-- <button class="btn btn-primary" type="button">
                  <font-awesome-icon icon="search" />
                </button> -->
              </div>
              <div>
                <OlabStatusObjAlerts
                  :statusObj="statusObj"
                  @emitStatusMessage="emitStatusMessage"
                  @emitErrorMessage="emitErrorMessage"
                />
              </div>
            </div>
          </div>
        </header>
        <section class="container">
          <div class="bootstrap-demo">
            <label class="checkbox-inline mx-2 my-2">
              <!-- class checkbox-inline to display checkbox inline -->
              <!-- input type checkbox -->
              <input type="checkbox" id="checkbox-chart" v-model="chartCheck" />
              Chart
            </label>
            <label class="checkbox-inline mx-2 my-2">
              <input
                type="checkbox"
                id="checkbox-database"
                v-model="databaseCheck"
              />
              Database
            </label>
            <label class="checkbox-inline mx-2 my-2">
              <input
                type="checkbox"
                id="checkbox-experiment"
                v-model="experimentCheck"
              />
              Experiment
            </label>
          </div>
          <!-- <div>
            {{ chartCheck }} - {{ databaseCheck }} - {{ experimentCheck }}
          </div> -->
        </section>
        <section class="container">
          <div>
            <FAQList
              :user="user"
              :dbInfo="dbInfo"
              :item="item"
              :items="searchItems"
              :statusObj="statusObj"
            />
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import OlabStatusObjAlerts from "@/components/OlabStatusObjAlerts.vue";
import FAQList from "@/components/FAQList.vue";
import { faqdata } from "@/assets/data/faq/faq-data";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "OlabFAQ",
  data() {
    return {
      title: "Frequently Asked Questions",
      chartCheck: true,
      databaseCheck: true,
      experimentCheck: true,
      searchTerm: "",
      filterKey: "all",
      item: null,
      faqdata,
      items: faqdata
    };
  },
  components: {
    OlabStatusObjAlerts,
    FAQList
    // FontAwesomeIcon
  },
  props: ["user", "dbInfo", "statusObj"],
  emits: ["setStatusMessage", "setErrorMessage"],
  computed: {
    filteredItems() {
      const matchList = [];
      if (this.chartCheck) {
        matchList.push("chart");
      }
      if (this.databaseCheck) {
        matchList.push("database");
      }
      if (this.experimentCheck) {
        matchList.push("experiment");
      }
      const resultedList = this.items.filter((item) =>
        matchList.includes(item.module)
      );
      return resultedList;
    },
    searchItems() {
      // console.log("searchItems - searchTerm = " + this.searchTerm);
      const lowercaseSearchTerm = this.searchTerm.toLowerCase();
      const resultedList = this.filteredItems.filter(
        (item) =>
          item.olab_id.toLowerCase().includes(lowercaseSearchTerm) ||
          item.tags.includes(lowercaseSearchTerm) ||
          item.question.toLowerCase().includes(lowercaseSearchTerm) ||
          item.answer.toLowerCase().includes(lowercaseSearchTerm)
      );
      return resultedList;
    }
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.$emit("setStatusMessage", statusMsg, statusProgress);
    },
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    }
    // doSearchTerm(sTerm, filterKey) {
    // console.log(
    //   "doSearchTerm - sTerm = " + sTerm + ", filterKey = " + filterKey
    // );
    // const resultedList = this.items.filter(
    //   (item) => item.question.includes(sTerm) || item.answer.includes(sTerm)
    // );
    // return resultedList;
    // }
  },
  watch: {
    // searchTerm() {
    //   console.log("Watch - searchTerm = " + this.searchTerm);
    //   this.doSearchTerm(this.searchTerm, this.filterKey);
    // }
  }
};
</script>
