<template>
  <div v-if="selectedType === 'culture'">
    <div class="form-group">
      <div
        v-if="localStatusObj.progress"
        class="spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
        {{ localStatusObj.errorMessage }}
      </span>
      <span v-if="localStatusObj.message" class="mx-1 text-info">
        {{ localStatusObj.message }}
      </span>
      <span class="font-weight-bold mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-5">
        <span class="font-weight-bold mr-1">Culture Type:</span>
        <span class="ml-1">{{ item.culture_type }}</span>
      </div>
      <div class="form-group col-lg-5">
        <span class="font-weight-bold mr-1">Culture Stage:</span>
        <span class="ml-1">{{ cultureStage }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-lg-5">
        <span class="font-weight-bold mr-1">Strain Souce Type:</span>
        <span class="ml-1">{{ strainSourceType }}</span>
      </div>
      <div class="form-group col-lg-5">
        <span class="font-weight-bold mr-1">Strain Confirmation Method:</span>
        <span class="ml-1">{{ strainConfirmationMethod }}</span>
      </div>
    </div>
    <div v-if="strain" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">Strain:</span>
      </label>
      <div class="table-responsive-lg">
        <table class="table">
          <thead>
            <th scope="col">OLAB ID</th>
            <th scope="col">Name</th>
            <th scope="col">GenoType</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ strain.olab_id }}</td>
              <td>{{ strain.name }}</td>
              <td>{{ strain.strain_genotype }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="media" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">Media:</span>
      </label>
      <div class="table-responsive-lg">
        <table class="table">
          <thead>
            <th scope="col">OLAB ID</th>
            <th scope="col">Name</th>
            <th scope="col">Volume</th>
            <th scope="col">Expiry Date</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ media.olab_id }}</td>
              <td>{{ media.name }}</td>
              <td>{{ media.volume }} {{ media.unit }}</td>
              <td>{{ mediaExpiryDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="container" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">{{ containerTypeName }}:</span>
      </label>
      <div class="table-responsive-lg">
        <table class="table">
          <thead>
            <th scope="col">OLAB ID</th>
            <th scope="col">Name</th>
            <th v-if="container.olab_type !== 'bioreactor'" scope="col">
              Type
            </th>
            <th scope="col">Manufacturer</th>
            <th v-if="container.olab_type !== 'plate'" scope="col">Size</th>
            <th v-else-if="container.dimension && container.type === 'round'">
              Dimension (D * H)
            </th>
            <th
              v-else-if="container.dimension && container.type === 'rectangle'"
            >
              Dimension (W * L * H)
            </th>
            <th v-if="container.olab_type === 'bioreactor'" scope="col">
              Temperature
            </th>
            <th v-if="container.olab_type === 'bioreactor'" scope="col">
              Speed
            </th>
            <th v-if="container.olab_type === 'bioreactor'" scope="col">
              Air(vvm)
            </th>
          </thead>
          <tbody>
            <tr>
              <td>{{ container.olab_id }}</td>
              <td>{{ container.name }}</td>
              <td v-if="container.olab_type !== 'bioreactor'">
                {{ container.type }}
              </td>
              <td>
                {{ container.manufacturer }}
              </td>
              <td v-if="container.olab_type !== 'plate'">
                {{ container.size }} {{ containerUnit }}
              </td>
              <td v-else-if="container.dimension && container.type === 'round'">
                {{ container.dimension.diameter }}
                {{ container.dimension.unit }} *
                {{ container.dimension.height }}
                {{ container.dimension.unit }}
              </td>
              <td
                v-else-if="
                  container.dimension && container.type === 'rectangle'
                "
              >
                {{ container.dimension.width }}
                {{ container.dimension.unit }} *
                {{ container.dimension.length }}
                {{ container.dimension.unit }} *
                {{ container.dimension.height }}
                {{ container.dimension.unit }}
              </td>
              <td v-if="container.olab_type === 'bioreactor'">
                {{ container.tm }} &#8451;
              </td>
              <td v-if="container.olab_type === 'bioreactor'">
                {{ container.rpm }} rpm
              </td>
              <td v-if="container.olab_type === 'bioreactor'">
                {{ container.air_vvm }} L/min
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="incubator" class="form-group">
      <label class="form-control-label">
        <span class="font-weight-bold">Incubator:</span>
      </label>
      <div class="table-responsive-lg">
        <table class="table">
          <thead>
            <th scope="col">OLAB ID</th>
            <th scope="col">Name</th>
            <th scope="col">Temperature</th>
            <th scope="col">Type</th>
            <th v-if="incubator.type === 'Shaker'" scope="col">Speed</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ incubator.olab_id }}</td>
              <td>{{ incubator.name }}</td>
              <td>{{ incubator.tm }} &#8451;</td>
              <td>{{ incubator.type }}</td>
              <td v-if="incubator.type === 'Shaker'">
                {{ incubator.rpm }} rpm
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="culture" class="form-group">
      <div class="form-group">
        <span class="font-weight-bold mr-1">Start Date [Time]:</span>
        <span class="ml-1">{{ formatStartDate }}</span>
      </div>
      <div class="form-group">
        <span class="font-weight-bold mr-1">End Date [Time]:</span>
        <span v-if="culture.end_date" class="ml-1">{{ formatEndDate }}</span>
      </div>
      <div class="form-group">
        <span class="font-weight-bold mr-1">Culture Time:</span>
        <span class="ml-1">{{ formatCultureTime }}</span>
      </div>

      <div v-if="container" class="form-row">
        <div v-if="container.olab_type !== 'plate'" class="form-group col-md-3">
          <span class="font-weight-bold mr-1">Initial OD (600nm):</span>
          <span class="ml-1">{{ culture.initial_OD }}</span>
        </div>
        <!-- If container.olab_type !== "bioreactor" show final OD -->
        <div
          v-if="
            container.olab_type !== 'bioreactor' &&
            container.olab_type !== 'plate'
          "
          class="form-group col-md-3"
        >
          <span class="font-weight-bold mr-1">Final OD (600nm):</span>
          <span class="ml-1">{{ culture.final_OD }}</span>
        </div>
        <div class="form-group col-md-3">
          <span class="font-weight-bold mr-1">Initial pH Level:</span>
          <span class="ml-1">{{ culture.initial_ph_level }}</span>
        </div>
        <!-- If container.olab_type !== "bioreactor" show final ph Level -->
        <div
          v-if="
            container.olab_type !== 'bioreactor' &&
            container.olab_type !== 'plate'
          "
          class="form-group col-md-3"
        >
          <span class="font-weight-bold mr-1">Final pH Level:</span>
          <span class="ml-1">{{ culture.final_ph_level }}</span>
        </div>
        <div class="form-group col-md-3">
          <span class="font-weight-bold mr-1">Initial Total Carbon:</span>
          <span class="ml-1">{{ culture.initial_total_carbon }}</span>
          <span v-if="container" class="ml-1">g</span>
        </div>
        <div class="form-group col-md-3">
          <span class="font-weight-bold mr-1">Initial Volume:</span>
          <span class="ml-1">{{ culture.initial_volume }}</span>
          <span class="ml-1">{{ containerUnit }}</span>
        </div>
        <div class="form-group col-md-3">
          <span class="font-weight-bold mr-1">Inoculum Size:</span>
          <span class="ml-1">{{ culture.inoculum_size }}</span>
          <span class="ml-1">{{ containerUnit }}</span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <span class="font-weight-bold mr-1">Note:</span>
      <span class="ml-1">{{ item.note }}</span>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
import { OlabUtils } from "@/olab/olabutils";
import { strainSourceTypeSettingModel } from "@/assets/data/strain_source_type_setting";
import { strainConfirmationMethodSettingModel } from "@/assets/data/strain_confirmation_method_setting";

export default {
  name: "CultureView",
  data() {
    return {
      strainSourceTypeSettingModel,
      strainConfirmationMethodSettingModel,
      culture: null,
      media: null,
      strain: null,
      container: null,
      incubator: null,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("** dbInfo =", this.dbInfo);
    // console.log("** selectedType =", this.selectedType);
    // Loading is needed for the first item
    await this.loadCultureData();
  },
  computed: {
    cultureStage() {
      return OlabUtils.getCultureStageName(this.item.culture_stage);
    },
    strainSourceType() {
      let res = "";
      if (this.strainSourceTypeSettingModel && this.item) {
        res = this.strainSourceTypeSettingModel.find(
          (tt) => tt.key_name === this.item.strain_source_type
        );
        res = res ? res.display_name : "";
      }
      return res;
    },
    strainConfirmationMethod() {
      let res = "";
      if (this.strainConfirmationMethodSettingModel && this.culture) {
        res = this.strainConfirmationMethodSettingModel.find(
          (tt) => tt.key_name === this.culture.strain_confirmation_method
        );
        res = res ? res.display_name : "";
      }
      return res;
    },
    mediaExpiryDate() {
      let dateStr = "";
      if (this.media && this.media.expire_date) {
        const mDate = OlabDate.createDateObjWithISOString(
          this.media.expire_date
        );
        // Show local time
        dateStr = OlabDate.formatYMD(mDate, false);
      }
      return dateStr;
    },
    formatStartDate() {
      const startDate = OlabDate.createDateObjWithISOString(
        this.culture.start_date
      );
      return OlabDate.formatYMDHM(startDate, false);
    },
    formatEndDate() {
      const endDate = OlabDate.createDateObjWithISOString(
        this.culture.end_date
      );
      return OlabDate.formatYMDHM(endDate, false);
    },
    containerTypeName() {
      const cType = this.container ? this.container.olab_type : "No Container";
      return cType[0].toUpperCase() + cType.slice(1);
    },
    formatCultureTime() {
      // cultureTime is in minutes
      const cultureTime = this.computeCultureTime();
      return OlabDate.formatDiffTime(cultureTime);
    },
    containerUnit() {
      let sym = "--";
      if (this.container) {
        if (this.container && this.container.olab_type === "plate") {
          sym = "ml";
        } else {
          sym = OlabUtils.getUnitSym(this.container.unit);
        }
      }
      return sym;
    }
  },
  methods: {
    // Computed culture time in minutes
    computeCultureTime() {
      let time = 0;
      const clt = this.culture;
      if (clt && clt.start_date && clt.end_date) {
        const sDate = OlabDate.createDateObjWithISOString(clt.start_date);
        const eDate = OlabDate.createDateObjWithISOString(clt.end_date);
        // Convert milliseconds to minutes
        time = OlabDate.computeDiffTime(sDate, eDate);
      }
      return time;
    },
    async loadCultureData() {
      if (this.item && this.item.olab_type === "culture") {
        const itemData = await OlabAxios.getCultureData(
          this.item,
          this.localStatusObj
        );
        // console.log("itemData =", itemData);
        this.culture = itemData.culture;
        this.media = itemData.media;
        this.strain = itemData.strain;
        this.container = this.culture ? this.culture.container : null;
        this.incubator = this.culture ? this.culture.incubator : null;
      }
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadCultureData();
    },
    "item.updatedAt"() {
      // console.log("watch: item.updatedAt: ", this.item.updatedAt);
      this.loadCultureData();
    }
  }
};
</script>
