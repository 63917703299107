<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'recipe'">
    <div class="card mb-3">
      <div class="card-header">
        <form v-on:submit.prevent="onSubmit">
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="inp-edit-recipe-name">Recipe Name</label>
                <input
                  name="Recipe Name"
                  type="text"
                  placeholder="Recipe Name"
                  class="form-control text-align-left"
                  v-bind:class="validState(item.name)"
                  v-model="item.name"
                  id="inp-edit-recipe-name"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="inp-edit-volume">Volume</label>
                <div class="form-inline">
                  <input
                    name="Volume"
                    type="number"
                    min="0"
                    placeholder="Vol"
                    class="mr-1 form-control"
                    v-bind:class="isGreaterThanZero(item.volume)"
                    style="width: 150px; text-align: center"
                    v-model="item.volume"
                    @keyup="item.volume = clampPostiveValue(item.volume)"
                    id="inp-edit-volume"
                  />
                  <select
                    class="form-control text-light bg-secondary"
                    v-model="item.unit"
                  >
                    <option>mL</option>
                    <option>L</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-body">
        <h4>Ingredients</h4>
        <form
          class="form-inline"
          v-for="(ingredient, k) in item.ingredients"
          v-bind:key="k"
          v-on:submit.prevent="onSubmit"
        >
          <button class="btn">
            <font-awesome-icon
              class="text-warning"
              icon="trash-alt"
              v-on:click="deleteRow(k)"
            >
              &nbsp;
            </font-awesome-icon>
          </button>
          <input
            type="number"
            min="0"
            placeholder="Qty"
            class="form-control"
            v-bind:class="isGreaterThanZero(item.ingredients[k].qty)"
            style="width: 150px; text-align: center"
            v-model="ingredient.qty"
            @keyup="ingredient.qty = clampPostiveValue(ingredient.qty)"
          />
          <select
            class="form-control mx-2 text-light bg-secondary"
            v-model="ingredient.unit"
          >
            <option>mL</option>
            <option>L</option>
            <option>mg</option>
            <option>g</option>
          </select>
          <!-- TODO: to do on the fly search on the Chemical database -->
          <select
            class="form-control"
            v-bind:class="validState(item.ingredients[k].name)"
            v-model="item.ingredients[k].chem_id"
          >
            <option disabled>Select ...</option>
            <option
              v-for="(chemical, j) in chemicals"
              :value="chemicals[j].olab_id"
              v-bind:key="chemicals[j].olab_id"
            >
              {{ chemical.name }}, {{ chemical.olab_id }}
            </option>
          </select>
          <!-- <input
            type="text"
            placeholder="Substance Name"
            class="form-control"
            v-bind:class="validState(item.ingredients[k].name)"
            v-model="ingredient.name"
          /> -->
        </form>
        <button class="btn">
          <font-awesome-icon
            class="mr-1 text-warning"
            icon="plus-square"
            v-on:click="addNewRow"
          >
            &nbsp;
          </font-awesome-icon>
        </button>
        <div class="pb-3">
          <span>Adjust final volume to</span>
          <span class="h5 text-primary bg-light mx-1"
            >{{ item.volume }} {{ item.unit }}</span
          >
          <span>with Milli-Q Water</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RecipeEdit",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      chemicals: []
    };
  },
  components: {
    FontAwesomeIcon
  },
  props: ["user", "dbInfo", "statusObj", "selectedType", "item"],
  async mounted() {
    // console.log("Recipe Edit mounted ...");
    const searchBody = OlabSearch.createSearchBody("chemical", "", "name");
    //TODO: need to assign to big number, need proper constant
    searchBody.limit = OlabAxios.MAX_LIMIT;
    this.chemicals = await OlabAxios.getChemicals(searchBody, this.statusObj);
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    isGreaterThanZero(fieldName) {
      return OlabSanity.isGreaterThanZero(fieldName);
    },
    clampPostiveValue(value) {
      if (!isNaN(value)) {
        value = value < 0 ? 0 : value;
        return value;
      }
      return 0;
    },
    // The addNewRow and deleteRow is to add or delete ingredient in the recipes formula
    addNewRow() {
      OlabItem.addIngredients(this.item, {
        qty: "",
        unit: "g",
        name: "",
        chem_id: ""
      });
      console.log("add new row");
    },
    deleteRow(index) {
      OlabItem.deleteIngredients(this.item, index);
      // var idx = index;
      // console.log(idx, index);
      // if (idx > -1) {
      //   console.log("recipe index number", index);
      //   OlabItem.item.ingredients.splice(idx, 1);
      // }
    }
  }
};
</script>
