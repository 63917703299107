export const prodDataCfg1Model = [
  {
    key_name: "age",
    base_unit: "hour",
    display_name: "Age (h)"
  },
  {
    key_name: "od_600nm",
    base_unit: "no_unit",
    display_name: "OD (600nm)"
  },
  {
    key_name: "product_conc",
    base_unit: "gram/liter",
    display_name: "Product conc. (g/L)"
  },
  {
    key_name: "pcw",
    base_unit: "percent",
    display_name: "PCW (%)"
  },
  {
    key_name: "increased_vol",
    base_unit: "milliliter",
    display_name: "Increased Volume (ml)"
  },
  {
    key_name: "fed_carbon",
    base_unit: "gram",
    display_name: "Fed carbon (g)"
  },
  {
    key_name: "carbon_conc_measured",
    base_unit: "gram/liter",
    display_name: "Carbon conc. measured (g/L)"
  },
  {
    key_name: "by_products",
    base_unit: "gram/liter",
    display_name: "By-products (g/L)"
  },
  {
    key_name: "ph_control",
    base_unit: "no_unit",
    display_name: "pH Control"
  },
  {
    key_name: "naoh20pct_used",
    base_unit: "liter",
    display_name: "20%NaOH used (L)"
  },
  {
    key_name: "antifoam50pct_used",
    base_unit: "milliliter",
    display_name: "50% Antifoam used (ml)"
  },
  {
    key_name: "broth_out",
    base_unit: "liter",
    display_name: "B.O (L)"
  },
  {
    key_name: "input_output",
    base_unit: "percent",
    display_name: "I/O (%)"
  },
  {
    key_name: "total_accumulated_carbon",
    base_unit: "gram",
    display_name: "Total accumulated carbon (g)"
  },
  {
    key_name: "carbon_in_media",
    base_unit: "gram",
    display_name: "Carbon in the media (g)"
  },
  {
    key_name: "total_consumed_carbon",
    base_unit: "gram",
    display_name: "Total consumed carbon (g)"
  },
  {
    key_name: "consumed_carbon_bw_sampling",
    base_unit: "gram",
    display_name: "Consumed carbon between sampling (g)"
  },
  {
    key_name: "carbon_consumed_rate",
    base_unit: "gram/hour",
    display_name: "Carbon consumed rate (g/hr)"
  },
  {
    key_name: "product_adjusted",
    base_unit: "gram",
    display_name: "Product adjusted (g)"
  },
  {
    key_name: "production_rate",
    base_unit: "milligram/liter/hour",
    display_name: "Production rate (mg/L/hr)"
  },
  {
    key_name: "yield",
    base_unit: "milligram/gram",
    display_name: "Yield (mg/g)"
  },
  {
    key_name: "yield_12h",
    base_unit: "milligram/gram",
    display_name: "Yield for 12hrs interval (mg/g)"
  }
];
