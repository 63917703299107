<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'branch'">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="inp-add-name" class="form-control-label">
          <span>Name:</span>
        </label>
        <input
          id="inp-add-name"
          class="form-control"
          v-bind:class="validState(item.name)"
          type="text"
          placeholder="Name"
          v-model="item.name"
        />
        <!-- <div class="invalid-feedback">{{ invalidFB.name }}</div> -->
      </div>
      <div>
        <OlabStatusObjDialogs
          :statusObj="localStatusObj"
          :showStatusObjDialog="showStatusObjDialog"
          @emitStatusMessage="emitStatusMessage"
          @emitErrorMessage="emitErrorMessage"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-10">
          <label for="sel-project">Project:</label>
          <span v-if="project == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-project"
              v-model="project"
              :options="projects"
              :loading="isLoadingProj"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="projectInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getProjects"
            />
          </div>
          <!-- <p v-if="project">
            {{ project.olab_id }}
          </p> -->
        </div>
      </div>
      <div>
        <div class="form-row">
          <div class="form-group col-md-10">
            <div class="form-group">
              <label for="sel-users">Select Members:</label>
              <div class="mb-2 olabtest">
                <VueMultiselect
                  id="sel-users"
                  v-model="selUsers"
                  :options="userChoices"
                  :loading="isLoadingProjMembers"
                  :internal-search="internalSearchMembers"
                  :multiple="true"
                  :close-on-select="true"
                  :options-limit="10"
                  :custom-label="nameInfo"
                  placeholder="Type to search"
                  label="olab_id"
                  track-by="olab_id"
                  @search-change="loadProjMbrs"
                />
              </div>
              <!-- <p v-if="selUsers && selUsers.length > 0">
                {{ computeSelusers }}
              </p> -->
            </div>
          </div>
        </div>

        <div class="py-1 text-wrap">
          <span class="mr-1">Members:</span>
        </div>
        <div v-if="item.members" class="py-1 text-wrap">
          <ol>
            <!-- Loop through members and display them in the ordered list -->
            <li v-for="(mbr, index) in item.members" :key="index">
              {{ mbr }}
            </li>
          </ol>
        </div>
      </div>
      <div class="form-group">
        <label for="inp-add-start-date" class="form-control-label">
          Start Date:
        </label>
        <input
          class="form-control"
          v-bind:class="validState(item.start_date)"
          type="date"
          v-model="item.start_date"
          id="inp-add-start-date"
        />
        <!-- <div class="invalid-feedback">{{invalidFB.startDate}}</div> -->
      </div>
      <div class="form-group">
        <label for="inp-add-end-date" class="form-control-label"
          >End Date:</label
        >
        <input
          class="form-control"
          type="date"
          v-model="item.end_date"
          id="inp-add-end-date"
        />
      </div>
      <div class="form-group">
        <label for="inp-add-desc" class="form-control-label"
          >Description:</label
        >
        <textarea
          id="inp-add-desc"
          class="form-control"
          v-model="item.desc"
        ></textarea>
      </div>
      <div class="form-group float-right">Created by: {{ item.creator }}</div>
      <!-- .form-group -->
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";
import OlabStatusObjDialogs from "@/components/OlabStatusObjDialogs.vue";

export default {
  name: "BranchAdd",
  data() {
    return {
      selUsers: [],
      userChoices: [],
      invalidFB: OlabItem.getInvalidFeedbacks(),
      project: null,
      projects: [],
      isLoadingProj: false,
      isLoadingProjMembers: false,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      },
      showStatusObjDialog: false
    };
  },
  components: {
    VueMultiselect,
    OlabStatusObjDialogs
  },
  props: ["user", "dbInfo", "selectedType", "item", "resetItem"],
  emits: ["clearResetItem"],
  async mounted() {
    await this.getProjects("");
  },
  computed: {
    computeSelusers() {
      const csfUsrs = [];
      if (this.selUsers) {
        this.selUsers.forEach((sf) => {
          const csf = { name: sf.name, olab_id: sf.olab_id };
          csfUsrs.push(csf);
        });
      }
      return csfUsrs;
    },
    internalSearchMembers() {
      let iSearchState = true;
      if (
        this.project &&
        this.project.ra_locks &&
        this.project.ra_locks.public
      ) {
        iSearchState = false;
      }
      return iSearchState;
    }
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.localStatusObj.message = statusMsg;
      this.localStatusObj.progress = statusProgress;
      this.showStatusObjDialog = this.localStatusObj.message != null;
    },
    emitErrorMessage(errMsg) {
      this.localStatusObj.errorMessage = errMsg;
      this.showStatusObjDialog = this.localStatusObj.errorMessage != null;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ name, email }) {
      return `${name}: [${email}]`;
    },
    projectInfo({ olab_id, name }) {
      return `${olab_id}: [${name}]`;
    },
    async getProjects(query) {
      // console.log("getProjects: query = ", query);
      this.isLoadingProj = true;
      const searchBody = OlabSearch.createSearchBody("project", query, "merge");
      this.projects = await OlabAxios.getProjects(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingProj = false;
    },
    async loadProjMembers(query, proj) {
      // console.log("loadProjMembers: proj =", proj);
      this.isLoadingProjMembers = true;
      if (proj && !proj.ra_locks.public) {
        this.userChoices = await OlabAxios.getProjectMembers(
          proj.olab_id,
          this.localStatusObj
        );
      } else {
        // Share within Division case
        // TODO: Need to restrict search to within proj's division
        const searchBody = OlabSearch.createSearchBody("user", query, "name");
        this.userChoices = await OlabAxios.getUsers(
          searchBody,
          this.localStatusObj
        );
      }
      this.isLoadingProjMembers = false;
      // console.log("userChoices =", this.userChoices);
    },
    async loadProjMbrs(query) {
      await this.loadProjMembers(query, this.project);
    }
  },
  watch: {
    project() {
      this.item.setProject(this.project);
      this.loadProjMembers("", this.project);
    },
    selUsers() {
      // console.log("watch: selUsers =", this.selUsers);
      const mbrs = [];
      if (this.selUsers) {
        this.selUsers.forEach((sUsr) => {
          mbrs.push(sUsr.email);
        });
      }
      this.item.setMembers(mbrs);
      // console.log("members =", this.item.members);
    },
    resetItem() {
      // Clean previously set fields once add is executed
      // console.log("BranchAdd:resetItem() - item =", this.item);
      this.selUsers = [];
      this.userChoices = [];
      this.project = null;
      this.projects = [];
      this.$emit("clearResetItem");
    }
  }
};
</script>
