<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex align-items-center"
        v-for="cht in charts"
        :key="cht.olab_id"
      >
        <div class="container">
          <div class="row">
            <div v-if="accessOK(user, cht)" class="col-1 m-auto">
              <div
                v-if="selectedChart && cht.olab_id === selectedChart.olab_id"
              >
                <button
                  type="button"
                  class="btn btn-success"
                  v-on:click="setSelectedChart(cht)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="setSelectedChart(cht)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
            </div>
            <div v-else class="col-1 m-auto">
              <!-- NOP -->
            </div>

            <div
              class="col-11 col-sm-10 mt-2 align-self-center text-center text-wrap"
            >
              <a
                class="h5 text-info"
                data-toggle="modal"
                href="#chartInfo"
                v-on:click="setViewChart(cht)"
                data-backdrop="static"
                data-keyboard="false"
              >
                {{ cht.name }}</a
              >
              <div v-if="cht.control && cht.control.type" class="pt-1">
                <span>{{
                  cht.control.type.charAt(0).toUpperCase() +
                  cht.control.type.slice(1)
                }}</span>
                <span class="ml-3">{{ cht.project_id }}</span>
              </div>
              <div class="pt-1">
                <span>{{ cht.olab_id }}</span>
                <span class="ml-3">{{ cht.creator }}</span>
              </div>
              <div class="pt-1">
                <small>{{ cht.note }}</small>
              </div>
            </div>

            <!-- Modal: chartInfo -->
            <div v-if="viewChart != null" class="modal fade" id="chartInfo">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="h5 text-dark">{{ viewChart.olab_id }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <!-- experiment -->
                    <ChartView
                      :dbInfo="dbInfo"
                      :selectedType="selectedType"
                      :item="viewChart"
                    />
                  </div>
                  <div class="container">
                    <div class="form-row justify-content-between font-italic">
                      <div class="col-md-6">
                        Updated at: {{ formatTimeStamp }}
                      </div>
                      <div class="col-md-6">
                        Created by: {{ viewChart.creator }}
                      </div>
                    </div>
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #chartInfo -->
          </div>
          <!-- UI for Input Experiment configs -->
          <div
            v-if="selectedChart && cht.olab_id === selectedChart.olab_id"
            class="row my-2"
          >
            <div class="container">
              <!-- TODO: Replace with ChartEdit -->
              <ChartControl
                :user="user"
                :dbInfo="dbInfo"
                :selectedType="selectedType"
                :chart="selectedChart"
                :charts="charts"
                :statusObj="statusObj"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ChartControl from "@/components/charts/ChartControl";
import ChartView from "@/components/forms/ChartView";
import { OlabDate } from "@/olab/olabdate";

export default {
  name: "ChartList",
  data() {
    return {
      selectedChart: null,
      viewChart: null
    };
  },
  props: ["user", "dbInfo", "selectedType", "chart", "charts", "statusObj"],
  components: {
    FontAwesomeIcon,
    ChartControl,
    ChartView
  },
  computed: {
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.viewChart) {
        const createdAt = this.viewChart.createdAt;
        const updatedAt = this.viewChart.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    }
  },
  methods: {
    accessOK(user, item) {
      let result = false;
      if (user === null || item === null) {
        result = false;
      }
      // Restricted to User Only access
      result = item.accessUserOnlyOK(user);
      return result;
    },
    setSelectedChart(cht) {
      if (
        cht &&
        this.selectedChart &&
        this.selectedChart.olab_id === cht.olab_id
      ) {
        this.registerChart(null);
      } else {
        this.registerChart(cht);
      }
    },
    registerChart(cht) {
      if (cht) {
        this.chart.set(cht);
        this.selectedChart = cht;
      } else {
        this.selectedChart = null;
      }
    },
    setViewChart(cht) {
      // console.log("setViewChart =", cht);
      this.viewChart = cht;
    }
  }
};
</script>
