<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        class="list-group-item d-flex align-items-center"
        v-for="exp in exprts"
        :key="exp.olab_id"
      >
        <div class="container">
          <div class="row">
            <div v-if="accessOK(user, exp)" class="col-1 m-auto">
              <div
                v-if="selectedExprt && exp.olab_id === selectedExprt.olab_id"
              >
                <button
                  type="button"
                  class="btn btn-success"
                  v-on:click="setSelectedExprt(exp)"
                >
                  <font-awesome-icon icon="check-square" />
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  v-on:click="setSelectedExprt(exp)"
                >
                  <font-awesome-icon icon="square" />
                </button>
              </div>
            </div>
            <div v-else class="col-1 m-auto">
              <!-- NOP -->
            </div>

            <div
              class="col-11 col-sm-10 mt-2 align-self-center text-center text-wrap"
            >
              <a
                class="h5 text-info"
                data-toggle="modal"
                href="#experimentInfo"
                v-on:click="setViewExprt(exp)"
                data-backdrop="static"
                data-keyboard="false"
              >
                {{ exp.name }}</a
              >
              <div v-if="exp.hypotheses && exp.hypotheses[0]" class="pt-1">
                <span>{{ exp.hypotheses[0].statement }}</span>
              </div>
              <div class="pt-1">
                <span>{{ exp.olab_id }}</span>
                <span class="ml-3">{{ exp.creator }}</span>
              </div>
              <div class="pt-1">
                <small>{{ exp.note }}</small>
              </div>
            </div>

            <!-- Modal: experimentInfo -->
            <div
              v-if="viewExprt != null"
              class="modal fade"
              id="experimentInfo"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      OLAB ID:
                      <span class="h5 text-dark">{{ viewExprt.olab_id }}</span>
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <!-- .modal-header -->
                  <div class="modal-body">
                    <!-- experiment -->
                    <ExperimentView
                      :dbInfo="dbInfo"
                      :selectedType="selectedType"
                      :item="viewExprt"
                    />
                  </div>
                  <div class="container">
                    <div class="form-row justify-content-between font-italic">
                      <div class="col-md-6">
                        Updated at: {{ formatTimeStamp }}
                      </div>
                      <div class="col-md-6">
                        Created by: {{ viewExprt.creator }}
                      </div>
                    </div>
                  </div>
                  <!-- .modal-body -->
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <!-- .modal-footer -->
                </div>
                <!-- .modal-content -->
              </div>
              <!-- .modal-dialog .modal-lg-->
            </div>
            <!-- .modal fade #experimentInfo -->
          </div>
          <!-- UI for Input Experiment configs -->
          <div
            v-if="selectedExprt && exp.olab_id === selectedExprt.olab_id"
            class="row my-2"
          >
            <div class="container">
              <ExprtDesign
                :user="user"
                :dbInfo="dbInfo"
                :selectedType="selectedType"
                :selectedExprt="selectedExprt"
                :exprts="exprts"
                :statusObj="statusObj"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ExprtDesign from "@/components/exprts/ExprtDesign";
import ExperimentView from "@/components/forms/ExperimentView";
import { OlabDate } from "@/olab/olabdate";

export default {
  name: "ExperimentList",
  data() {
    return {
      selectedExprt: null,
      viewExprt: null
    };
  },
  props: ["user", "dbInfo", "selectedType", "exprt", "exprts", "statusObj"],
  components: {
    FontAwesomeIcon,
    ExprtDesign,
    ExperimentView
  },
  computed: {
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.viewExprt) {
        const createdAt = this.viewExprt.createdAt;
        const updatedAt = this.viewExprt.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    }
  },
  methods: {
    accessOK(user, item) {
      let result = false;
      if (user === null || item === null) {
        result = false;
      }
      // Restricted to User Only access
      result = item.accessUserOnlyOK(user);
      return result;
    },
    setSelectedExprt(exp) {
      if (
        exp &&
        this.selectedExprt &&
        this.selectedExprt.olab_id === exp.olab_id
      ) {
        this.registerExprt(null);
      } else {
        this.registerExprt(exp);
      }
    },
    registerExprt(exp) {
      if (exp) {
        this.exprt.set(exp);
        this.selectedExprt = exp;
      } else {
        this.selectedExprt = null;
      }
    },
    setViewExprt(exp) {
      // console.log("setViewExprt =", exp);
      this.viewExprt = exp;
    }
  }
};
</script>
