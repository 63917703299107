export const strainConfirmationMethodSettingModel = [
  {
    key_name: "16s_rrna",
    display_name: "16s rRNA"
  },
  {
    key_name: "cpcr",
    display_name: "cPCR"
  },
  {
    key_name: "sequencing",
    display_name: "Sequencing"
  },
  {
    key_name: "none",
    display_name: "None"
  }
];
