<template>
  <div v-if="selectedType === 'media' && item">
    <div class="form-group">
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div class="form-group form-row">
      <div class="col-md-5">
        <label class="form-control-label">
          <span>Media: {{ item.volume }} {{ item.unit }} (Prepared)</span>
        </label>
      </div>
      <div class="col-md-5">
        <span class="mr-1">Expire Date:</span>
        <span class="ml-1">{{ item.expire_date }}</span>
      </div>
    </div>

    <div v-if="recipe" class="form-group">
      <!-- <div class="form-control-label">
        <span class="font-weight-bold">
          Media: {{ item.volume }} {{ item.unit }} (In Stock)
        </span>
      </div> -->
      <table class="table">
        <thead>
          <th scope="col">Substance</th>
          <th scope="col">
            Recipe <br />
            {{ recipe.volume }}
            {{ recipe.unit }}
          </th>
          <th scope="col">
            Media <br />
            {{ item.volume }} {{ item.unit }}
          </th>
        </thead>
        <tbody>
          <tr v-for="ingredient in recipe.ingredients" v-bind:key="ingredient">
            <td>{{ ingredient.name }}</td>
            <td>{{ ingredient.qty }} {{ ingredient.unit }}</td>
            <td>{{ computeSV(ingredient) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-group text-wrap">
      <span class="mr-1">Purpose:</span>
      <span class="ml-1">{{ item.purpose }}</span>
    </div>

    <div class="form-group">
      <span class="mr-1">Note:</span>
      <span class="ml-1">{{ item.note }}</span>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabUtils } from "@/olab/olabutils";

export default {
  name: "MediaView",
  data() {
    return {
      recipe: null,
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("** dbInfo =", this.dbInfo);
    // console.log("** selectedType =", this.selectedType);
    // Loading is needed for the first item
    this.loadRecipe();
  },
  methods: {
    async loadRecipe() {
      // console.log("(1) recipe =", this.recipe);
      if (this.item && this.item.olab_type === "media") {
        this.recipe = await OlabAxios.getRecipe(
          this.item.recipe_id,
          this.localStatusObj
        );
      }
      // console.log("(2) recipe =", this.recipe);
    },
    computeSV(ingredient) {
      let sVal = 0;
      let unit = ingredient.unit;
      if (this.item.volume > 0) {
        sVal = (ingredient.qty * this.item.volume) / this.recipe.volume;
        sVal = OlabUtils.fixFloatTo(sVal, 4);
      }
      return `${sVal} ${unit}`;
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadRecipe();
    }
  }
};
</script>
