<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'user'">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="inp-add-name" class="form-control-label"> Name:</label>
        <input
          class="form-control"
          v-bind:class="validState(item.name)"
          type="text"
          placeholder="Name"
          v-model="item.name"
          id="inp-add-name"
        />
        <!-- <div class="invalid-feedback">{{ invalidFB.name }}</div> -->
      </div>
      <div class="form-group">
        <label for="inp-add-email" class="form-control-label">Email:</label>
        <input
          class="form-control"
          v-bind:class="validEmailState(item.email)"
          type="email"
          placeholder="Email Address"
          v-model="item.email"
          id="inp-add-email"
        />
        <!-- <div class="invalid-feedback">{{ invalidFB.email }}</div> -->
      </div>
      <div class="form-group">
        <label for="inp-add-role" class="form-control-label"
          >System Role:</label
        >
        <select
          class="form-control"
          v-bind:class="validState(item.role)"
          v-model="item.role"
          id="inp-add-role"
        >
          <option disabled value="null">Please select one</option>
          <option value="admin">Admin</option>
          <option value="lead">Lead</option>
          <option value="user">User</option>
        </select>
      </div>
      <div v-if="divisions" class="form-group">
        <label for="inp-add-division" class="form-control-label"
          >Division:</label
        >
        <select
          class="form-control"
          v-bind:class="validState(item.division)"
          v-model="item.division"
          id="inp-add-division"
        >
          <option
            v-for="division in divisions"
            :value="division.key_name"
            :key="division.key_name"
            :disabled="division.disabled"
          >
            {{ division.display_name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="inp-add-password" class="form-control-label"
          >Password:</label
        >
        <div class="form-row">
          <section class="col-sm-6">
            <input
              class="form-control"
              v-bind:class="validPWState(item.passwd)"
              type="password"
              required
              placeholder="Password"
              v-model="item.passwd"
              id="inp-add-password"
            />
            <div class="invalid-feedback">
              {{ invalidFB.password }}
            </div>
          </section>
          <section class="col-sm-6">
            <input
              class="form-control"
              v-bind:class="validCPWState(item.passwd, item.confirmed_passwd)"
              type="password"
              required
              placeholder="Repeat Password"
              v-model="item.confirmed_passwd"
            />
            <div class="invalid-feedback">
              {{ invalidFB.password_confirmed }}
            </div>
          </section>
        </div>
      </div>
      <div class="form-group float-right">Created by: {{ item.creator }}</div>
      <!-- .form-group -->
    </form>
  </div>
</template>

<script>
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";

export default {
  name: "UserAdd",
  data() {
    return {
      divisions: null,
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: ["user", "dbInfo", "selectedType", "item"],
  mounted() {
    const dbI = this.dbInfo;
    // Setup divisions options
    if (dbI && dbI.divisions && dbI.divisions.length > 0) {
      this.divisions = [
        {
          key_name: null,
          display_name: "Please select one",
          disabled: true
        }
      ];
      dbI.divisions.forEach((dv) => {
        this.divisions.push(dv);
      });
    } else {
      this.divisions = null;
    }
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    validPWState(pw) {
      let result = "is-invalid";
      if (pw !== null && pw !== "") {
        result = OlabSanity.getValidPWState(pw);
      }
      return result;
    },
    validCPWState(pw, cpw) {
      let result = "is-invalid";
      if (pw !== null && cpw !== null) {
        result = OlabSanity.getValidCPWState(pw, cpw);
      }
      return result;
    },
    validEmailState(em) {
      // console.log("validEmailState: email = " + em);
      return OlabSanity.validateEmail(em) ? "is-valid" : "is-invalid";
    }
  }
};
</script>
