<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-32 0 512 512" width="200">
      <g transform="translate(150,150)">
        <path
          fill="#eeeeee"
          transform="scale(0.5)"
          d="M.1 494.1c-1.1 9.5 6.3 17.8 15.9 17.8l32.3.1c8.1 0 14.9-5.9 16-13.9.7-4.9 1.8-11.1 3.4-18.1H380c1.6 6.9 2.9 13.2 3.5 18.1 1.1 8 7.9 14 16 13.9l32.3-.1c9.6 0 17.1-8.3 15.9-17.8-4.6-37.9-25.6-129-118.9-207.7-17.6 12.4-37.1 24.2-58.5 35.4 6.2 4.6 11.4 9.4 17 14.2H159.7c21.3-18.1 47-35.6 78.7-51.4C410.5 199.1 442.1 65.8 447.9 17.9 449 8.4 441.6.1 432 .1L399.6 0c-8.1 0-14.9 5.9-16 13.9-.7 4.9-1.8 11.1-3.4 18.1H67.8c-1.6-7-2.7-13.1-3.4-18.1-1.1-8-7.9-14-16-13.9L16.1.1C6.5.1-1 8.4.1 17.9 5.3 60.8 31.4 171.8 160 256 31.5 340.2 5.3 451.2.1 494.1zM224 219.6c-25.1-13.7-46.4-28.4-64.3-43.6h128.5c-17.8 15.2-39.1 30-64.2 43.6zM355.1 96c-5.8 10.4-12.8 21.1-21 32H114c-8.3-10.9-15.3-21.6-21-32h262.1zM92.9 416c5.8-10.4 12.8-21.1 21-32h219.4c8.3 10.9 15.4 21.6 21.2 32H92.9z"
        />
      </g>
      <text
        :x="textPosX"
        :y="textPosY"
        fill="#f80"
        transform="rotate(-47,175,400)"
        style="
          font-size: 45px;
          font-weight: normal;
          text-align: start;
          text-anchor: start;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 1px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
          font-family: Arial;
        "
        id="olab-text"
        xml:space="preserve"
      >
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "OlabDNASVGGen",
  props: ["name", "textPosX", "textPosY"]
};
</script>
