<template>
  <div class="modal fade" id="viewItem">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            OLAB ID:
            <span class="h5 text-dark">{{ item.olab_id }}</span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- .modal-header -->
        <div class="modal-body">
          <BioreactorView
            v-if="selectedType === 'bioreactor'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <BranchView
            v-if="selectedType === 'branch'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ChartView
            v-if="selectedType === 'chart'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <CultureView
            v-if="selectedType === 'culture'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ExperimentView
            v-if="selectedType === 'experiment'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <FlaskView
            v-if="selectedType === 'flask'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <IncubatorView
            v-if="selectedType === 'incubator'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ManufacturerView
            v-if="selectedType === 'manufacturer'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <MediaView
            v-if="selectedType === 'media'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ChemicalView
            v-if="selectedType === 'chemical'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <RecipeView
            v-if="selectedType === 'recipe'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <UserView
            v-if="selectedType === 'user'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <PlateView
            v-if="selectedType === 'plate'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <ProjectView
            v-if="selectedType === 'project'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <StrainView
            v-if="selectedType === 'strain'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
          <TubeView
            v-if="selectedType === 'tube'"
            :dbInfo="dbInfo"
            :selectedType="selectedType"
            :item="item"
          />
        </div>
        <div class="container">
          <div class="form-row justify-content-between font-italic">
            <div class="col-md-6">Updated at: {{ formatTimeStamp }}</div>
            <div class="col-md-6">Created by: {{ item.creator }}</div>
          </div>
        </div>
        <!-- .modal-body -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
        <!-- .modal-footer -->
      </div>
      <!-- .modal-content -->
    </div>
    <!-- .modal-dialog .modal-lg-->
  </div>
  <!-- </div> -->
  <!-- .modal fade #viewItem -->
</template>

<script>
import BioreactorView from "@/components/forms/BioreactorView.vue";
import BranchView from "@/components/forms/BranchView.vue";
import ChartView from "@/components/forms/ChartView.vue";
import ExperimentView from "@/components/forms/ExperimentView.vue";
import FlaskView from "@/components/forms/FlaskView.vue";
import IncubatorView from "@/components/forms/IncubatorView.vue";
import ManufacturerView from "@/components/forms/ManufacturerView.vue";
import MediaView from "@/components/forms/MediaView.vue";
import ChemicalView from "@/components/forms/ChemicalView.vue";
import RecipeView from "@/components/forms/RecipeView.vue";
import CultureView from "@/components/forms/CultureView.vue";
import PlateView from "@/components/forms/PlateView.vue";
import ProjectView from "@/components/forms/ProjectView.vue";
import UserView from "@/components/forms/UserView.vue";
import StrainView from "@/components/forms/StrainView.vue";
import TubeView from "@/components/forms/TubeView.vue";
import { OlabDate } from "@/olab/olabdate";

export default {
  name: "ViewForm",
  props: ["dbInfo", "selectedType", "item"],
  components: {
    BioreactorView,
    BranchView,
    ChartView,
    ExperimentView,
    FlaskView,
    IncubatorView,
    ManufacturerView,
    MediaView,
    ChemicalView,
    RecipeView,
    CultureView,
    PlateView,
    ProjectView,
    UserView,
    StrainView,
    TubeView
  },
  // mounted() {
  //   console.log("*** dbInfo =", this.dbInfo);
  //   console.log("*** selectedType =", this.selectedType);
  //   console.log("*** item =", this.item);
  // },
  computed: {
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.item) {
        const createdAt = this.item.createdAt;
        const updatedAt = this.item.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    }
  }
};
</script>
