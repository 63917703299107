<template>
  <div>
    <div class="card card-body">
      <h5 class="card-title">Preview</h5>
      <div class="card-text">
        <div class="my-3 row justify-content-center">
          <div class="col-10">
            <div class="card border-dark mb-3">
              <div class="card-header text-dark">Forms and Workflows</div>
              <div class="card-body text-dark">
                <!-- <h5 class="card-title"></h5> -->
                <p class="card-text">Charts</p>
                <p class="card-text">Cultures</p>
                <p class="card-text">Experiments, Plates</p>
                <p class="card-text">Recipes, Medias</p>
                <p class="card-text">Chemicals, Manufacturers</p>
                <p class="card-text">Strains, Incubators</p>
                <p class="card-text">Flasks, Projects and Users</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
