export const plotTypeSettingModel = [
  {
    key_name: "bar",
    display_name: "Bar"
  },
  {
    key_name: "box",
    display_name: "Box"
  },
  {
    key_name: "box-line",
    display_name: "Box-Line"
  },
  {
    key_name: "line",
    display_name: "Line"
  },
  {
    key_name: "point",
    display_name: "Point"
  },
  {
    key_name: "scatter",
    display_name: "Scatter"
  }
  // ,
  // {
  //   key_name: "hist",
  //   display_name: "Histogram"
  // },
  // {
  //   key_name: "ecdf",
  //   display_name: "ECDF"
  // },
  // {
  //   key_name: "kde",
  //   display_name: "KDE"
  // },
  // {
  //   key_name: "strip",
  //   display_name: "Strip"
  // },
  // {
  //   key_name: "swarm",
  //   display_name: "Swarm"
  // },
  // {
  //   key_name: "violin",
  //   display_name: "Violin"
  // }
];
