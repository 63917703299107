<template>
  <div v-if="selectedType === 'chart' && item">
    <div class="form-group">
      <div
        v-if="localStatusObj.progress"
        class="spinner-border spinner-border-sm text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
        {{ localStatusObj.errorMessage }}
      </span>
      <span v-if="localStatusObj.message" class="mx-1 text-info">
        {{ localStatusObj.message }}
      </span>
      <span class="mr-1">Name:</span>
      <span class="ml-1">{{ item.name }}</span>
    </div>
    <div v-if="item && item.control" class="card">
      <div class="card-header text-dark">Chart Controls</div>
      <div class="card-body">
        <!-- <div class="py-1 small text-dark text-wrap">
          {{ item.control }}
        </div> -->
        <!-- <div class="form-group">
          <span class="mr-1">Title:</span>
          <span class="ml-1">{{ item.control.title }}</span>
        </div> -->
        <div class="form-group">
          <span class="mr-1">Plot Type:</span>
          <span class="ml-1">{{ item.control.type }}</span>
        </div>
        <div class="form-group">
          <span class="mr-1">Data Source:</span>
          <span class="ml-1">{{ item.control.source }}</span>
        </div>
        <div class="form-group">
          <span class="mr-1">X axes:</span>
          <span class="ml-1">{{ item.control.x_axes }}</span>
        </div>
        <div class="form-group">
          <span class="mr-1">Left Y axes:</span>
          <span class="ml-1">{{ item.control.left_y_axes }}</span>
        </div>
        <div class="form-group">
          <span class="mr-1">Right Y axes:</span>
          <span class="ml-1">{{ item.control.right_y_axes }}</span>
        </div>
        <div class="form-group">
          <span class="mr-1">Filters:</span>
          <span class="ml-1">{{ formatFilters }}</span>
        </div>
        <div class="form-group">
          <span class="mr-1">Group By:</span>
          <span class="ml-1">{{ item.control.groups }}</span>
        </div>
        <!-- TODO: Show when features are ready -->
        <!-- <div class="form-group">
          <span class="mr-1">options:</span>
          <span class="ml-1">{{ item.control.options }}</span>
        </div>
        <div class="form-group">
          <span class="mr-1">colors</span>
          <span class="ml-1">{{ item.control.colors }}</span>
        </div> -->
      </div>
    </div>
    <div v-if="svgImage" class="my-5">
      <div class="form-group row">
        <label for="scale-down-input" class="mx-2 col-form-label"
          >Scale Down</label
        >
        <!-- Slider to adjust the scale down -->
        <input
          id="scale-down-input"
          type="range"
          min="1"
          max="5"
          step="0.1"
          v-model="scale"
        />
      </div>
      <!-- Container for the v-html content -->
      <div
        class="svg-container"
        style="overflow-x: scroll; overflow-y: scroll"
        v-html="svgImageWithViewBox"
      ></div>
    </div>
    <div class="py-1">
      <div class="card">
        <div class="card-header text-dark">Description</div>
        <div class="card-body">
          <div class="py-1 small text-dark text-wrap">
            {{ item.desc }}
          </div>
        </div>
        <!-- .card-body -->
      </div>
      <!-- .card -->
    </div>
    <div class="py-1">
      <div class="card">
        <div class="card-header text-dark">Note</div>
        <div class="card-body">
          <div class="py-1 small text-dark text-wrap">
            {{ item.note }}
          </div>
        </div>
        <!-- .card-body -->
      </div>
      <!-- .card -->
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";

export default {
  name: "ChartView",
  data() {
    return {
      svgWidth: 400,
      svgHeight: 300,
      scale: 1, // Initial scale value
      svgImage: "", // Holds the raw SVG content
      viewBoxMinX: 0, // Initial min-x value for the viewBox
      viewBoxMinY: 0, // Initial min-y value for the viewBox
      viewBoxWidth: 500, // Initial width value for the viewBox
      viewBoxHeight: 500, // Initial height value for the viewBox
      maxViewBoxX: 1000, // Max value for viewBox X
      maxViewBoxY: 1000, // Max value for viewBox Y
      filters: [],
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("** dbInfo =", this.dbInfo);
    // console.log("** selectedType =", this.selectedType);
    // Loading is needed for the first item
    await this.initChartView();
  },
  computed: {
    svgImageWithViewBox() {
      if (!this.svgImage) return "";

      // Create a temporary DOM element to manipulate the SVG content
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.svgImage;
      const svgElement = tempDiv.querySelector("svg");

      if (svgElement) {
        svgElement.setAttribute(
          "viewBox",
          `${this.viewBoxMinX} ${this.viewBoxMinY} ${this.viewBoxWidth} ${this.viewBoxHeight}`
        );
      }

      return tempDiv.innerHTML;
    },
    formatFilters() {
      const res = [];
      this.filters.map((fil) => {
        res.push({ key: fil.key, values: fil.values });
      });
      return res;
    }
  },
  methods: {
    async initChartView() {
      await this.loadSVG();
      this.filters.length = 0;
      this.filters = this.filters.concat(this.item.control.filters);
    },
    async loadSVG() {
      if (this.item && this.item.olab_type === "chart") {
        this.svgImage = await OlabAxios.getSVGFile(
          this.item,
          this.svgWidth,
          this.svgHeight,
          this.localStatusObj
        );
        // console.log("svgImage =", this.svgImage);
        if (this.svgImage) {
          // Parse the SVG content and extract the viewBox values
          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(this.svgImage, "image/svg+xml");
          const svgElement = svgDoc.querySelector("svg");
          if (svgElement) {
            const viewBox = svgElement.getAttribute("viewBox");
            if (viewBox) {
              const [minX, minY, width, height] = viewBox
                .split(" ")
                .map(Number);
              this.viewBoxMinX = minX;
              this.viewBoxMinY = minY;
              this.viewBoxWidth = width;
              this.viewBoxHeight = height;

              // Set max values for sliders based on the initial SVG viewBox values
              this.maxViewBoxX = width;
              this.maxViewBoxY = height;
            }
            this.viewBoxWidth = this.scale * this.maxViewBoxX;
            this.viewBoxHeight = this.scale * this.maxViewBoxY;
          }
        }
      }
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.initChartView();
    },
    scale() {
      this.viewBoxWidth = this.scale * this.maxViewBoxX;
      this.viewBoxHeight = this.scale * this.maxViewBoxY;
    }
  }
};
</script>
<style scoped>
.svg-container {
  margin-top: 20px;
  width: 100%;
  height: auto;
}
</style>
