<template>
  <div v-if="culture && culture.olab_type == 'culture'">
    <form>
      <div class="form-group">
        <span class="font-weight-bold mr-1">Name:</span>
        <span class="ml-1">{{ culture.name }}</span>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-5">
          <span class="font-weight-bold mr-1">Culture Type:</span>
          <span class="ml-1">{{ culture.culture_type }}</span>
        </div>
        <div class="form-group col-lg-5">
          <span class="font-weight-bold mr-1">Culture Stage:</span>
          <span class="ml-1">{{ cultureStageName }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-5">
          <span class="font-weight-bold mr-1">Strain Souce Type:</span>
          <span class="ml-1">{{ strainSourceType }}</span>
        </div>
        <div class="form-group col-lg-5">
          <span class="font-weight-bold mr-1">Strain Confirmation Method:</span>
          <span class="ml-1">{{ strainConfirmationMethod }}</span>
        </div>
      </div>

      <!-- Insert Status Messages -->
      <div v-if="localStatusObj && localStatusObj.useStage === 1">
        <div
          v-if="localStatusObj.progress"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
          {{ localStatusObj.errorMessage }}
        </span>
        <span v-if="localStatusObj.message" class="mx-1 text-info">
          {{ localStatusObj.message }}
        </span>
      </div>
      <div v-if="strain" class="form-group">
        <label class="form-control-label">
          <span class="font-weight-bold">Strain:</span>
        </label>
        <div class="table-responsive-lg">
          <table class="table">
            <thead>
              <th scope="col">OLAB ID</th>
              <th scope="col">Name</th>
              <th scope="col">GenoType</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ strain.olab_id }}</td>
                <td>{{ strain.name }}</td>
                <td>{{ strain.strain_genotype }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="media" class="form-group">
        <label class="form-control-label">
          <span class="font-weight-bold">Media:</span>
        </label>
        <div class="table-responsive-lg">
          <table class="table">
            <thead>
              <th scope="col">OLAB ID</th>
              <th scope="col">Name</th>
              <th scope="col">Volume</th>
              <th scope="col">Expiry Date</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ media.olab_id }}</td>
                <td>{{ media.name }}</td>
                <td>{{ media.volume }} {{ media.unit }}</td>
                <td>{{ mediaExpiryDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="container" class="form-group">
        <label class="form-control-label">
          <span class="font-weight-bold">{{ containerTypeName }}:</span>
        </label>
        <div class="table-responsive-lg">
          <table class="table">
            <thead>
              <th scope="col">OLAB ID</th>
              <th scope="col">Name</th>
              <th v-if="container.olab_type !== 'bioreactor'" scope="col">
                Type
              </th>
              <th scope="col">Manufacturer</th>
              <th v-if="container.olab_type !== 'plate'" scope="col">Size</th>
              <th v-else-if="container.dimension && container.type === 'round'">
                Dimension (D * H)
              </th>
              <th
                v-else-if="
                  container.dimension && container.type === 'rectangle'
                "
              >
                Dimension (W * L * H)
              </th>
              <th v-if="container.olab_type === 'bioreactor'" scope="col">
                Temperature
              </th>
              <th v-if="container.olab_type === 'bioreactor'" scope="col">
                Speed
              </th>
              <th v-if="container.olab_type === 'bioreactor'" scope="col">
                Air(vvm)
              </th>
            </thead>
            <tbody>
              <tr>
                <td>{{ container.olab_id }}</td>
                <td>{{ container.name }}</td>
                <td v-if="container.olab_type !== 'bioreactor'">
                  {{ container.type }}
                </td>
                <td>
                  {{ container.manufacturer }}
                </td>
                <td v-if="container.olab_type !== 'plate'">
                  {{ container.size }} {{ containerUnit }}
                </td>
                <td
                  v-else-if="container.dimension && container.type === 'round'"
                >
                  {{ container.dimension.diameter }}
                  {{ container.dimension.unit }} *
                  {{ container.dimension.height }}
                  {{ container.dimension.unit }}
                </td>
                <td
                  v-else-if="
                    container.dimension && container.type === 'rectangle'
                  "
                >
                  {{ container.dimension.width }}
                  {{ container.dimension.unit }} *
                  {{ container.dimension.length }}
                  {{ container.dimension.unit }} *
                  {{ container.dimension.height }}
                  {{ container.dimension.unit }}
                </td>
                <td v-if="container.olab_type === 'bioreactor'">
                  {{ container.tm }} &#8451;
                </td>
                <td v-if="container.olab_type === 'bioreactor'">
                  {{ container.rpm }} rpm
                </td>
                <td v-if="container.olab_type === 'bioreactor'">
                  {{ container.air_vvm }} L/min
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="incubator" class="form-group">
        <label class="form-control-label">
          <span class="font-weight-bold">Incubator:</span>
        </label>
        <div class="table-responsive-lg">
          <table class="table">
            <thead>
              <th scope="col">OLAB ID</th>
              <th scope="col">Name</th>
              <th scope="col">Temperature</th>
              <th scope="col">Type</th>
              <th v-if="incubator.type === 'Shaker'" scope="col">Speed</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ incubator.olab_id }}</td>
                <td>{{ incubator.name }}</td>
                <td>{{ incubator.tm }}&#8451;</td>
                <td>{{ incubator.type }}</td>
                <td v-if="incubator.type === 'Shaker'">
                  {{ incubator.rpm }}rpm
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Insert Status Messages -->
      <div class="form-row">
        <label class="form-control-label">
          <span class="font-weight-bold">Setting:</span>
        </label>
        <div v-if="localStatusObj && localStatusObj.useStage === 2">
          <div
            v-if="localStatusObj.progress"
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
            {{ localStatusObj.errorMessage }}
          </span>
          <span v-if="localStatusObj.message" class="mx-1 text-info">
            {{ localStatusObj.message }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="inputStartDate">Start Date</label>
          <div class="input-group">
            <input
              id="inputStartDate"
              type="date"
              class="form-control"
              v-bind:class="validState(startDate)"
              :value="startDate"
              @input.prevent="updateStartDate"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputStartTime">Start Time</label>
          <div class="input-group">
            <input
              id="inputStartTime"
              type="time"
              class="form-control"
              v-bind:class="validState(startTime)"
              :value="startTime"
              @input.prevent="updateStartTime"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="inputEndDate">End Date</label>
          <div class="input-group">
            <input
              id="inputEndDate"
              type="date"
              class="form-control"
              :value="endDate"
              @input.prevent="updateEndDate"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputEndTime">End Time</label>
          <div class="input-group">
            <input
              id="inputEndTime"
              type="time"
              class="form-control"
              :value="endTime"
              @input.prevent="updateEndTime"
            />
          </div>
        </div>
        <div class="form-group col-md-6">
          <label>Culture Time:</label>
          <div class="text-info ml-2">
            {{ formatCultureTime }}
          </div>
        </div>
      </div>
      <div v-if="container" class="form-row">
        <div v-if="container.olab_type !== 'plate'" class="form-group col-md-3">
          <label for="inputInitialOD">Initial OD (600nm)</label>
          <div class="input-group">
            <input
              id="inputInitialOD"
              type="Number"
              step="0.1"
              min="0"
              class="form-control"
              :value="initialOD"
              @input.prevent="updateInitialOD"
            />
          </div>
        </div>
        <!-- If container.olab_type !== "bioreactor" set final OD -->
        <div
          v-if="
            container.olab_type !== 'bioreactor' &&
            container.olab_type !== 'plate'
          "
          class="form-group col-md-3"
        >
          <label for="inputFinalOD">Final OD (600nm)</label>
          <div class="input-group">
            <input
              id="inputFinalOD"
              type="Number"
              step="0.1"
              min="0"
              class="form-control"
              :value="finalOD"
              @input.prevent="updateFinalOD"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialPHLevel">Initial pH Level</label>
          <div class="input-group">
            <input
              id="inputInitialPHLevel"
              type="Number"
              step="0.1"
              min="0"
              max="14"
              class="form-control"
              :value="initialPHLevel"
              @input.prevent="updateInitialPHLevel"
            />
          </div>
        </div>
        <div
          v-if="
            container.olab_type !== 'bioreactor' &&
            container.olab_type !== 'plate'
          "
          class="form-group col-md-3"
        >
          <label for="inputFinalPHLevel">Final pH Level</label>
          <div class="input-group">
            <input
              id="inputFinalPHLevel"
              type="Number"
              step="0.1"
              min="0"
              max="14"
              class="form-control"
              :value="finalPHLevel"
              @input.prevent="updateFinalPHLevel"
            />
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialTotalCarbon">Initial Total Carbon</label>
          <div class="input-group">
            <input
              id="inputInitialTotalCarbon"
              type="Number"
              min="0"
              class="form-control"
              :value="initialTotalCarbon"
              @input.prevent="updateInitialTotalCarbon"
            />
            <!-- Unit is in gram -->
            <div class="input-group-append">
              <span class="input-group-text"> g </span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInitialVolume">Initial Volume</label>
          <div class="input-group">
            <input
              id="inputInitialVolume"
              type="Number"
              class="form-control"
              :value="initialVolume"
              @input.prevent="updateInitialVolume"
            />
            <div class="input-group-append">
              <span class="input-group-text"> {{ containerUnit }} </span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="inputInoculumSize">Inoculum Size</label>
          <div class="input-group">
            <input
              id="inputInoculumSize"
              type="Number"
              class="form-control"
              :value="inoculumSize"
              @input.prevent="updateInoculumSize"
            />
            <div class="input-group-append">
              <span class="input-group-text"> {{ containerUnit }} </span>
            </div>
          </div>
        </div>
      </div>
      <!-- File uploader is it the last stage in the experiment and for all containers except plate -->
      <div
        v-if="
          container &&
          container.olab_type !== 'plate' &&
          exprt.num_culture_stages === cultureStage
        "
      >
        <div v-if="cultureCategory === 'prod_data_cfg1'">
          <FileUpload
            :user="user"
            :exprt="exprt"
            :culture="culture"
            :reloadUpload="reloadUpload"
            @setReloadUpload="setReloadUpload"
          />
          <ProductionData
            :user="user"
            :exprt="exprt"
            :culture="culture"
            :reloadUpload="reloadUpload"
            @setReloadUpload="setReloadUpload"
          />
        </div>
        <div v-if="cultureCategory === 'prod_data_cfg2'">
          <FileUploadCfg2
            :user="user"
            :exprt="exprt"
            :culture="culture"
            :reloadUpload="reloadUpload"
            @setReloadUpload="setReloadUpload"
          />
          <ProductionDataCfg2
            :user="user"
            :exprt="exprt"
            :culture="culture"
            :reloadUpload="reloadUpload"
            @setReloadUpload="setReloadUpload"
          />
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="inp-culture-imagefile-item">Growth</label>
        <div class="input-group">
          <input
            type="file"
            id="inp-culture-imagefile-item"
            accept="image/*"
          />
        </div>
      </div> -->
      <div class="form-group">
        <label for="inp-culture-item-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="note"
          id="inp-culture-item-note"
        ></textarea>
      </div>
    </form>
    <div class="mt-2 form-row justify-content-between font-italic">
      <div class="col-md-6">Updated at: {{ formatTimeStamp }}</div>
      <div class="col-md-6">Created by: {{ culture.creator }}</div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
import { OlabProdData } from "@/olab/olabproddata";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabUtils } from "@/olab/olabutils";
import FileUpload from "@/components/exprts/FileUpload";
import FileUploadCfg2 from "@/components/exprts/FileUploadCfg2";
import ProductionData from "@/components/exprts/ProductionData";
import ProductionDataCfg2 from "@/components/exprts/ProductionDataCfg2";
import { strainSourceTypeSettingModel } from "@/assets/data/strain_source_type_setting";
import { strainConfirmationMethodSettingModel } from "@/assets/data/strain_confirmation_method_setting";
// import VueMultiselect from "vue-multiselect";

export default {
  name: "CultureItem",
  data() {
    return {
      strainSourceTypeSettingModel,
      strainConfirmationMethodSettingModel,
      reloadUpload: false,
      cultureLocal: null,
      media: null,
      strain: null,

      note: null,
      imageFile: null,
      container: null,
      incubator: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      cultureTime: 0,
      startDateISO: null,
      endDateISO: null,
      inoculumSize: 0,
      initialOD: 0,
      finalOD: 0,
      initialPHLevel: 0,
      finalPHLevel: 0,
      initialVolume: 0,
      initialTotalCarbon: 0,
      // TODO: May add status alert later
      localStatusObj: {
        useStage: 0,
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["user", "exprt", "culture", "statusObj"],
  components: {
    FileUpload,
    FileUploadCfg2,
    ProductionData,
    ProductionDataCfg2
  },
  async mounted() {
    // console.log("Mounted CultureItem: exprt =", this.exprt);
    // console.log("Mounted CultureItem: culture =", this.culture);
    await this.loadCultureData();
    if (this.culture && this.culture.prod_data_category) {
      OlabProdData.setProdDataCategory(this.culture.prod_data_category);
      // console.log(
      //   "Mounted CultureItem: culture.prod_data_category =",
      //   this.culture.prod_data_category
      // );
    }
  },
  computed: {
    cultureCategory() {
      return this.culture.prod_data_category;
    },
    cultureStage() {
      return parseInt(this.culture.culture_stage, 10) + 1;
    },
    cultureStageName() {
      return OlabUtils.getCultureStageName(this.culture.culture_stage);
    },
    strainSourceType() {
      let res = "";
      if (this.strainSourceTypeSettingModel && this.culture) {
        res = this.strainSourceTypeSettingModel.find(
          (tt) => tt.key_name === this.culture.strain_source_type
        );
        res = res ? res.display_name : "";
      }
      return res;
    },
    strainConfirmationMethod() {
      let res = "";
      if (this.strainConfirmationMethodSettingModel && this.culture) {
        res = this.strainConfirmationMethodSettingModel.find(
          (tt) => tt.key_name === this.culture.strain_confirmation_method
        );
        res = res ? res.display_name : "";
      }
      return res;
    },
    mediaExpiryDate() {
      let dateStr = "";
      if (this.media && this.media.expire_date) {
        const date = OlabDate.createDateObjWithISOString(
          this.media.expire_date
        );
        // Show local time
        dateStr = OlabDate.formatYMD(date, false);
      }
      return dateStr;
    },
    containerTypeName() {
      const cType = this.container ? this.container.olab_type : "No Container";
      return cType[0].toUpperCase() + cType.slice(1);
    },
    formatCultureTime() {
      // cultureTime is in minutes
      return OlabDate.formatDiffTime(this.cultureTime);
    },
    formatTimeStamp() {
      let timeStampStr = "";
      if (this.culture) {
        const createdAt = this.culture.createdAt;
        const updatedAt = this.culture.updatedAt;
        timeStampStr = OlabDate.formatTimeStamp(
          updatedAt ? updatedAt : createdAt,
          false
        );
      }
      return timeStampStr;
    },
    containerUnit() {
      let sym = "--";
      if (this.container) {
        if (this.container && this.container.olab_type === "plate") {
          sym = "ml";
        } else {
          sym = OlabUtils.getUnitSym(this.container.unit);
        }
      }
      return sym;
    }
  },
  methods: {
    setReloadUpload(reUpld) {
      // console.log("setReloadUpload =", reUpld);
      this.reloadUpload = reUpld;
    },
    // Computed culture time in minutes
    computeCultureTime() {
      let time = 0;
      if (this.startDate && this.startTime && this.endDate && this.endTime) {
        const sDateTime = OlabDate.createDateObj(
          this.startDate,
          this.startTime
        );
        const eDateTime = OlabDate.createDateObj(this.endDate, this.endTime);
        time = OlabDate.computeDiffTime(sDateTime, eDateTime);
      }
      this.cultureTime = time;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    async loadCultureData() {
      if (this.culture && this.culture.olab_type === "culture") {
        this.localStatusObj.useStage = 1;
        const itemData = await OlabAxios.getCultureData(
          this.culture,
          this.localStatusObj
        );
        // console.log("itemData =", itemData);
        this.cultureLocal = itemData.culture;
        this.media = itemData.media;
        this.strain = itemData.strain;
        this.container = this.cultureLocal ? this.cultureLocal.container : null;
        this.incubator = this.cultureLocal ? this.cultureLocal.incubator : null;
        this.inoculumSize = this.cultureLocal
          ? this.cultureLocal.inoculum_size
          : 0;
        this.initialOD = this.cultureLocal ? this.cultureLocal.initial_OD : 0;
        this.finalOD = this.cultureLocal ? this.cultureLocal.final_OD : 0;
        this.initialPHLevel = this.cultureLocal
          ? this.cultureLocal.initial_ph_level
          : 0;
        this.finalPHLevel = this.cultureLocal
          ? this.cultureLocal.final_ph_level
          : 0;
        this.initialVolume = this.cultureLocal
          ? this.cultureLocal.initial_volume
          : 0;
        this.initialTotalCarbon = this.cultureLocal
          ? this.cultureLocal.initial_total_carbon
          : 0;
        this.note = this.cultureLocal.note;
        if (this.cultureLocal && this.cultureLocal.start_date) {
          const sDate = OlabDate.createDateObjWithISOString(
            this.cultureLocal.start_date
          );
          this.startDate = OlabDate.formatYMD(sDate, false);
          this.startTime = OlabDate.formatHM(sDate, false);
        }
        if (this.cultureLocal && this.cultureLocal.end_date) {
          const eDate = OlabDate.createDateObjWithISOString(
            this.cultureLocal.end_date
          );
          this.endDate = OlabDate.formatYMD(eDate, false);
          this.endTime = OlabDate.formatHM(eDate, false);
        }
        this.computeCultureTime();
      }
    },
    async updateCultureData() {
      // console.log("updateCultureData: culture =", this.culture);
      this.localStatusObj.useStage = 2;
      await OlabAxios.updateCultureData(
        this.user,
        this.culture,
        this.localStatusObj
      );
    },
    computeDateISOStr(date, time) {
      let isoString = null;
      if (date && time) {
        isoString = OlabDate.createDateObj(date, time).toISOString();
      }
      return isoString;
    },
    updateStartDate(e) {
      const sDate = e.target.value;
      this.startDate = sDate;
      const sDateISOStr = this.computeDateISOStr(
        this.startDate,
        this.startTime
      );
      if (sDateISOStr) {
        this.startDateISO = sDateISOStr;
        this.computeCultureTime();
      }
    },
    updateStartTime(e) {
      const sTime = e.target.value;
      this.startTime = sTime;
      const sDateISOStr = this.computeDateISOStr(
        this.startDate,
        this.startTime
      );
      if (sDateISOStr) {
        this.startDateISO = sDateISOStr;
        this.computeCultureTime();
      }
    },
    updateEndDate(e) {
      const eDate = e.target.value;
      this.endDate = eDate;
      const eDateISOStr = this.computeDateISOStr(this.endDate, this.endTime);
      if (eDateISOStr) {
        this.endDateISO = eDateISOStr;
        this.computeCultureTime();
      }
    },
    updateEndTime(e) {
      const eTime = e.target.value;
      this.endTime = eTime;
      const eDateISOStr = this.computeDateISOStr(this.endDate, this.endTime);
      if (eDateISOStr) {
        this.endDateISO = eDateISOStr;
        this.computeCultureTime();
      }
    },
    updateInoculumSize(e) {
      const inoSz = e.target.value;
      // console.log("updateInoculumSize: inoSz =", inoSz);
      this.inoculumSize = inoSz;
      this.culture.setInoculumSize(this.inoculumSize);
      this.updateCultureData();
    },
    updateInitialOD(e) {
      const od = e.target.value;
      // console.log("updateInitialOD: od =", od);
      this.initialOD = od;
      this.culture.setInitialOD(this.initialOD);
      this.updateCultureData();
    },
    updateFinalOD(e) {
      const od = e.target.value;
      // console.log("updateFinalOD: od =", od);
      this.finalOD = od;
      this.culture.setFinalOD(this.finalOD);
      this.updateCultureData();
    },
    updateInitialPHLevel(e) {
      const ph = e.target.value;
      // console.log("updateInitialPHLevel: ph =", ph);
      this.initialPHLevel = ph;
      this.culture.setInitialPHLevel(this.initialPHLevel);
      this.updateCultureData();
    },
    updateFinalPHLevel(e) {
      const ph = e.target.value;
      // console.log("updateFinalPHLevel: ph =", ph);
      this.finalPHLevel = ph;
      this.culture.setFinalPHLevel(this.finalPHLevel);
      this.updateCultureData();
    },
    updateInitialVolume(e) {
      const vol = e.target.value;
      // console.log("updateInitialVolume: vol =", vol);
      this.initialVolume = vol;
      this.culture.setInitialVolume(this.initialVolume);
      this.updateCultureData();
    },
    updateInitialTotalCarbon(e) {
      const cbg = e.target.value;
      // console.log("updateInitialTotalCarbon: cbg =", cbg);
      this.initialTotalCarbon = cbg;
      this.culture.setInitialTotalCarbon(this.initialTotalCarbon);
      this.updateCultureData();
    }
  },
  watch: {
    "culture.olab_id"() {
      // console.log("watch: culture =", this.culture);
      this.loadCultureData();
    },
    startDateISO() {
      this.culture.setStartDate(this.startDateISO);
      // console.log("startDateISO =", this.startDateISO);
      this.updateCultureData();
    },
    endDateISO() {
      this.culture.setEndDate(this.endDateISO);
      // console.log("endDateISO =", this.endDateISO);
      this.updateCultureData();
    },
    imageFile() {
      // TODO: XXXXX
      console.log("TODO: watch: imageFile =", this.imageFile);
    },
    note() {
      this.culture.setNote(this.note);
      // console.log("TODO: watch: note =", this.note);
      this.updateCultureData();
    }
  }
};
</script>
