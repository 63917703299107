export const prodDataCfg2Model = [
  {
    key_name: "time",
    base_unit: "hour",
    display_name: "Time (h)"
  },
  {
    key_name: "od_600nm",
    base_unit: "no_unit",
    display_name: "OD (600nm)"
  },
  {
    key_name: "ph_control",
    base_unit: "no_unit",
    display_name: "pH"
  },
  {
    key_name: "glucose",
    base_unit: "gram/liter",
    display_name: "Glucose (g/L)"
  },
  {
    key_name: "arabionose",
    base_unit: "gram/liter",
    display_name: "Arabionose (g/L)"
  },
  {
    key_name: "propionate",
    base_unit: "gram/liter",
    display_name: "Propionate (g/L)"
  },
  {
    key_name: "lactate",
    base_unit: "gram/liter",
    display_name: "Lactate (g/L)"
  },
  {
    key_name: "acetate",
    base_unit: "gram/liter",
    display_name: "Acetate (g/L)"
  },
  {
    key_name: "ethanol",
    base_unit: "gram/liter",
    display_name: "Ethanol (g/L)"
  },
  {
    key_name: "glycerol",
    base_unit: "gram/liter",
    display_name: "Glycerol (g/L)"
  },
  {
    key_name: "squalene",
    base_unit: "gram/liter",
    display_name: "Squalene (g/L)"
  },
  {
    key_name: "squalane",
    base_unit: "gram/liter",
    display_name: "Squalane (g/L)"
  },
  {
    key_name: "consumed_glucose",
    base_unit: "gram/liter",
    display_name: "Consumed glucose (g/L)"
  },
  {
    key_name: "consumed_arabionose",
    base_unit: "gram/liter",
    display_name: "Consumed arabionose (g/L)"
  },
  {
    key_name: "propionate_glucose",
    base_unit: "percent",
    display_name: "Propionate/Glucose (%)"
  },
  {
    key_name: "lactate_glucose",
    base_unit: "percent",
    display_name: "Lactate/Glucose (%)"
  },
  {
    key_name: "acetate_glucose",
    base_unit: "percent",
    display_name: "Acetate/Glucose (%)"
  },
  {
    key_name: "ethanol_glucose",
    base_unit: "percent",
    display_name: "Ethanol/Glucose (%)"
  },
  {
    key_name: "squalene_glucose",
    base_unit: "percent",
    display_name: "Squalene/Glucose (%)"
  },
  {
    key_name: "squalane_glucose",
    base_unit: "percent",
    display_name: "Squalane/Glucose (%)"
  }
];
