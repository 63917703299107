<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="item && selectedType === 'strain'">
    <form>
      <div class="form-group">
        <label for="inp-edit-name">Name</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-bind:class="validState(item.name)"
            v-model="item.name"
            id="inp-edit-name"
          />
        </div>
      </div>
      <div class="form-group">
        <span class="mr-1">Project ID:</span>
        <span class="ml-1">{{ formatProject }}</span>
      </div>
      <div class="form-group">
        <label for="inp-edit-genotype">Genotype</label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.strain_genotype)"
          v-model="item.strain_genotype"
          id="inp-edit-genotype"
        />
      </div>
      <div class="form-group">
        <label for="inp-edit-desc">Description</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.desc"
          id="inp-edit-desc"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="inp-edit-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-edit-note"
        ></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";

export default {
  name: "StrainEdit",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      project: null,
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["user", "dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("Mounted() item =", this.item);
    await this.loadProjectData();
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    async loadProjectData() {
      if (this.item && this.item.olab_type === "strain") {
        this.project = await OlabAxios.getProject(
          this.item.project_id,
          this.localStatusObj
        );
      }
    }
  },
  computed: {
    formatProject() {
      if (this.project) {
        return `${this.project.olab_id} - ${this.project.name}`;
      }
      return this.item.project_id;
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadProjectData();
    }
  }
};
</script>
