<template>
  <div>
    <form class="mt-3" @submit.prevent="login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h3 class="font-weight-light mb-3">Log in</h3>
                <section class="form-group">
                  <div>
                    <OlabStatusObjAlerts
                      :statusObj="statusObj"
                      @emitStatusMessage="emitStatusMessage"
                      @emitErrorMessage="emitErrorMessage"
                    />
                  </div>
                  <label class="form-control-label sr-only" for="Email"
                    >Email</label
                  >
                  <input
                    class="form-control"
                    v-bind:class="validEmailState()"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    v-model="email"
                  />
                  <div class="invalid-feedback">{{ invalidFB.email }}</div>
                </section>
                <section class="form-group">
                  <input
                    class="form-control"
                    v-bind:class="validPasswordState()"
                    type="password"
                    required
                    placeholder="Password"
                    v-model="password"
                  />
                  <div class="invalid-feedback">{{ invalidFB.password }}</div>
                </section>
                <div class="form-group text-right mb-0">
                  <button class="btn btn-primary" type="submit">Log in</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <p class="text-center mt-2">
      <span>
        <router-link to="/register">register</router-link>
        or
        <router-link to="/forgotpassword">forgot password?</router-link>
        or
        <a
          href="mailto:admin@olabbio.com?subject=Required Action: Forgot Password"
          >admin</a
        >
        for assistance
      </span>
    </p> -->
  </div>
</template>

<script>
// @ is an alias to /src
import OlabStatusObjAlerts from "@/components/OlabStatusObjAlerts.vue";
import axios from "axios";
import { OlabUtils } from "../olab/olabutils";

export default {
  name: "OlabLogin",
  data() {
    return {
      email: "",
      password: "",
      wrongEmail: false,
      wrongPassword: false,
      invalidFB: {
        email: "User email not found.",
        password:
          "Wrong password. Try again or click forgot password to reset it."
      }
    };
  },
  components: {
    OlabStatusObjAlerts
  },
  props: ["statusObj"],
  emits: ["setStatusMessage", "setErrorMessage", "setUser"],
  mounted() {
    console.log("OlabLogin mounted ...");
    // Reset status and error
    this.emitStatusMessage(null, false);
    this.emitErrorMessage(null);
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.$emit("setStatusMessage", statusMsg, statusProgress);
    },
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    validEmailState() {
      let result = null;
      result = this.wrongEmail ? "is-invalid" : null;
      return result;
    },
    validPasswordState() {
      let result = null;
      result = this.wrongPassword ? "is-invalid" : null;
      return result;
    },
    async login() {
      const info = {
        email: this.email,
        password: this.password
      };
      // alert(`${info.email}, ${info.password}`);
      console.log("Login: Implement login", info);
      try {
        const res = await axios({
          method: "POST",
          url: "/api/v1/users/login",
          data: {
            email: info.email,
            password: info.password
          }
        });
        console.log(res.data.data.user);
        if (res.data.status === "success") {
          this.$emit("setUser", res.data.data.user);
          setTimeout(() => {
            this.$router.push("/");
          }, 100);
        }
      } catch (err) {
        console.log(err.response.data.message, err);
        this.emitErrorMessage(OlabUtils.getErrorMessage(err));
      }
    }
  },
  watch: {
    email() {
      // console.log("email = " + this.email);
      // Reset
      if (this.wrongEmail) {
        this.wrongEmail = false;
      }
    },
    password() {
      // console.log("password = " + this.password);
      // Reset
      if (this.wrongPassword) {
        this.wrongPassword = false;
      }
    }
  }
};
</script>
