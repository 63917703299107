<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'user'">
    <form>
      <fieldset class="form-group">
        <!-- <legend>{{selectedType}} Info</legend> -->
        <div class="form-group">
          <label for="inp-edit-name" class="form-control-label">Name:</label>
          <input
            class="form-control"
            v-bind:class="validState(item.name)"
            type="text"
            placeholder="Name"
            v-model="item.name"
            id="inp-edit-name"
          />
          <!-- <div class="invalid-feedback">{{invalidFB.name}}</div> -->
        </div>
        <div class="text-wrap">
          <label class="form-control-label">Email:</label>
          <span class="ml-1">{{ item.email }}</span>
        </div>
        <div class="form-group">
          <div v-if="user.role && user.role === 'admin'">
            <label for="inp-edit-role" class="form-control-label"
              >System Role:</label
            >
            <select
              class="form-control"
              v-bind:class="validState(item.role)"
              v-model="item.role"
              id="inp-edit-role"
            >
              <option disabled value="null">Please select one</option>
              <option value="admin">Admin</option>
              <option value="lead">Lead</option>
              <option value="user">User</option>
            </select>
            <!-- <span>selected = {{ item.role }}</span> -->
          </div>
          <div v-else>
            <span class="mr-1">System Role:</span>
            <span class="h5 text-dark">{{ item.role }}</span>
          </div>
        </div>
        <div v-if="divisions" class="form-group">
          <div v-if="user.role && user.role === 'admin'">
            <label for="inp-edit-division" class="form-control-label"
              >Divison:</label
            >
            <select
              class="form-control"
              v-bind:class="validState(item.division)"
              v-model="item.division"
              id="inp-edit-division"
            >
              <option
                v-for="division in divisions"
                :value="division.key_name"
                :key="division.key_name"
                :disabled="division.disabled"
              >
                {{ division.display_name }}
              </option>
            </select>
            <!-- <span>selected = {{ item.division }}</span> -->
          </div>
          <div v-else>
            <span class="mr-1">Divison:</span>
            <span class="h5 text-dark">{{ item.division }}</span>
          </div>
        </div>
        <div v-if="user.role && user.role === 'admin'" class="form-group">
          <label for="inp-edit-password" class="form-control-label">
            <span>Password:</span>
          </label>
          <div class="form-row">
            <section class="col-sm-6">
              <input
                class="form-control"
                v-bind:class="validPWState(item.passwd)"
                type="password"
                required
                placeholder="************"
                v-model="item.passwd"
                id="inp-edit-password"
              />
              <div class="invalid-feedback">
                {{ invalidFB.password }}
              </div>
            </section>
            <section class="col-sm-6">
              <input
                class="form-control"
                v-bind:class="validCPWState(item.passwd, item.confirmed_passwd)"
                type="password"
                required
                placeholder="************"
                v-model="item.confirmed_passwd"
              />
              <div class="invalid-feedback">
                {{ invalidFB.password_confirmed }}
              </div>
            </section>
          </div>
        </div>
        <!-- .card -->
      </fieldset>
      <!-- .form-group -->
    </form>
  </div>
</template>

<script>
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";

export default {
  name: "UserEdit",
  data() {
    return {
      divisions: null,
      invalidFB: OlabItem.getInvalidFeedbacks()
    };
  },
  props: ["user", "dbInfo", "selectedType", "item"],
  mounted() {
    const dbI = this.dbInfo;
    // Setup divisions options
    if (dbI && dbI.divisions && dbI.divisions.length > 0) {
      this.divisions = [
        {
          key_name: null,
          display_name: "Please select one",
          disabled: true
        }
      ];
      dbI.divisions.forEach((dv) => {
        this.divisions.push(dv);
      });
    } else {
      this.divisions = null;
    }
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    validPWState(pw) {
      // Don't validate if pw is null or ""
      let result = "";
      if (pw !== null && pw !== "") {
        result = OlabSanity.getValidPWState(pw);
      }
      return result;
    },
    validCPWState(pw, cpw) {
      // Don't validate if pw and cpw are null or ""
      let result = "";
      if ((pw === null || pw === "") && (cpw === null || cpw === "")) {
        result = "";
      } else {
        result = OlabSanity.getValidCPWState(pw, cpw);
      }
      return result;
    }
  }
};
</script>
