// import { OlabUtils } from "./olabutils.js";

class OlabDate {
  static _pad(n) {
    return (n < 10 ? "0" : "") + n;
  }

  static formatDMY(d, isoFlag) {
    const date = isoFlag ? d.getUTCDate() : d.getDate();
    const month = isoFlag ? d.getUTCMonth() : d.getMonth();
    const year = isoFlag ? d.getUTCFullYear() : d.getFullYear();
    return OlabDate._pad(date) + "-" + OlabDate._pad(month + 1) + "-" + year;
  }

  static formatYMD(d, isoFlag) {
    const date = isoFlag ? d.getUTCDate() : d.getDate();
    const month = isoFlag ? d.getUTCMonth() : d.getMonth();
    const year = isoFlag ? d.getUTCFullYear() : d.getFullYear();

    const retDate = `${year}-${OlabDate._pad(month + 1)}-${OlabDate._pad(
      date
    )}`;
    return retDate;
  }

  static formatYMDHM(d, isoFlag) {
    const date = isoFlag ? d.getUTCDate() : d.getDate();
    const month = isoFlag ? d.getUTCMonth() : d.getMonth();
    const year = isoFlag ? d.getUTCFullYear() : d.getFullYear();
    const hours = isoFlag ? d.getUTCHours() : d.getHours();
    const minutes = isoFlag ? d.getUTCMinutes() : d.getMinutes();

    const retDate = `${year}-${OlabDate._pad(month + 1)}-${OlabDate._pad(
      date
    )}`;
    const time = `${OlabDate._pad(hours)}:${OlabDate._pad(minutes)}`;
    return `${retDate} [${time}]`;
  }

  static formatYMDHMS(d, isoFlag) {
    const date = isoFlag ? d.getUTCDate() : d.getDate();
    const month = isoFlag ? d.getUTCMonth() : d.getMonth();
    const year = isoFlag ? d.getUTCFullYear() : d.getFullYear();
    const hours = isoFlag ? d.getUTCHours() : d.getHours();
    const minutes = isoFlag ? d.getUTCMinutes() : d.getMinutes();
    const seconds = isoFlag ? d.getUTCSeconds() : d.getSeconds();

    const retDate = `${year}-${OlabDate._pad(month + 1)}-${OlabDate._pad(
      date
    )}`;
    const time = `${OlabDate._pad(hours)}:${OlabDate._pad(
      minutes
    )}:${OlabDate._pad(seconds)}`;
    return `${retDate} [${time}]`;
  }

  static formatHM(d, isoFlag) {
    const hours = isoFlag ? d.getUTCHours() : d.getHours();
    const minutes = isoFlag ? d.getUTCMinutes() : d.getMinutes();

    const time = `${OlabDate._pad(hours)}:${OlabDate._pad(minutes)}`;
    return `${time}`;
  }

  static formatTimeStamp(tsStr, isoFlag) {
    let formatTS = "";
    if (tsStr) {
      const date = OlabDate.createDateObjWithISOString(tsStr);
      formatTS = OlabDate.formatYMDHMS(date, isoFlag);
    }
    return formatTS;
  }

  static createDateObjWithISOString(s) {
    const b = s.split(/\D+/);
    // console.log("b =", b);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }

  static createDateObj(dateStr, timeStr) {
    const [year, month, day] = dateStr.split("-");
    const [hh, mm] = timeStr.split(":");
    let newDate = new Date(+year, +month - 1, +day, +hh, +mm);
    // console.log("** newDate = ", newDate);
    // console.log("newDate.toString =", newDate.toString());
    // console.log("newDate.toLocaleString =", newDate.toLocaleString());
    // console.log("newDate.toUTCString =", newDate.toUTCString());
    // console.log("newDate.toISOString =", newDate.toISOString());
    // console.log("----------");
    return newDate;
  }

  // Computed diff time in minutes
  static computeDiffTime(startDateObj, endDateObj) {
    let time = 0;
    if (startDateObj && endDateObj) {
      // Convert milliseconds to minutes
      time = (endDateObj.getTime() - startDateObj.getTime()) / 60000;
    }
    return time;
  }

  static formatDiffTime(diffTime) {
    // diffTime is in minutes
    const hours = diffTime / 60;
    const hourPart = Math.floor(hours);
    const minutePart = Math.round((hours - hourPart) * 60);

    return `${diffTime} minutes = ${hourPart} hour(s) and ${minutePart} minute(s).`;
  }

  static convertIsoDateToLocalDateTime(isoDate) {
    const localDate = new Date(isoDate).toLocaleDateString();
    const localTime = new Date(isoDate).toLocaleTimeString();
    const localDateTime = `${localDate}, ${localTime}`;
    return localDateTime;
  }
}

export { OlabDate };
