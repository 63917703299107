<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'project'">
    <form>
      <fieldset class="form-group">
        <!-- <legend>{{selectedType}} Info</legend> -->
        <div class="form-group">
          <label for="inp-edit-name" class="form-control-label"> Name:</label>
          <input
            class="form-control"
            v-bind:class="validState(item.name)"
            type="text"
            placeholder="Name"
            v-model="item.name"
            id="inp-edit-name"
          />
          <!-- <div class="invalid-feedback">{{invalidFB.name}}</div> -->
        </div>
        <div class="form-group">
          <label for="inp-edit-start-date" class="form-control-label">
            Start Date:
          </label>
          <input
            class="form-control"
            v-bind:class="validState(item.start_date)"
            type="date"
            v-model="item.start_date"
            id="inp-edit-start-date"
          />
          <!-- <div class="invalid-feedback">{{invalidFB.startDate}}</div> -->
        </div>
        <div class="form-group">
          <label for="inp-edit-end-date" class="form-control-label"
            >End Date:</label
          >
          <input
            class="form-control"
            type="date"
            v-model="item.end_date"
            id="inp-edit-end-date"
          />
        </div>
        <div v-if="item.ra_locks" class="form-row">
          <div class="form-group col-md-3">
            <div class="py-1 text-wrap">
              <span class="mr-1">Division:</span>
              <span class="ml-1">{{ user.division }}</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label class="form-control-label">
              <!-- <span>Project members and Division leads Access Only:</span> -->
              <span>Division Access:</span>
            </label>
            <div class="form-check form-check-inline mx-2">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                v-model="item.ra_locks.public"
              />
            </div>
            <div>
              <small v-if="item.ra_locks.public" class="text-info">
                Viewable by all members in the {{ user.division }} Division
              </small>
              <small v-else class="text-info">
                Viewable by members of this project and the leads in
                {{ user.division }}
              </small>
            </div>
          </div>
        </div>
        <!-- Add members if public is false -->
        <div v-if="item.ra_locks && !item.ra_locks.public">
          <div>
            <OlabStatusObjDialogs
              :statusObj="localStatusObj"
              :showStatusObjDialog="showStatusObjDialog"
              @emitStatusMessage="emitStatusMessage"
              @emitErrorMessage="emitErrorMessage"
            />
          </div>
          <div class="form-group col-md-10">
            <div class="form-group">
              <label for="sel-users">Select Members:</label>
              <div class="mb-2 olabtest">
                <VueMultiselect
                  id="sel-users"
                  v-model="selUsers"
                  :options="userChoices"
                  :loading="isLoadingUsers"
                  :internal-search="false"
                  :multiple="true"
                  :close-on-select="true"
                  :options-limit="10"
                  :custom-label="nameInfo"
                  placeholder="Type to search"
                  label="olab_id"
                  track-by="olab_id"
                  @search-change="getUsers"
                />
              </div>
              <!-- <p v-if="selUsers && selUsers.length > 0">
                {{ computeSelusers }}
              </p> -->
            </div>
          </div>
          <div class="py-1 text-wrap">
            <span class="mr-1">Members:</span>
          </div>
          <div v-if="item.ra_locks.members" class="py-1 text-wrap">
            <ol>
              <!-- Loop through members and display them in the ordered list -->
              <li v-for="(mbr, index) in item.ra_locks.members" :key="index">
                {{ mbr }}
              </li>
            </ol>
          </div>
        </div>
        <div class="py-1 form-group">
          <label for="inp-edit-desc" class="form-control-label"
            >Description:</label
          >
          <textarea
            class="form-control"
            v-model="item.desc"
            id="inp-edit-desc"
          ></textarea>
        </div>
        <!-- .card -->
      </fieldset>
      <!-- .form-group -->
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";
import OlabStatusObjDialogs from "@/components/OlabStatusObjDialogs.vue";

export default {
  name: "ProjectEdit",
  data() {
    return {
      selUsers: [],
      userChoices: [],
      isLoadingUsers: false,
      invalidFB: OlabItem.getInvalidFeedbacks(),
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      },
      showStatusObjDialog: false
    };
  },
  props: ["user", "dbInfo", "selectedType", "item"],
  components: {
    VueMultiselect,
    OlabStatusObjDialogs
  },
  async mounted() {
    // console.log("ProjectEdit mounted(): item.olab_id =", this.item.olab_id);
    // Preload user choices and members
    await this.getUsers("");
    await this.loadProjMembers();
  },
  computed: {
    computeSelusers() {
      const csfUsrs = [];
      if (this.selUsers) {
        this.selUsers.forEach((sf) => {
          const csf = { name: sf.name, olab_id: sf.olab_id };
          csfUsrs.push(csf);
        });
      }
      return csfUsrs;
    }
  },
  methods: {
    emitStatusMessage(statusMsg, statusProgress) {
      this.localStatusObj.message = statusMsg;
      this.localStatusObj.progress = statusProgress;
      this.showStatusObjDialog = this.localStatusObj.message != null;
    },
    emitErrorMessage(errMsg) {
      this.localStatusObj.errorMessage = errMsg;
      this.showStatusObjDialog = this.localStatusObj.errorMessage != null;
    },
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    nameInfo({ name, email }) {
      return `${name}: [${email}]`;
    },
    async getUsers(query) {
      this.isLoadingUsers = true;
      const searchBody = OlabSearch.createSearchBody("user", query, "name");
      this.userChoices = await OlabAxios.getUsers(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingUsers = false;
    },
    async loadProjMembers() {
      const raLocks = this.item.ra_locks;
      if (raLocks && !raLocks.public && raLocks.members) {
        const usrs = await OlabAxios.getUsersByEmails(
          raLocks.members,
          this.localStatusObj
        );
        this.selUsers = usrs;
      } else {
        this.selUsers = [];
      }
      // console.log("selUsers =", this.selUsers);
    }
  },
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadProjMembers();
    },
    selUsers() {
      // console.log("watch: selUsers =", this.selUsers);
      const mbrs = [];
      if (this.selUsers) {
        this.selUsers.forEach((sUsr) => {
          mbrs.push(sUsr.email);
        });
      }
      this.item.setRaLocksMembers(mbrs);
      // console.log("ra_locks =", this.item.ra_locks);
    }
  }
};
</script>
