<template>
  <div v-if="selectedType === 'media'">
    <form v-on:submit.prevent="onSubmit">
      <div class="card">
        <div class="card-header">Media Recipe</div>
        <div class="card-body">
          <label for="sel-recipe">Recipe:</label>
          <span v-if="recipe == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div>
            <VueMultiselect
              id="sel-recipe"
              v-model="recipe"
              :options="recipes"
              :loading="isLoadingRecipes"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              open-direction="below"
              :options-limit="10"
              :custom-label="recipeInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getRecipes"
            />
          </div>
          <!-- <p v-if="recipe">
            {{ recipe.olab_id }}
          </p> -->
          <div v-if="recipe">
            <div class="form-row mt-4">
              <div class="form-group col-md-4">
                <label>Media Volume</label>
                <div class="input-group">
                  <input
                    name="Volume"
                    type="number"
                    min="0"
                    placeholder="Volume"
                    class="form-control"
                    v-bind:class="isGreaterThanZero(mediaVolume)"
                    v-model="mediaVolume"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{{ mediaUnit }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-4">
                <label for="inp-add-expire-date">Expiry Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="inp-add-expire-date"
                  v-model="mediaExpireDate"
                />
              </div>
            </div>
            <div class="mt-2">
              <!-- <label>Ingredients</label> -->
              <table class="table">
                <thead>
                  <th scope="col">Substance</th>
                  <th scope="col">
                    Recipe <br />
                    {{ recipe.volume }}
                    {{ recipe.unit }}
                  </th>
                  <th scope="col">
                    Media <br />
                    {{ mediaVolume }} {{ mediaUnit }}
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="(ingredient, idx) in recipe.ingredients"
                    v-bind:key="ingredient"
                  >
                    <td>{{ ingredient.name }}</td>
                    <td>{{ ingredient.qty }} {{ ingredient.unit }}</td>
                    <td>{{ computeSV(ingredient, idx) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group mt-3">
            <label>Purpose</label>
            <input
              name="purpose"
              type="text"
              placeholder="Purpose"
              class="form-control"
              v-bind:class="validState(mediaPurpose)"
              style="text-align: left"
              v-model="mediaPurpose"
            />
          </div>
          <div class="form-group">
            <label>Note</label>
            <textarea
              name="note"
              type="text"
              placeholder="Note"
              class="form-control"
              style="text-align: left"
              rows="3"
              v-model="mediaNote"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabUtils } from "@/olab/olabutils";
import VueMultiselect from "vue-multiselect";

export default {
  name: "MediaEdit",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      recipes: [],
      recipe: null,
      isLoadingRecipes: false,
      collapse: false,
      mediaVolume: 0,
      mediaUnit: "L",
      mediaIngredients: [],
      mediaExpireDate: null,
      mediaPurpose: "",
      mediaNote: "",
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  components: { VueMultiselect },
  props: ["user", "dbInfo", "selectedType", "item"],
  async mounted() {
    // console.log("Mounted() item =", this.item);
    await this.loadMediaData();
    await this.getRecipes("");
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    isGreaterThanZero(fieldName) {
      return OlabSanity.isGreaterThanZero(fieldName);
    },
    recipeInfo({ olab_id, name }) {
      return `${olab_id}: [${name}]`;
    },
    async getRecipes(query) {
      // console.log("getRecipes: query = ", query);
      this.isLoadingRecipes = true;
      const searchBody = OlabSearch.createSearchBody("recipe", query, "merge");
      this.recipes = await OlabAxios.getRecipes(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingRecipes = false;
    },
    computeSV(ingredient, idx) {
      let sVal = 0;
      let unit = ingredient.unit;
      if (this.mediaVolume > 0) {
        sVal = (ingredient.qty * this.mediaVolume) / this.recipe.volume;
        sVal = OlabUtils.fixFloatTo(sVal, 4);
      }
      if (idx >= this.mediaIngredients.length) {
        this.mediaIngredients.push({
          name: this.recipe.ingredients[idx].name,
          unit: unit,
          qty: sVal
        });
      } else {
        this.mediaIngredients[idx].name = this.recipe.ingredients[idx].name;
        this.mediaIngredients[idx].unit = unit;
        this.mediaIngredients[idx].qty = sVal;
      }
      return `${sVal} ${unit}`;
    },
    async loadMediaData() {
      if (this.item && this.item.olab_type === "media") {
        this.recipe = await OlabAxios.getRecipe(
          this.item.recipe_id,
          this.localStatusObj
        );
        this.mediaVolume = this.item.volume;
        this.mediaUnit = this.item.unit;
        this.mediaExpireDate = this.item.expire_date;
        this.mediaPurpose = this.item.purpose;
        this.mediaNote = this.item.note;
      }
    }
  },
  computed: {},
  watch: {
    "item.olab_id"() {
      // console.log("watch: item.olab_id: ", this.item.olab_id);
      this.loadMediaData();
    },
    recipe() {
      // console.log("XXXXXX recipe =", this.recipe);
      if (this.recipe) {
        // Set media's unit equal recipe's unit
        this.mediaUnit = this.recipe.unit;
        this.item.setRecipe(this.recipe);
        this.item.setUnit(this.mediaUnit);
      }
    },
    // TO consider: mediaIngredients
    mediaUnit() {
      this.item.setUnit(this.mediaUnit);
    },
    mediaVolume() {
      if (this.recipe) {
        // Take this time to set name
        const mediaName = `${this.recipe.name}-${this.mediaVolume}_${this.mediaUnit}`;
        this.item.setName(mediaName);
      }
      this.item.setVolume(this.mediaVolume);
    },
    mediaExpireDate() {
      this.item.setExpireDate(this.mediaExpireDate);
    },
    mediaPurpose() {
      this.item.setPurpose(this.mediaPurpose);
    },
    mediaNote() {
      this.item.setNote(this.mediaNote);
    }
  }
};
</script>
