<template>
  <div>
    <div class="form-group card">
      <div class="card-header text-dark">
        Production Data
        <div
          v-if="localStatusObj.progress"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="localStatusObj.errorMessage" class="mx-1 text-danger">
          {{ localStatusObj.errorMessage }}
        </span>
        <span v-if="localStatusObj.message" class="mx-1 text-info">
          {{ localStatusObj.message }}
        </span>
      </div>
      <div class="card-body">
        <div v-if="batchUploads" class="mb-2">
          <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th class="small" scope="col">#</th>
                  <th class="small" scope="col">Uploaded By</th>
                  <th class="small" scope="col">Date</th>
                  <th class="small" scope="col">Count</th>
                  <th class="small" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bUpld, index) in batchUploads" :key="index">
                  <th class="small" scope="row">{{ index + 1 }}</th>
                  <td class="small">{{ bUpld.creator }}</td>
                  <td class="small">
                    {{ formatTimeStamp(bUpld.createdAt) }}
                  </td>
                  <td class="small">{{ bUpld.total_count }}</td>
                  <td class="small">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      :disabled="index === selectedIndex"
                      v-on:click="viewData(index, bUpld)"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- {{ batchUploads }} -->
        </div>
        <div>
          <div v-if="prodDatas" class="py-1">
            <div class="card">
              <div class="card-header text-dark">Loaded Product Data</div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="small" scope="col">#</th>
                        <th class="small" scope="col">Time (h)</th>
                        <th class="small" scope="col">OD(600nm)</th>
                        <th class="small" scope="col">pH</th>
                        <th class="small" scope="col">Glucose (g/L)</th>
                        <th class="small" scope="col">Arabionose (g/L)</th>
                        <th class="small" scope="col">Propionate (g/L)</th>
                        <th class="small" scope="col">Lactate (g/L)</th>
                        <th class="small" scope="col">Acetate (g/L)</th>
                        <th class="small" scope="col">Ethanol (g/L)</th>
                        <th class="small" scope="col">Glycerol (g/L)</th>
                        <th class="small" scope="col">Squalene (g/L)</th>
                        <th class="small" scope="col">Squalane (g/L)</th>
                        <th class="small" scope="col">
                          Consumed glucose (g/L)
                        </th>
                        <th class="small" scope="col">
                          Consumed arabionose (g/L)
                        </th>
                        <th class="small" scope="col">
                          Propionate/Glucose (%)
                        </th>
                        <th class="small" scope="col">Lactate/Glucose (%)</th>
                        <th class="small" scope="col">Acetate/Glucose (%)</th>
                        <th class="small" scope="col">Ethanol/Glucose (%)</th>
                        <th class="small" scope="col">Squalene/Glucose (%)</th>
                        <th class="small" scope="col">Squalane/Glucose (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pData, index) in prodDatas" :key="index">
                        <th class="small" scope="row">{{ index + 1 }}</th>
                        <td class="small">{{ pData.time }}</td>
                        <td class="small">{{ pData.od_600nm }}</td>
                        <td class="small">{{ pData.ph_control }}</td>
                        <td class="small">{{ pData.glucose }}</td>
                        <td class="small">{{ pData.arabionose }}</td>
                        <td class="small">{{ pData.propionate }}</td>
                        <td class="small">{{ pData.lactate }}</td>
                        <td class="small">{{ pData.acetate }}</td>
                        <td class="small">{{ pData.ethanol }}</td>
                        <td class="small">{{ pData.glycerol }}</td>
                        <td class="small">{{ pData.squalene }}</td>
                        <td class="small">{{ pData.squalane }}</td>
                        <td class="small">{{ pData.consumed_glucose }}</td>
                        <td class="small">{{ pData.consumed_arabionose }}</td>
                        <td class="small">{{ pData.propionate_glucose }}</td>
                        <td class="small">{{ pData.lactate_glucose }}</td>
                        <td class="small">{{ pData.acetate_glucose }}</td>
                        <td class="small">{{ pData.ethanol_glucose }}</td>
                        <td class="small">{{ pData.squalene_glucose }}</td>
                        <td class="small">{{ pData.squalane_glucose }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-2 float-right">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    v-on:click="clearDatas"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .card-body -->
      <div class="card-footer text-center">
        <!-- <span>Footer Production data</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabDate } from "@/olab/olabdate";
import { OlabSearch } from "@/olab/olabsearch";
import { OlabProdData } from "@/olab/olabproddata";

export default {
  name: "ProductionData",
  data() {
    return {
      prodDataUpload: null,
      selectedIndex: -1,
      prodDatas: null,
      batchUploads: null,
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  props: ["user", "exprt", "culture", "reloadUpload"],
  async mounted() {
    // console.log("Mounted ProductionData: exprt =", this.exprt);
    // console.log("Mounted ProductionData: culture =", this.culture);
    await this.loadProductionUpload();
  },
  computed: {},
  methods: {
    formatTimeStamp(tm) {
      return OlabDate.formatTimeStamp(tm);
    },
    async viewData(index, bUpld) {
      await this.loadProdDatas(index, bUpld);
    },
    clearDatas() {
      this.prodDatas = null;
      this.selectedIndex = -1;
    },
    async loadProdDatas(index, bUpld) {
      this.selectedIndex = index;
      if (bUpld) {
        let searchBody = OlabSearch.createSearchBody(
          "bioreactor_pd",
          bUpld.origin_csv,
          "origin_csv"
        );
        this.prodDatas = await OlabProdData.getProductionDatas(
          searchBody,
          this.localStatusObj
        );
        console.log("prodDatas =", this.prodDatas);
      }
    },
    async loadProductionUpload() {
      // reset
      this.batchUploads = null;

      this.prodDataUpload = await OlabAxios.loadProductionUpload(
        this.user,
        this.culture,
        this.localStatusObj
      );
      if (this.prodDataUpload) {
        this.batchUploads = this.prodDataUpload.batch_uploads;
      } else if (
        this.localStatusObj &&
        this.localStatusObj.errorMessage &&
        this.localStatusObj.errorMessage.includes("404")
      ) {
        // Reset errorMessage since it is possible to have no upload data
        // console.log("errorMessage =", this.localStatusObj.errorMessage);
        this.localStatusObj.errorMessage = null;
      }
      // console.log(
      //   "loadProductionUpload: prodDataUpload =",
      //   this.prodDataUpload
      // );
      // console.log("batchUploads =", this.batchUploads);
    }
  },
  watch: {
    "culture.olab_id"() {
      // console.log("watch: culture =", this.culture);
      this.batchUploads = null;
      this.prodDataUpload = null;
      this.clearDatas();
      // Reload production uploads
      this.loadProductionUpload();
    },
    reloadUpload() {
      // console.log("watch: reloadUpload =", this.culture);
      // Reload production uploads
      this.loadProductionUpload();
      this.$emit("setReloadUpload", false);
    }
  }
};
</script>
