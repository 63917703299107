<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="selectedType === 'strain'">
    <form v-on:submit.prevent="onSubmit">
      <div class="form-group">
        <label for="inp-exprt-name" class="form-control-label">
          <span>Name:</span>
        </label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.name)"
          id="inp-exprt-name"
          placeholder="Name"
          v-model="item.name"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-10">
          <label for="sel-project">Project:</label>
          <span v-if="project == null" class="text-danger font-weight-bold mx-1"
            >*</span
          >
          <div class="mb-2">
            <VueMultiselect
              id="sel-project"
              v-model="project"
              :options="projects"
              :loading="isLoadingProj"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="projectInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getProjects"
            />
          </div>
          <!-- <p v-if="project">
            {{ project.olab_id }}
          </p> -->
        </div>
      </div>
      <div class="form-group">
        <label for="inp-add-genotype">Genotype</label>
        <input
          type="text"
          class="form-control"
          v-bind:class="validState(item.strain_genotype)"
          v-model="item.strain_genotype"
          id="inp-add-genotype"
        />
      </div>
      <div class="form-group">
        <label for="inp-add-desc">Description</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.desc"
          id="inp-add-desc"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="inp-add-note">Note</label>
        <textarea
          type="textarea"
          class="form-control"
          v-model="item.note"
          id="inp-add-note"
        ></textarea>
      </div>
      <div class="form-group float-right">Created by: {{ item.creator }}</div>
    </form>
  </div>
</template>

<script>
import { OlabAxios } from "@/olab/olabaxios";
import { OlabItem } from "@/olab/olabitem.js";
import { OlabSanity } from "@/olab/olabsanity.js";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";

export default {
  name: "StrainAdd",
  data() {
    return {
      invalidFB: OlabItem.getInvalidFeedbacks(),
      project: null,
      projects: [],
      isLoadingProj: false,
      // TODO: May add status alert later
      localStatusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  components: {
    VueMultiselect
  },
  props: ["user", "dbInfo", "selectedType", "item"],
  async mounted() {
    await this.getProjects("");
  },
  methods: {
    validState(state) {
      return OlabSanity.getValidState(state);
    },
    projectInfo({ olab_id, name }) {
      return `${olab_id}: [${name}]`;
    },
    async getProjects(query) {
      // console.log("getProjects: query = ", query);
      this.isLoadingProj = true;
      const searchBody = OlabSearch.createSearchBody("project", query, "merge");
      this.projects = await OlabAxios.getProjects(
        searchBody,
        this.localStatusObj
      );
      this.isLoadingProj = false;
    }
  },
  watch: {
    project() {
      this.item.setProject(this.project);
    }
  }
};
</script>
