export const numCultureStagesSettingModel = [
  {
    key_name: 1,
    display_name: "1-Stage"
  },
  {
    key_name: 2,
    display_name: "2-Stage"
  },
  {
    key_name: 3,
    display_name: "3-Stage"
  },
  {
    key_name: 4,
    display_name: "4-Stage"
  },
  {
    key_name: 5,
    display_name: "5-Stage"
  },
  {
    key_name: 6,
    display_name: "6-Stage"
  },
  {
    key_name: 7,
    display_name: "7-Stage"
  },
  {
    key_name: 8,
    display_name: "8-Stage"
  }
];
