<template>
  <div id="app">
    <OlabNavigation :user="user" @logout="logout" />
    <div
      v-if="user && dbInfo && dbInfo.message"
      class="alert alert-danger text-center"
      role="alert"
    >
      {{ dbInfo.message }}
    </div>
    <router-view
      :user="user"
      :olabServerInfo="olabServerInfo"
      :bioServerInfo="bioServerInfo"
      :dbInfo="dbInfo"
      :statusObj="statusObj"
      @setUser="setUser"
      @logout="logout"
      @setStatusMessage="setStatusMessage"
      @setErrorMessage="setErrorMessage"
    />
    <OlabFooter :user="user" />
  </div>
</template>

<script>
import OlabNavigation from "@/components/OlabNavigation.vue";
import OlabFooter from "@/components/OlabFooter.vue";
import axios from "axios";
import { OlabUtils } from "@/olab/olabutils.js";

export default {
  name: "App",
  data() {
    return {
      olabServerInfo: {
        name: "NO NAME",
        owner: "OLAB Bioscience Informatics"
      },
      bioServerInfo: null,
      dbInfo: null,
      user: null,
      statusObj: {
        message: null,
        progress: false,
        searching: false,
        errorMessage: null
      }
    };
  },
  components: {
    OlabNavigation,
    OlabFooter
  },
  async mounted() {
    // Set the title of the webpage loaded
    document.title = "OLAB Bioscience";
    try {
      const result = await axios.get("/api/v1/olabserver/info");
      const info = result.data;
      if (info) {
        // console.log("OLAB Server Info =", info.data.olabServerInfo);
        this.olabServerInfo = info.data.olabServerInfo;
        this.dbInfo = info.data.olabServerInfo.dbInfo;
        if (this.dbInfo && !this.dbInfo.message) {
          // In cases where dbInfo doesn't have message defined
          // console.log("message is undefined .....", this.dbInfo);
          this.dbInfo = { ...this.dbInfo, message: "" };
          // console.log("Add message to dbInfo .....", this.dbInfo);
        }
        // console.log(info.data.bioServerInfo);
        this.bioServerInfo = info.data.bioServerInfo;
      }
      let res = await axios.get("/api/v1/users/reauth");
      // console.log("reauth: user = " + res.data.data.user);
      if (res.data.status === "success" && res.data.data) {
        const userData = res.data.data;
        // console.log(userData.user);
        this.setupLoginState(userData.user);
      } else {
        this.clearLoginState();
      }
    } catch (err) {
      // console.error(err);
      // console.error(err.name);
      // console.error(err.message);
      // console.error(err.response);
      // console.error(err.response.data.status);
      // console.error(err.response.data.message);
      // console.error(err.response.data.stack);
      this.clearLoginState();
      this.setErrorMessage(OlabUtils.getErrorMessage(err));
    }
  },
  methods: {
    async setupLoginState(user) {
      // reauthenticate user with existing jwt cookie if exist
      try {
        if (user) {
          this.user = user;
        } else {
          // No user login
          this.clearLoginState();
        }
      } catch (err) {
        console.log(err);
        this.setErrorMessage(OlabUtils.getErrorMessage(err));
      }
    },
    clearLoginState() {
      this.user = null;
    },
    setUser(loginUser) {
      console.log("App: setUser =", loginUser);
      this.setupLoginState(loginUser);
    },
    async logout() {
      console.log("App: Calling logout ...");
      try {
        const res = await axios({
          method: "GET",
          url: "/api/v1/users/logout"
        });
        if (res.data.status === "success") {
          this.clearLoginState();
          setTimeout(() => {
            // avoid uncaught error if current route is "/"
            this.$router.push("/").catch(() => {});
          }, 100);
        }
      } catch (err) {
        console.log(err);
        this.setErrorMessage(OlabUtils.getErrorMessage(err));
      }
    },
    setStatusMessage(statusMsg, statusProgress) {
      this.statusObj.message = statusMsg;
      this.statusObj.progress = statusProgress;
    },
    setErrorMessage(errMsg) {
      this.statusObj.errorMessage = errMsg;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Gugi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

@import "node_modules/bootstrap/scss/bootstrap";

:root {
  /* Override Variables */
  --gray-dark: #404040;

  /* Page Variables */
  --color-text: var(--gray-dark);
  --font-family-gugi: Gugi, serif;

  --clr-pink-neon: hsl(317 100% 54%);
  --clr-green-neon: hsl(123, 59%, 52%);
  --clr-bg: hsl(323 21% 16%);
}

.family-gugi {
  font-family: var(--font-family-gugi);
}

.brand-color {
  background-color: #e70;
}

.text-brand-color {
  color: #e70;
}

.btn-brand {
  color: white;
  background: #e70;
}

#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  font-weight: 200;
}
</style>
