class OlabUtils {
  static fixFloatTo(value, digit) {
    return parseFloat(value).toFixed(digit);
  }

  static getErrorMessage(err) {
    const status = err && err.response ? err.response.status : err;
    const message =
      err && err.response && err.response.data
        ? err.response.data.message
        : "NO MESSAGE";
    // console.log("error message:", message);
    return `${status}: ${message}`;
  }

  static errorLog(err) {
    console.log(err);
    // For debugging
    alert(OlabUtils.getErrorMessage(err));
  }

  static subStrB4Space(str) {
    return !str || str === "" ? "" : str.substring(0, str.indexOf(" ")).trim();
  }

  static getUnitSym(unit) {
    let sym = "--";
    switch (unit) {
      case "ml":
      case "milliliter":
        sym = "ml";
        break;
      case "L":
      case "liter":
        sym = "L";
        break;
      case "kl":
      case "kiloliter":
        sym = "kl";
        break;
    }
    return sym;
  }

  static getCultureStageName(cs) {
    let csStr = "";
    switch (cs) {
      case "00":
        csStr = "00 - Initial Culture";
        break;
      case "01":
        csStr = "01 - Second Culture";
        break;
      case "02":
        csStr = "02 - Third Culture";
        break;
      case "03":
        csStr = "03 - Forth Culture";
        break;
      case "04":
        csStr = "04 - Fifth Culture";
        break;
      case "05":
        csStr = "05 - Sixth Culture";
        break;
      case "06":
        csStr = "06 - Seventh Culture";
        break;
      case "07":
        csStr = "07 - Eighth Culture";
        break;
      default:
        csStr = "XX - Unknown Culture";
    }
    return csStr;
  }

  static formatMemorySize(size) {
    let formatSz = "";
    const kiloBytes = 1024;
    const megaBytes = kiloBytes * 1024;
    const gigaBytes = megaBytes * 1024;
    if (size >= gigaBytes) {
      const cSz = parseFloat(size / gigaBytes).toPrecision(4);
      formatSz = `${cSz} GB`;
    } else if (size >= megaBytes) {
      const cSz = parseFloat(size / megaBytes).toPrecision(4);
      formatSz = `${cSz} MB`;
    } else if (size >= kiloBytes) {
      const cSz = parseFloat(size / kiloBytes).toPrecision(4);
      formatSz = `${cSz} KB`;
    } else {
      formatSz = `${size} Bytes`;
    }
    return formatSz;
  }
}

export { OlabUtils };
