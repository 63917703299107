<template>
  <div>
    <div class="list-group list-group-flush">
      <DatabaseListItem
        v-for="sid in selectedItems"
        :key="sid.olab_id"
        :sid="sid"
        :user="user"
        :dbInfo="dbInfo"
        :selectedType="selectedType"
        :item="item"
        :selectedItems="selectedItems"
        :statusObj="statusObj"
      />
    </div>
  </div>
</template>

<script>
import DatabaseListItem from "@/components/DatabaseListItem.vue";

export default {
  name: "DatabaseList",
  data() {
    return {
      selectedItem: null
    };
  },
  props: [
    "user",
    "dbInfo",
    "statusObj",
    "selectedItems",
    "selectedType",
    "item"
  ],
  components: {
    DatabaseListItem
  }
};
</script>
