import { createRouter, createWebHistory } from "vue-router";
import OlabHome from "../views/OlabHome.vue";
import OlabLogin from "../views/OlabLogin.vue";
import OlabAnalysis from "../views/OlabAnalysis.vue";
import OlabChart from "../views/OlabChart.vue";
import OlabExperiment from "../views/OlabExperiment.vue";
import BioCalculators from "../views/BioCalculators.vue";
import FAQ from "@/views/FAQ.vue";
import Database from "../views/Database.vue";
import Molarity from "../views/calc/Molarity.vue";
import Dilution from "../views/calc/Dilution.vue";
import CustomMedium from "../views/calc/CustomMedium.vue";
import MediumBuffer from "../views/calc/MediumBuffer.vue";
import Quick from "../views/calc/Quick.vue";
import MicrobialGrowth from "../views/calc/MicrobialGrowth.vue";
import TodoPage from "../views/TodoPage.vue";

const routes = [
  {
    path: "/",
    name: "olabhome",
    component: OlabHome
  },
  {
    path: "/olablogin",
    name: "olablogin",
    component: OlabLogin
  },
  {
    path: "/todopage",
    name: "todopage",
    component: TodoPage
  },
  {
    path: "/olabanalysis",
    name: "olabanalysis",
    component: OlabAnalysis
  },
  {
    path: "/olabchart",
    name: "olabchart",
    component: OlabChart
  },
  {
    path: "/olabexperiment",
    name: "olabexperiment",
    component: OlabExperiment
  },
  {
    path: "/database",
    name: "database",
    component: Database
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ
  },
  {
    path: "/biocalculators",
    name: "biocalculators",
    component: BioCalculators
  },
  {
    path: "/molarity",
    name: "molarity",
    component: Molarity
  },
  {
    path: "/dilution",
    name: "dilution",
    component: Dilution
  },
  {
    path: "/custommedium",
    name: "custommedium",
    component: CustomMedium
  },
  {
    path: "/mediumbuffer",
    name: "mediumBuffer",
    component: MediumBuffer
  },
  {
    path: "/quick",
    name: "quick",
    component: Quick
  },
  {
    path: "/microbialgrowth",
    name: "microbialgrowth",
    component: MicrobialGrowth
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
