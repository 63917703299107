<template>
  <div>
    <div class="list-group-item d-flex mb-1 align-items-center">
      <div class="container">
        <div class="row">
          <div v-if="accessOK(user, sid)" class="col-1 align-self-center mx-2">
            <button
              type="button"
              class="btn btn-info"
              data-toggle="modal"
              data-target="#selectEditItem"
              v-on:click="registerItem(sid)"
              data-backdrop="static"
              data-keyboard="false"
            >
              <font-awesome-icon icon="edit" />
            </button>
          </div>
          <div v-else class="col-1 mx-2">
            <!-- NOP -->
          </div>

          <div class="col-10 col-sm-4 align-self-center text-center">
            <div v-if="selectedType === 'bioreactor'">
              <img
                src="@/assets/icons/bioreactor.svg"
                alt="Bioreactor icon"
                width="50"
              />
            </div>
            <div v-else-if="selectedType === 'branch'">
              <OlabDnaSVGGen name="Branch" textPosX="250" textPosY="395" />
            </div>
            <div v-else-if="selectedType === 'chemical'">
              <img
                src="@/assets/icons/chemical.svg"
                alt="Chiemical icon"
                width="50"
              />
              <!-- <OlabDnaSVGGen name="Chemical" textPosX="225" textPosY="390" /> -->
            </div>
            <div v-else-if="selectedType === 'culture'">
              <img
                src="@/assets/icons/seed.svg"
                alt="Culture icon"
                width="50"
              />
            </div>
            <div v-else-if="selectedType === 'experiment'">
              <img
                src="@/assets/icons/experiment.svg"
                alt="Experiment icon"
                width="50"
              />
            </div>
            <div v-else-if="selectedType === 'flask'">
              <img src="@/assets/icons/flask.svg" alt="Flask icon" width="50" />
            </div>
            <div v-else-if="selectedType === 'incubator'">
              <img
                src="@/assets/icons/incubator.svg"
                alt="Incubator icon"
                width="50"
              />
            </div>
            <div v-else-if="selectedType === 'manufacturer'">
              <img
                src="@/assets/icons/manufacturer.svg"
                alt="Manufacturer icon"
                width="50"
              />
            </div>
            <div v-else-if="selectedType === 'media'">
              <img
                src="@/assets/icons/inoculum.svg"
                alt="Media icon"
                width="50"
              />
            </div>
            <div v-else-if="selectedType === 'project'">
              <OlabProjectSVGGen name="Project" textPosX="160" textPosY="280" />
            </div>
            <div v-else-if="selectedType === 'recipe'">
              <img
                src="@/assets/icons/recipe.svg"
                alt="Recipe icon"
                width="40"
              />
            </div>
            <div v-else-if="selectedType === 'strain'">
              <img
                src="@/assets/icons/strain.svg"
                alt="Strain icon"
                width="50"
              />
            </div>
            <div v-else-if="selectedType === 'user'">
              <OlabUserSVGGen :usr="sid" />
            </div>
            <div v-else-if="selectedType === 'plate'">
              <img src="@/assets/icons/plate.svg" alt="Plate icon" width="50" />
            </div>
            <div v-else-if="selectedType === 'tube'">
              <img src="@/assets/icons/tube.svg" alt="Tube icon" width="50" />
            </div>
          </div>

          <div
            class="col-12 col-sm-6 mt-2 align-self-center text-center text-truncate"
          >
            <a
              class="h4 text-info"
              data-toggle="modal"
              href="#viewItem"
              v-on:click="registerItem(sid)"
              data-backdrop="static"
              data-keyboard="false"
              >{{ computedName }}</a
            >

            <div class="h6 mt-2">
              <span>{{ sid.olab_id }}</span>
              <span v-if="sid.deleted" class="text-light bg-danger ml-2"
                >DELETED</span
              >
            </div>
            <div v-if="selectedType === 'user'" class="h6">
              {{ sid.email }}
            </div>
            <div v-else-if="sid.creator" class="h6">
              {{ sid.creator }}
            </div>
            <div>
              <small>{{ sid.note }}</small>
            </div>
          </div>

          <div v-if="selectedItem != null">
            <!-- Modal: EditItem -->
            <EditForm
              :user="user"
              :dbInfo="dbInfo"
              :selectedType="selectedType"
              :item="item"
              :selectedItems="selectedItems"
              :statusObj="statusObj"
            />
            <!-- Modal: ViewItem -->
            <ViewForm
              :dbInfo="dbInfo"
              :selectedType="selectedType"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import OlabDnaSVGGen from "@/components/OlabDnaSVGGen";
import OlabProjectSVGGen from "@/components/OlabProjectSVGGen";
import OlabUserSVGGen from "@/components/OlabUserSVGGen";
import EditForm from "@/components/forms/EditForm";
import ViewForm from "@/components/forms/ViewForm";

export default {
  name: "DatabaseListItem",
  data() {
    return {
      selectedItem: null
    };
  },
  props: [
    "sid",
    "user",
    "dbInfo",
    "selectedType",
    "item",
    "selectedItems",
    "statusObj"
  ],
  components: {
    FontAwesomeIcon,
    OlabDnaSVGGen,
    OlabProjectSVGGen,
    OlabUserSVGGen,
    EditForm,
    ViewForm
  },
  methods: {
    accessOK(user, item) {
      let result = false;
      if (user === null || item === null) {
        result = false;
      }
      result = item.accessOK(user);
      return result;
    },
    async registerItem(item) {
      this.selectedItem = item;
      // console.log("registerItem: item = " + item);
      // Copy data to this.item so that v-model for edit item.
      this.item.set(item);
    }
  },
  computed: {
    computedName() {
      let cName = "NO NAME";
      if (this.sid && this.sid.name) {
        cName = this.sid.name;
      }
      // console.log("cName =", cName);
      return cName;
    }
  }
};
</script>

<style scoped>
.stats-form {
  /* background-color: #eed; */
  /* border-radius: 0.375rem; */
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
</style>
