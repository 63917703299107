<template>
  <div class="calulatorface">
    <div class="container text-left mt-3">
      <h1>Custom Biocalculator</h1>
      <p>
        This bidirectional calculator provides an easy-to-use approach to
        calculate custom buffer solution.
      </p>
    </div>
    <!-- This is error message dialog box. Currently it is not in use. -->
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="statusObj.errorMessage"
          class="col-12 alert alert-danger text-center px-3"
        >
          {{ statusObj.errorMessage }}
          <div class="text-center mt-2">
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="closeError()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Select Recipes container only displayed when there is more than 1 recipes in the list -->
    <div class="container" v-if="recipes.length > 1">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="input-group my-3">
            <div class="input-group-prepend">
              <label class="input-group-text lead" for="recipeSelection"
                >Recipes</label
              >
            </div>
            <select v-model="recipeIndex">
              <option disabled value="">Please select one</option>
              <option
                v-for="(recipe, j) in recipes"
                v-bind:value="j"
                v-bind:key="recipe.name"
              >
                {{ recipe.name === "" ? "New Recipe" : recipe.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- Input Recipe formulation of selected recipe -->
    <div class="container text-left">
      <div class="row justify-content-center">
        <!-- Recipe formula  -->
        <div class="col-lg-6 col-md-6">
          <div class="card">
            <div class="card-header">
              <span class="h4">Recipe Formula</span>
              <form class="form-inline" v-on:submit.prevent="onSubmit">
                <span class="mx-2 form-inline mt-2">
                  <button
                    v-if="watchField === 'recipeFormula'"
                    class="btn"
                    v-bind:disabled="recipes[recipeIndex].volume <= 0"
                  >
                    <font-awesome-icon
                      v-bind:class="
                        recipes[recipeIndex].volume <= 0
                          ? 'text-secondary'
                          : 'text-warning'
                      "
                      icon="lock-open"
                      @click="
                        if (recipes[recipeIndex].volume > 0) {
                          watchField = '';
                        }
                      "
                    >
                      &nbsp;</font-awesome-icon
                    >
                  </button>
                  <button v-else class="btn">
                    <font-awesome-icon
                      class="mr-1 text-warning"
                      icon="lock"
                      @click="watchField = 'recipeFormula'"
                    >
                    </font-awesome-icon>
                  </button>
                  Recipe Name:
                  <input
                    name="Recipe Name"
                    type="text"
                    placeholder="Name"
                    class="mx-2 form-control text-primary"
                    style="width: 180px; text-align: center"
                    v-model="recipes[recipeIndex].name"
                    v-bind:disabled="watchField !== 'recipeFormula'"
                /></span>
              </form>
              <form class="form-inline" v-on:submit.prevent="onSubmit">
                <span class="mx-2 mt-2">
                  Volume:
                  <input
                    name="Volume"
                    type="number"
                    min="0"
                    placeholder="Vol"
                    class="mx-2 form-control text-primary"
                    style="width: 80px; text-align: center"
                    v-model="recipes[recipeIndex].volume"
                    v-bind:disabled="watchField !== 'recipeFormula'"
                    @keyup="
                      recipes[recipeIndex].volume = clampPostiveValue(
                        recipes[recipeIndex].volume
                      )
                    "
                  />
                  <select
                    class="form-control text-light bg-secondary"
                    v-model="recipes[recipeIndex].units"
                    v-bind:disabled="watchField !== 'recipeFormula'"
                  >
                    <option>mL</option>
                    <option>L</option>
                  </select>
                </span>
              </form>
            </div>
            <div class="card-body">
              <h4>Ingredients</h4>
              <form
                class="form-inline"
                v-for="(ingredient, k) in recipes[recipeIndex].ingredients"
                v-bind:key="k"
                v-on:submit.prevent="onSubmit"
              >
                <p>
                  <button class="btn" v-if="watchField === 'recipeFormula'">
                    <font-awesome-icon
                      class="mr-1 text-warning"
                      icon="trash-alt"
                      v-on:click="deleteRow(k)"
                    >
                      &nbsp;
                    </font-awesome-icon>
                  </button>
                  <input
                    type="number"
                    min="0"
                    placeholder="Qty"
                    style="width: 80px; text-align: center"
                    v-model="ingredient.ingredientsValue"
                    v-bind:disabled="watchField !== 'recipeFormula'"
                    @keyup="
                      ingredient.ingredientsValue = clampPostiveValue(
                        ingredient.ingredientsValue
                      )
                    "
                  />
                  <select
                    class="mx-2 form-control text-light bg-secondary"
                    v-model="ingredient.unit"
                    v-bind:disabled="watchField !== 'recipeFormula'"
                  >
                    <option>mL</option>
                    <option>L</option>
                    <option>mg</option>
                    <option>g</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Substance"
                    style="text-align: center"
                    v-model="ingredient.substance"
                    v-bind:disabled="watchField !== 'recipeFormula'"
                  />
                </p>
              </form>
              <button class="btn" v-if="watchField === 'recipeFormula'">
                <font-awesome-icon
                  class="mr-1 text-warning"
                  icon="plus-square"
                  v-on:click="addNewRow"
                >
                  &nbsp;
                </font-awesome-icon>
              </button>
              <div class="pb-3 pt-3">
                <span>Adjust final volume to</span>
                <span class="h5 text-primary bg-light mx-1"
                  >{{ recipes[recipeIndex].volume }}
                  {{ recipes[recipeIndex].units }}</span
                >
                <span>with Milli-Q Water</span>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            @click="addRecipe"
            v-if="recipes[recipeIndex].name !== ''"
          >
            Add New Recipe
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#deleteRecipeDialog"
            v-if="recipes.length > 1"
          >
            Delete this recipe
          </button>
        </div>
        <!-- Calculates desired solution mixture -->
        <div class="col-lg-6 col-md-6">
          <div class="card">
            <div class="card-header pb-4">
              <span class="h4">Solution </span>
              <span class="mx-2 form-inline mt-2"
                >Recipe Name: {{ recipes[recipeIndex].name }}</span
              >
              <form
                class="mx-2 form-inline mt-2"
                v-on:submit.prevent="onSubmit"
              >
                <button
                  v-if="watchField === 'volume'"
                  class="btn"
                  v-bind:disabled="recipes[recipeIndex].volume <= 0"
                >
                  <font-awesome-icon
                    v-if="watchField === 'volume'"
                    class="mr-1"
                    v-bind:class="
                      recipes[recipeIndex].volume <= 0
                        ? 'text-secondary'
                        : 'text-warning'
                    "
                    icon="lock-open"
                    @click="watchField = ''"
                  >
                    &nbsp;</font-awesome-icon
                  >
                </button>
                <button
                  v-else
                  class="btn"
                  v-bind:disabled="recipes[recipeIndex].volume <= 0"
                >
                  <font-awesome-icon
                    class="mr-1"
                    v-bind:class="
                      recipes[recipeIndex].volume <= 0
                        ? 'text-secondary'
                        : 'text-warning'
                    "
                    icon="lock"
                    @click="
                      if (recipes[recipeIndex].volume > 0) {
                        watchField = 'volume';
                      }
                    "
                  >
                  </font-awesome-icon>
                </button>
                <input
                  name="Desired volume"
                  type="number"
                  class="mx-2 form-control text-primary"
                  style="width: 120px; text-align: center"
                  v-model="this.recipes[recipeIndex].desiredVolume"
                  @click="
                    if (recipes[recipeIndex].volume > 0) {
                      watchField = 'volume';
                    }
                  "
                />
                {{ calculateVolume() }}
                <span class="mx-2 text-primary">{{
                  this.recipes[recipeIndex].units
                }}</span>
                <span>Solution</span>
              </form>
            </div>
            <div class="card-body">
              <h4>Ingredients</h4>
              <div class="form-group">
                <form
                  class="form-inline"
                  v-on:submit.prevent="onSubmit"
                  v-for="(ingredient, k) in recipes[recipeIndex].ingredients"
                  v-bind:key="ingredient.substance"
                >
                  <p>
                    <button
                      v-if="watchField === k"
                      class="btn"
                      v-bind:disabled="
                        recipes[recipeIndex].volume <= 0 ||
                        ingredient.ingredientsValue <= 0
                      "
                    >
                      <font-awesome-icon
                        class="mr-1"
                        v-bind:class="
                          recipes[recipeIndex].volume <= 0 ||
                          ingredient.ingredientsValue <= 0
                            ? 'text-secondary'
                            : 'text-warning'
                        "
                        icon="lock-open"
                        @click="watchField = ''"
                      ></font-awesome-icon>
                    </button>
                    <button
                      v-else
                      class="btn"
                      v-bind:disabled="
                        recipes[recipeIndex].volume <= 0 ||
                        ingredient.ingredientsValue <= 0
                      "
                    >
                      <font-awesome-icon
                        class="mr-1"
                        v-bind:class="
                          recipes[recipeIndex].volume <= 0 ||
                          ingredient.ingredientsValue <= 0
                            ? 'text-secondary'
                            : 'text-warning'
                        "
                        icon="lock"
                        @click="
                          if (recipes[recipeIndex].volume > 0) {
                            changeWatchField(k);
                          }
                        "
                      ></font-awesome-icon>
                    </button>
                    <input
                      type="number"
                      min="0"
                      style="width: 120px; text-align: center"
                      v-model="recipes[recipeIndex].ingredients[k].value"
                      v-bind:disabled="
                        recipes[recipeIndex].volume <= 0 ||
                        ingredient.ingredientsValue <= 0
                      "
                      @click="
                        if (recipes[recipeIndex].volume > 0) {
                          changeWatchField(k);
                        }
                      "
                      @keyup="
                        ingredient.value = clampPostiveValue(ingredient.value)
                      "
                    />
                    <span v-if="watchField !== k">
                      {{ calculateSubstanceValue(k) }}
                    </span>
                    <span class="mx-2">
                      {{ ingredient.unit }} of {{ ingredient.substance }}</span
                    >
                  </p>
                </form>
              </div>
              <div class="pb-3 pt-1">
                <span>Adjust final volume to</span>
                <span class="h5 text-primary bg-light mx-1"
                  >{{ recipes[recipeIndex].desiredVolume }}
                  {{ recipes[recipeIndex].units }}</span
                >
                <span>with Milli-Q Water</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Dialog box to confirm delete of ingredient -->
    <div
      class="modal fade"
      id="deleteRecipeDialog"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteRecipeDialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Delete Recipe Dialog
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="No"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Do you want to delete {{ recipes[recipeIndex].name }} recipe?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              No
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="deleteRecipe(recipeIndex)"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { OlabUtils } from "@/olab/olabutils";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "CustomMedium",
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      recipeIndex: 0,
      // watchField store the field that any variable that depend on it will be recompute.
      watchField: "recipeFormula",
      recipes: [
        {
          name: "",
          volume: "",
          units: "mL",
          desiredVolume: 0,
          ingredients: [
            {
              ingredientsValue: "",
              unit: "mL",
              substance: "",
              value: 0
            }
          ]
        }
      ]
    };
  },
  props: ["statusObj"],
  emits: ["setErrorMessage"],
  methods: {
    emitErrorMessage(errMsg) {
      this.$emit("setErrorMessage", errMsg);
    },
    closeError() {
      // console.log("Close Error Alert ...");
      this.$emit("setErrorMessage", null);
    },
    onSubmit() {
      // This is a NOP to prevent page reload on return key
      // console.log("After Submit");
    },
    // This is to set the watchField to the ingredient that is being referenced in the solution volume calculation
    changeWatchField(k) {
      if (this.recipes[this.recipeIndex].ingredients[k].ingredientsValue > 0) {
        this.watchField = k;
      }
    },
    clampPostiveValue(value) {
      if (!isNaN(value)) {
        value = value < 0 ? 0 : value;
        return value;
      }
      return 0;
    },
    // This is to calculate the volume of the solution with reference to the changes in the ingredient quality.
    // It check the watchField is not either "volume, recipeFormula or blank" before the calculation is done.
    // It also check that the ingredients quality is not less than zero. If less than zero, it will set the
    // ingredient to zero
    calculateVolume() {
      if (
        this.watchField === "volume" ||
        this.watchField === "recipeFormula" ||
        this.watchField === ""
      ) {
        return;
      } else {
        if (
          this.recipes[this.recipeIndex].ingredients[this.watchField].value < 0
        ) {
          this.recipes[this.recipeIndex].ingredients[this.watchField].value =
            OlabUtils.fixFloatTo(0, 4);
        }
        const value =
          (this.recipes[this.recipeIndex].volume *
            this.recipes[this.recipeIndex].ingredients[this.watchField].value) /
          this.recipes[this.recipeIndex].ingredients[this.watchField]
            .ingredientsValue;
        if (!isNaN(value)) {
          this.recipes[this.recipeIndex].desiredVolume = OlabUtils.fixFloatTo(
            value,
            2
          );
        }
      }
    },
    // This is to calculate the value of the individual ingredients,
    // if the recipe formula volume is zero, it will force the value to be zero to prevent
    // infinite error
    calculateSubstanceValue(k) {
      const recps = this.recipes;
      const recpIndex = this.recipeIndex;
      if (recps[recpIndex].desiredVolume < 0) {
        recps[recpIndex].desiredVolume = OlabUtils.fixFloatTo(0, 4);
      }
      if (recps[recpIndex].volume > 0) {
        const value =
          (parseFloat(recps[recpIndex].ingredients[k].ingredientsValue) *
            parseFloat(recps[recpIndex].desiredVolume)) /
          parseFloat(recps[recpIndex].volume);
        if (!isNaN(value)) {
          recps[recpIndex].ingredients[k].value = OlabUtils.fixFloatTo(
            value,
            4
          );
        }
      } else {
        recps[recpIndex].ingredients[k].value = 0;
      }
    },
    // This is to add new recipe in the recipes formula list
    addRecipe() {
      this.recipes.push({
        name: "",
        volume: "",
        units: "mL",
        desiredVolume: 0,
        ingredients: [
          {
            ingredientsValue: "",
            unit: "mL",
            substance: "",
            value: 0
          }
        ]
      });
      this.watchField = "recipeFormula";
      this.recipeIndex = this.recipes.length - 1;
    },
    // This is to delete the selected recipe in the recipes formula list
    deleteRecipe(index) {
      const idx = index;
      console.log("delete recipe ", idx);
      this.watchField = "recipeFormula";
      if (idx > -1) {
        this.recipes.splice(idx, 1);
        this.recipeIndex = 0;
      }
    },
    // The addNewRow and deleteRow is to add or delete ingredient in the recipes formula
    addNewRow() {
      this.recipes[this.recipeIndex].ingredients.push({
        ingredientsValue: "",
        unit: "mL",
        substance: "",
        value: 0
      });
      console.log("add new row");
    },
    deleteRow(index) {
      // const idx = this.ingredients.indexOf(ingredient);
      const idx = index;
      console.log(idx, index);
      if (idx > -1) {
        console.log("recipe index number", index);
        this.recipes[this.recipeIndex].ingredients.splice(idx, 1);
      }
    }
  }
};
</script>

<style scoped>
.calulatorface {
  padding: 50px 0;
  background-color: #fdf2e9;
}
</style>
