/* The list of forms supported by this class
 *
 * All models contain the following fields:
 *  creator, createdAt, updatedAt and updated_by

 * Project (Note: start_date and end_date are Date() created in ISO Format)
 *  olab_id, olab_type, name, start_date, end_date, desc, note
 *
 * Chemical
 *  olab_id, olab_type, name, cat_no, mfr_id, lot_no, expire_date, note, qty, unit
 *
 * Culture (Note: start_date and end_date are Date() created in ISO Format)
 *  olab_id, olab_type, name, project_id, experiment_id, media_id, strain_id,
 *  strain_source_type, strain_confirmation_method, culture_stage, culture_type,
 *  container_type, container, prod_data_category, start_date, end_date, incubator,
 *  image_file, volume, inoculum_size, initial_OD, final_OD, initial_ph_level,
 *  final_ph_level, initial_total_carbon, initial_volume, note, creator, updated_by,
 *  data_uploaded
 *
 * Bioreactor
 *  olab_id, olab_type, name, type, size, air_vvm, rpm, tm, desc, note
 *
 * Flask
 *  olab_id, olab_type, name, type, size, unit, desc, note
 *
 * Incubator - (note: tm -> temperature)
 *  olab_id, olab_type, name, type, rpm, tm, desc, note
 *
 * Manufacturer
 *  olab_id, olab_type, name, addr1, addr2, city, state, zipcode,
 *  country, contact_name, phone, email, note
 *
 * Media
 *  olab_id, olab_type, recipe_id, volume, unit, name (recipe name + volume)
 *  ingredients[{ name, unit, qty}], purpose, expire_date, note
 *
 * Flask
 *  olab_id, olab_type, name, type, dimension, desc, note
 *
 * Project
 *  olab_id, olab_type, name, division, project_id, ra_locks, desc
 *
 * Recipe
 *  olab_id, olab_type, name, volume, unit,
 *  ingredients[{ chem_id, name, unit, qty}], note
 *
 * Strain
 *  olab_id, olab_type, name, project_id, ra_locks, strain_genotype,
 *  desc, note
 *
 * Tube
 *  olab_id, olab_type, name, type, size, unit, desc, note
 *
 * User
 *  olab_id, olab_type, name, email, division, role, password, confirmed_password,
 *  note
 *
 */
class OlabItem {
  constructor(item) {
    if (item == null) {
      this.initItem();
    } else {
      let hp = Object.prototype.hasOwnProperty;
      this.olab_id = hp.call(item, "olab_id") ? item.olab_id : null;
      this.olab_type = hp.call(item, "olab_type") ? item.olab_type : null;
      this.name = hp.call(item, "name") ? item.name : null;
      this.division = hp.call(item, "division") ? item.division : null;
      this.desc = hp.call(item, "desc") ? item.desc : null;
      this.note = hp.call(item, "note") ? item.note : null;

      // System audit data
      this.creator = hp.call(item, "creator") ? item.creator : null;
      this.createdAt = hp.call(item, "createdAt") ? item.createdAt : null;
      this.updatedAt = hp.call(item, "updatedAt") ? item.updatedAt : null;
      this.updated_by = hp.call(item, "updated_by") ? item.updated_by : null;

      // Additional fields for project only
      this.start_date = hp.call(item, "start_date") ? item.start_date : null;
      this.end_date = hp.call(item, "end_date") ? item.end_date : null;

      // Additional fields for branch only
      this.members = hp.call(item, "members") ? item.members : null;

      // Additional fields for flask only
      this.type = hp.call(item, "type") ? item.type : null;
      this.size = hp.call(item, "size") ? item.size : null;

      // Additional fields for plate only
      this.dimension = hp.call(item, "dimension") ? item.dimension : null;

      // Additional fields for user only
      this.role = hp.call(item, "role") ? item.role : null;
      this.email = hp.call(item, "email") ? item.email : null;
      this.passwd = hp.call(item, "passwd") ? item.passwd : null;
      this.confirmed_passwd = hp.call(item, "confirmed_passwd")
        ? item.confirmed_passwd
        : null;
      this.photo = hp.call(item, "photo") ? item.photo : null;

      // Additional field for plasmid, project and strain
      let raLocks = hp.call(item, "ra_locks") ? item.ra_locks : null;
      this.ra_locks = {
        public: true,
        members: null
      };
      if (raLocks) {
        this.ra_locks.public = hp.call(raLocks, "public")
          ? raLocks.public
          : true;
        this.ra_locks.members = hp.call(raLocks, "members")
          ? raLocks.members
          : null;
      }
      // Additional fields for strain only
      this.strain_genotype = hp.call(item, "strain_genotype")
        ? item.strain_genotype
        : null;

      // Additional fields for incubator only
      this.tm = hp.call(item, "tm") ? item.tm : null;
      this.rpm = hp.call(item, "rpm") ? item.rpm : null;

      // Additional fields for Bioreactor
      this.air_vvm = hp.call(item, "air_vvm") ? item.air_vvm : null;

      // Additional fields for manufacturer only
      this.addr1 = hp.call(item, "addr1") ? item.addr1 : null;
      this.addr2 = hp.call(item, "addr2") ? item.addr2 : null;
      this.city = hp.call(item, "city") ? item.city : null;
      this.state = hp.call(item, "state") ? item.state : null;
      this.zipcode = hp.call(item, "zipcode") ? item.zipcode : null;
      this.country = hp.call(item, "country") ? item.country : null;
      this.contact_name = hp.call(item, "contact_name")
        ? item.contact_name
        : null;
      this.phone = hp.call(item, "phone") ? item.phone : null;

      // Additional fields for chemical only
      this.cat_no = hp.call(item, "cat_no") ? item.cat_no : null;
      this.lot_no = hp.call(item, "lot_no") ? item.lot_no : null;
      this.mfr_id = hp.call(item, "mfr_id") ? item.mfr_id : null;
      this.expire_date = hp.call(item, "expire_date") ? item.expire_date : null;
      this.qty = hp.call(item, "qty") ? item.qty : null;
      this.unit = hp.call(item, "unit") ? item.unit : null;

      // Additional fields for recipe only
      this.volume = hp.call(item, "volume") ? item.volume : null;
      this.ingredients = hp.call(item, "ingredients") ? item.ingredients : [];

      // Additional fields for media only
      this.recipe_id = hp.call(item, "recipe_id") ? item.recipe_id : null;
      this.purpose = hp.call(item, "purpose") ? item.purpose : null;

      // Additional fields for branch only
      this.project_id = hp.call(item, "project_id") ? item.project_id : null;

      // Additional fields for experimet only
      this.branch_id = hp.call(item, "branch_id") ? item.branch_id : null;
      this.hypotheses = hp.call(item, "hypotheses") ? item.hypotheses : [];
      this.control_strain_id = hp.call(item, "control_strain_id")
        ? item.control_strain_id
        : null;
      this.test_strain_ids = hp.call(item, "test_strain_ids")
        ? item.test_strain_ids
        : [];
      this.related_expr_ids = hp.call(item, "related_expr_ids")
        ? item.related_expr_ids
        : [];
      this.verifier_id = hp.call(item, "verifier_id") ? item.verifier_id : null;
      this.verifier_signature = hp.call(item, "verifier_signature")
        ? item.verifier_signature
        : null;
      this.verified_date = hp.call(item, "verified_date")
        ? item.verified_date
        : null;

      // Additional fields for Culture
      this.experiment_id = hp.call(item, "experiment_id")
        ? item.experiment_id
        : null;
      this.media_id = hp.call(item, "media_id") ? item.media_id : null;
      this.strain_id = hp.call(item, "strain_id") ? item.strain_id : null;
      this.culture_stage = hp.call(item, "culture_stage")
        ? item.culture_stage
        : null;
      this.culture_type = hp.call(item, "culture_type")
        ? item.culture_type
        : null;
      this.strain_source_type = hp.call(item, "strain_source_type")
        ? item.strain_source_type
        : null;
      this.strain_confirmation_method = hp.call(
        item,
        "strain_confirmation_method"
      )
        ? item.strain_confirmation_method
        : null;
      this.container_id = hp.call(item, "container_id")
        ? item.container_id
        : null;
      this.container_type = hp.call(item, "container_type")
        ? item.container_type
        : null;
      this.container = hp.call(item, "container") ? item.container : null;
      this.prod_data_category = hp.call(item, "prod_data_category")
        ? item.prod_data_category
        : null;
      this.incubator_id = hp.call(item, "incubator_id")
        ? item.incubator_id
        : null;
      this.incubator = hp.call(item, "incubator") ? item.incubator : null;
      this.image_file = hp.call(item, "image_file") ? item.image_file : null;
      this.inoculum_size = hp.call(item, "inoculum_size")
        ? item.inoculum_size
        : null;
      this.initial_OD = hp.call(item, "initial_OD") ? item.initial_OD : null;
      this.final_OD = hp.call(item, "final_OD") ? item.final_OD : null;
      this.initial_ph_level = hp.call(item, "initial_ph_level")
        ? item.initial_ph_level
        : null;
      this.final_ph_level = hp.call(item, "final_ph_level")
        ? item.final_ph_level
        : null;
      this.initial_total_carbon = hp.call(item, "initial_total_carbon")
        ? item.initial_total_carbon
        : null;
      this.initial_volume = hp.call(item, "initial_volume")
        ? item.initial_volume
        : null;
      this.data_uploaded = hp.call(item, "data_uploaded")
        ? item.data_uploaded
        : null;
      this.csv_file = hp.call(item, "csv_file") ? item.csv_file : null;

      // Additional fields for Experiment
      this.num_culture_stages = hp.call(item, "num_culture_stages")
        ? item.num_culture_stages
        : null;

      // Additional fields for Chart
      this.control = hp.call(item, "control") ? item.control : null;
      this.svg_filenames = hp.call(item, "svg_filenames")
        ? item.svg_filenames
        : null;
      this.auto_range = hp.call(item, "auto_range") ? item.auto_range : true;
      // An array of limits consists of 2 numbers (min and max)
      this.left_y_axes_limits = hp.call(item, "left_y_axes_limits")
        ? item.left_y_axes_limits
        : null;
      this.right_y_axes_limits = hp.call(item, "right_y_axes_limits")
        ? item.right_y_axes_limits
        : null;
      // Additional fields for Baseunit
      this.key_name = hp.call(item, "key_name") ? item.key_name : null;
      this.base_unit = hp.call(item, "base_unit") ? item.base_unit : null;
      this.display_name = hp.call(item, "display_name")
        ? item.display_name
        : null;
    }
  }

  static getInvalidFeedbacks() {
    let fbMessage = {
      name: "Name is a required field",
      email: "email format is invalid",
      password: "password has to be 8 or more characters",
      password_confirmed: "passwords must match"
    };
    return fbMessage;
  }

  initItem() {
    // console.log("OlabItem.initItem() ...");

    // Common fields: internal use
    this.olab_id = null;
    this.olab_type = null;
    this.creator = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.updated_by = null;

    // Common fields: User settable
    this.name = null;
    this.division = null;
    this.desc = null;
    this.note = null;

    // Additional fields for user only
    this.role = null;
    this.email = null;
    this.passwd = null;
    this.confirmed_passwd = null;
    this.photo = null;

    // Additional fields for project only
    this.start_date = null;
    this.end_date = null;

    // Additional fields for branch only
    this.members = null;

    // Additional fields for flask only
    this.type = null;
    this.size = null;

    // Additional fields for plate only
    this.dimension = null;

    // Additional field for project and strain
    this.ra_locks = {
      public: true,
      members: null
    };

    // Additional fields for strain only
    this.strain_genotype = null;

    // Additional fields for incubator only
    this.tm = null;
    this.rpm = null;

    // Additional fields for bioreactor only
    this.air_vvm = null;

    // Additional fields for manufacturer only
    this.addr1 = null;
    this.addr2 = null;
    this.city = null;
    this.state = null;
    this.zipcode = null;
    this.country = null;
    this.contact_name = null;
    this.phone = null;

    // Additional fields for chemical only
    this.cat_no = null;
    this.lot_no = null;
    this.mfr_id = null;
    this.expire_date = null;
    this.qty = null;
    this.unit = null;

    // Additional fields for recipe
    this.volume = null;
    this.ingredients = [];

    // Additional fields for media
    this.recipe_id = null;
    this.purpose = null;

    // Additional fields for Branch
    this.project_id = null;

    // Additional fields for Experiment
    this.branch_id = null;
    this.hypotheses = [];
    this.control_strain_id = null;
    this.test_strain_ids = [];
    this.related_expr_ids = [];
    this.verifier_id = null;
    this.verifier_signature = null;
    this.verified_date = null;

    // Additional fields for Culture
    this.experiment_id = null;
    this.media_id = null;
    this.strain_id = null;
    this.culture_stage = null;
    this.culture_type = null;
    this.strain_source_type = null;
    this.strain_confirmation_method = null;
    this.container_id = null;
    this.container_type = null;
    this.container = null;
    this.prod_data_category = null;
    this.incubator_id = null;
    this.incubator = null;
    this.image_file = null;
    this.inoculum_size = null;
    this.initial_OD = null;
    this.final_OD = null;
    this.initial_ph_level = null;
    this.final_ph_level = null;
    this.initial_total_carbon = null;
    this.initial_volume = null;
    this.data_uploaded = null;
    this.csv_file = null;

    // Additional fields for Experiment
    this.num_culture_stages = null;

    // Additional fields for Chart
    this.control = null;
    this.svg_filenames = null;
    this.auto_range = true;
    this.left_y_axes_limits = null;
    this.right_y_axes_limits = null;

    // Additional fields for Baseunit
    this.key_name = null;
    this.base_unit = null;
    this.display_name = null;
  }

  setCultureStage(cs) {
    this.culture_stage = cs;
  }

  setCultureType(ct) {
    this.culture_type = ct;
  }

  setStrainSourceType(sst) {
    this.strain_source_type = sst ? sst.key_name : null;
  }

  setStrainConfirmationMethod(scm) {
    this.strain_confirmation_method = scm ? scm.key_name : null;
  }

  setContainer(con) {
    this.container_id = con ? con.olab_id : null;
    this.container_type = con ? con.olab_type : null;
    this.container = con;
  }

  setIncubator(icb) {
    this.incubator_id = icb ? icb.olab_id : null;
    this.incubator = icb;
  }

  setExperiment(exprt) {
    this.experiment_id = exprt ? exprt.olab_id : null;
  }

  setMedia(mda) {
    this.media_id = mda ? mda.olab_id : null;
  }

  setStrain(strn) {
    this.strain_id = strn ? strn.olab_id : null;
  }

  setBranch(br) {
    this.branch_id = br ? br.olab_id : null;
  }

  setProject(proj) {
    this.project_id = proj ? proj.olab_id : null;
  }

  setControlStrain(ctrStrn) {
    this.control_strain_id = ctrStrn ? ctrStrn.olab_id : null;
  }

  setTestStrains(testStrnIDs) {
    this.test_strain_ids = testStrnIDs ? testStrnIDs : [];
  }

  setRelatedExprts(relExprtIDs) {
    this.related_expr_ids = relExprtIDs ? relExprtIDs : [];
  }

  setVerifier(vr) {
    this.verifier_id = vr ? vr.olab_id : null;
  }

  setHypotheses(hypos) {
    this.hypotheses = hypos ? hypos : [];
  }

  setName(name) {
    this.name = name;
  }

  setRecipe(recipe) {
    this.recipe_id = recipe ? recipe.olab_id : null;
  }

  setUnit(unit) {
    this.unit = unit;
  }

  setDimension(dim) {
    this.dimension = dim;
  }

  setInoculumSize(inoSz) {
    this.inoculum_size = inoSz;
  }

  setInitialOD(od) {
    this.initial_OD = od;
  }

  setFinalOD(od) {
    this.final_OD = od;
  }

  setInitialPHLevel(ph) {
    this.initial_ph_level = ph;
  }

  setFinalPHLevel(ph) {
    this.final_ph_level = ph;
  }

  setInitialTotalCarbon(cbn) {
    this.initial_total_carbon = cbn;
  }

  setVolume(vol) {
    this.volume = vol;
  }

  setInitialVolume(vol) {
    this.initial_volume = vol;
  }

  setAir(air) {
    this.air_vvm = air;
  }

  setRPM(rpm) {
    this.rpm = rpm;
  }

  setTM(tm) {
    this.tm = tm;
  }

  setPurpose(purpose) {
    this.purpose = purpose;
  }

  setNote(note) {
    this.note = note;
  }

  setStartDate(startDate) {
    this.start_date = startDate;
  }

  setEndDate(endDate) {
    this.end_date = endDate;
  }

  setExpireDate(expDate) {
    this.expire_date = expDate;
  }

  setNumCultureStages(ncs) {
    this.num_culture_stages = ncs;
  }

  setControl(ctrl) {
    this.control = ctrl;
  }

  setMembers(mbrs) {
    this.members = mbrs;
  }

  setRaLocksMembers(mbrs) {
    if (this.ra_locks) {
      this.ra_locks.members = mbrs;
    }
  }

  setMfrID(mfrID) {
    this.mfr_id = mfrID;
  }

  setType(type) {
    this.type = type;
  }

  set(item) {
    this.olab_id = item.olab_id;
    this.olab_type = item.olab_type;
    this.name = item.name;
    this.division = item.division;
    this.desc = item.desc;
    this.note = item.note;
    this.creator = item.creator;
    this.createdAt = item.createdAt;
    this.updatedAt = item.updatedAt;
    this.updated_by = item.updated_by;

    this.role = item.role;
    this.email = item.email;
    this.passwd = item.passwd;
    this.confirmed_passwd = item.confirmed_passwd;
    this.photo = item.photo;

    this.start_date = item.start_date;
    this.end_date = item.end_date;

    this.members = item.members;

    this.type = item.type;
    this.size = item.size;

    this.dimension = item.dimension;

    this.ra_locks = item.ra_locks;

    this.strain_genotype = item.strain_genotype;

    this.tm = item.tm;
    this.rpm = item.rpm;

    this.air_vvm = item.air_vvm;

    this.addr1 = item.addr1;
    this.addr2 = item.addr2;
    this.city = item.city;
    this.state = item.state;
    this.zipcode = item.zipcode;
    this.country = item.country;
    this.contact_name = item.contact_name;
    this.phone = item.phone;

    this.cat_no = item.cat_no;
    this.lot_no = item.lot_no;
    this.mfr_id = item.mfr_id;
    this.expire_date = item.expire_date;
    this.qty = item.qty;
    this.unit = item.unit;

    this.volume = item.volume;
    this.ingredients = item.ingredients;

    this.recipe_id = item.recipe_id;
    this.purpose = item.purpose;

    this.branch_id = item.branch_id;

    this.project_id = item.project_id;
    this.hypotheses = item.hypotheses;
    this.control_strain_id = item.control_strain_id;
    this.test_strain_ids = item.test_strain_ids;
    this.related_expr_ids = item.related_expr_ids;
    this.verifier_id = item.verifier_id;
    this.verifier_signature = item.verifier_signature;
    this.verified_date = item.verified_date;

    this.experiment_id = item.experiment_id;
    this.media_id = item.media_id;
    this.strain_id = item.strain_id;
    this.culture_stage = item.culture_stage;
    this.culture_type = item.culture_type;
    this.strain_source_type = item.strain_source_type;
    this.strain_confirmation_method = item.strain_confirmation_method;
    this.container_id = item.container_id;
    this.container_type = item.container_type;
    this.container = item.container;
    this.prod_data_category = item.prod_data_category;
    this.incubator_id = item.incubator_id;
    this.incubator = item.incubator;
    this.image_file = item.image_file;
    this.inoculum_size = item.inoculum_size;
    this.initial_OD = item.initial_OD;
    this.final_OD = item.final_OD;
    this.initial_ph_level = item.initial_ph_level;
    this.final_ph_level = item.final_ph_level;
    this.initial_volume = item.initial_volume;
    this.initial_total_carbon = item.initial_total_carbon;
    this.data_uploaded = item.data_uploaded;
    this.csv_file = item.csv_file;

    this.num_culture_stages = item.num_culture_stages;

    this.control = item.control;
    this.svg_filenames = item.svg_filenames;
    this.auto_range = item.auto_range;
    this.left_y_axes_limits = item.left_y_axes_limits;
    this.right_y_axes_limits = item.right_y_axes_limits;

    this.key_name = item.key_name;
    this.base_unit = item.base_unit;
    this.display_name = item.display_name;
  }

  accessUserOnlyOK(user) {
    let result = false;
    if (user === null) {
      result = false;
    }
    result = user.email === this.email || user.email === this.creator;
    return result;
  }

  accessOK(user) {
    let result = false;
    if (user === null) {
      result = false;
    }
    result =
      user.email === this.email ||
      user.email === this.creator ||
      user.role === "lead" ||
      user.role === "admin";
    return result;
  }

  static addIngredients(item, data) {
    item.ingredients.push(data);
  }

  static deleteIngredients(item, index) {
    if (index > -1) {
      // console.log("recipe index number", index);
      item.ingredients.splice(index, 1);
    }
  }
}

export { OlabItem };
