export const strainSourceTypeSettingModel = [
  {
    key_name: "stock(-80C)",
    display_name: "Stock(-80\u00B0C)"
  },
  {
    key_name: "plate",
    display_name: "Plate"
  },
  {
    key_name: "tube",
    display_name: "Tube"
  },
  {
    key_name: "flask",
    display_name: "Flask"
  }
];
