<template>
  <div>
    <header class="brand-color">
      <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/">
            <img
              alt="OLAB Bioscience logo"
              src="../assets/OLAB.svg"
              height="30"
            />
          </router-link>
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#togglerNav"
            aria-controls="#togglerNav"
            aria-label="Toggle Navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="togglerNav">
            <div class="navbar-nav ml-auto">
              <!-- TODO: Enable when ready -->
              <!-- <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/olabanalysis"
                v-if="user"
                >Analysis</router-link
              > -->
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/olabchart"
                v-if="user"
                >Chart</router-link
              >
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/olabexperiment"
                v-if="user"
                >Experiment</router-link
              >
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/database"
                v-if="user"
                >Database</router-link
              >
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/biocalculators"
                v-if="user && useCalc"
                >Bio-Calculators</router-link
              >
              <router-link
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                to="/faq"
                v-if="user"
                >FAQ</router-link
              >
              <router-link
                class="nav-item nav-link"
                to="/olablogin"
                v-if="!user"
                >Login</router-link
              >
              <div class="text-left">
                <button
                  class="nav-item nav-link btn btn-link"
                  @click="$emit('logout')"
                  v-if="user"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "OlabNavigation",
  props: ["user"],
  computed: {
    useCalc() {
      // console.log(
      //   "VUE_APP_SHOW_BIO_CALCULATORS =",
      //   process.env.VUE_APP_SHOW_BIO_CALCULATORS === false
      // );
      return process.env.VUE_APP_SHOW_BIO_CALCULATORS === "true";
    }
  }
};
</script>

<style lang="scss">
/* nav a.hover, nav a.router-link-active, */
nav a.router-link-exact-active {
  // background-color: #f81;
  font-size: 1.3em;
}
a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
}
</style>
