<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">
        {{ getCultureStageName }}
        <span v-if="culture === null" class="text-danger font-weight-bold mx-1"
          >*</span
        >
      </h5>
      <div class="row mb-3">
        <div class="ml-3">
          <button
            type="button"
            class="btn btn-info"
            v-on:click="prepAddCulture()"
          >
            <font-awesome-icon icon="plus" />
          </button>
        </div>
        <div class="col-10">
          <div>
            <VueMultiselect
              v-model="culture"
              :options="cultures"
              :loading="isLoadingCultures"
              :internal-search="false"
              :multiple="false"
              :close-on-select="true"
              :options-limit="10"
              :custom-label="cultureInfo"
              placeholder="Type to search"
              label="olab_id"
              track-by="olab_id"
              @search-change="getCultures"
              :disabled="showCultureAdd"
            />
          </div>
          <!-- <p v-if="culture">
            {{ culture.olab_id }}
          </p> -->
        </div>
      </div>
      <div v-if="showCultureAdd">
        <div class="h3">Add Culture</div>
        <CultureAdd
          :user="user"
          :selectedExprt="selectedExprt"
          :cultureStage="cultureStage"
          :productionStage="productionStage"
          :prodDataCategories="prodDataCategories"
          @doneCultureAdd="doneCultureAdd"
        />
      </div>
      <div v-if="culture">
        <CultureItem
          :user="user"
          :exprt="selectedExprt"
          :culture="culture"
          :statusObj="statusObj"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CultureAdd from "@/components/exprts/CultureAdd.vue";
import CultureItem from "@/components/exprts/CultureItem.vue";
import { OlabAxios } from "@/olab/olabaxios";
import { OlabSearch } from "@/olab/olabsearch";
import VueMultiselect from "vue-multiselect";

export default {
  name: "CultureControl",
  data() {
    return {
      culture: null,
      cultures: [],
      isLoadingCultures: false,
      showCultureAdd: false
    };
  },
  props: [
    "user",
    "selectedExprt",
    "cultureStage",
    "prodDataCategories",
    "statusObj"
  ],
  emits: ["scrollIntoCultureAdd"],
  components: {
    FontAwesomeIcon,
    CultureAdd,
    CultureItem,
    VueMultiselect
  },
  async mounted() {
    // console.log("Mounted ExprtCulture: selectedExprt =", this.selectedExprt);
    // console.log("Mounted ExprtCulture: cultureStage =", this.cultureStage);
    await this.getCultures("");
  },
  computed: {
    productionStage() {
      const cs = parseInt(this.cultureStage);
      // console.log("cs =", cs, ", cultureStage =", this.cultureStage);
      return this.selectedExprt
        ? this.selectedExprt.num_culture_stages === cs + 1
        : false;
    },
    getCultureStageName() {
      // console.log("productionStage =", this.productionStage);
      if (this.productionStage === true) {
        return "Production";
      }
      let name = "No Name";
      switch (this.cultureStage) {
        case "00":
          name = "Initial Culture";
          break;
        case "01":
          name = "Second Culture";
          break;
        case "02":
          name = "Third Culture";
          break;
        case "03":
          name = "Fourth Culture";
          break;
        case "04":
          name = "Fifth Culture";
          break;
        case "05":
          name = "Sixth Culture";
          break;
        case "06":
          name = "Seventh Culture";
          break;
        case "07":
          name = "Eighth Culture";
          break;
        case "08":
          name = "Nineth Culture";
          break;
      }
      return name;
    }
  },
  methods: {
    prepAddCulture() {
      // console.log("prepAddCulture ...");
      // Clear selected culture
      this.culture = null;
      this.isLoadingCultures = false;

      this.showCultureAdd = !this.showCultureAdd;
    },
    cultureInfo({ olab_id, name }) {
      return `${name} [${olab_id}]`;
    },
    async getCultures(query) {
      // console.log("getCultures: query = ", query);
      this.isLoadingCultures = true;
      const searchBody = OlabSearch.createSearchBody("culture", query, "merge");
      // Restrict cultures query to selected experiment only
      searchBody.auxID = this.selectedExprt.olab_id;
      searchBody.auxID2 = this.cultureStage;
      this.cultures = await OlabAxios.getCultures(searchBody, this.statusObj);
      this.isLoadingCultures = false;
    },
    async doneCultureAdd() {
      this.showCultureAdd = false;
      // console.log("doneCultureAdd: Reload cultures entries");
      await this.getCultures("");
      this.$emit("scrollIntoCultureAdd", this.cultureStage);
    }
  },
  watch: {
    culture() {
      // console.log("culture =", this.culture);
    }
  }
};
</script>
